import {
  EBundlesInternalViewModel,
  ESectionViewModel,
  StoreBodyBlock
} from 'constants/enums';

import { IntegrationSettingsData } from '../Settings/Settings.types';

import { SectionsItem } from './StoreTheme/storeTheme.types';

export interface ThemeTabProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  sectionViewModel?: string;
  setFieldValue?: any;
  setTouched?: any;
  validateField?: any;
  integration?: IntegrationSettingsData;
}
export interface ThemeFormValues {
  login: {
    text: string;
    textColor: string;
    textSize: string;
    textWeight: 'normal' | 'bold' | 'bolder';
    loginLogoImage?: string;
    loginBackgroundImageMobile?: string;
    loginBackgroundImageDesktop?: string;
    loginLogoSize: string;
    otpButton: {
      backgroundColor: {
        colorOne: string;
        colorTwo?: string;
      };
      text: string;
      icon: string;
      textColor: {
        colorOne: string;
        colorTwo?: string;
      };
    };
    consentSection: {
      htmlText?: string;
      consentRequired?: boolean;
    };
    font: string;
  };
  general: {
    logo: string;
    font: string;
    tabName: string;
    favicon: string;
    backgroundImageMobile: string;
    backgroundImageDesktop: string;
    storeSaleImage?: string;
    buttonColor: {
      colorOne: string;
      colorTwo?: string;
    };
    sections: SectionsItem[];
    sectionViewModel: ESectionViewModel;
    bundlesInternalViewModel: EBundlesInternalViewModel;
    logoSize: string;
    buttonTextColor: string;
    footer: {
      imageMobile?: string;
      imageDesktop?: string;
      appstoreLink?: string;
      googlePlayLink?: string;
      privacyPolicyUrl: string;
      termsAndConditionsUrl: string;
      supportLink?: string;
      openLinksInNewTab: boolean;
    };
    sectionName?: string;
    sectionId?: string;
    accountApprovalModal: {
      backgroundImage: string;
      backgroundColor: {
        colorOne: string;
        colorTwo?: string;
      };
      borderColor: string;
      borderWidth: number;
      buttonTextColor: string;
      buttonBackgroundColor: {
        colorOne: string;
        colorTwo?: string;
      };
      textColor: string;
    };
  };
  loaderScreen: {
    headerText: string;
    headerColor: {
      colorOne: string;
      colorTwo?: string;
    };
    headerSize: string;
    headerWeight: string;
    text?: string;
    textColor?: string;
    textSize?: string;
    textWeight?: string;
    headerOn?: boolean;
    textOn?: boolean;
  };
  completedScreen: {
    headerText: string;
    headerColor: {
      colorOne: string;
      colorTwo?: string;
    };
    headerSize: string;
    headerWeight: string;
    textColor: string;
    text?: string;
    textSize?: string;
    textWeight?: 'normal' | 'bold' | 'bolder';
    backToGameButtonText: string;
  };
  storeScreen: {
    bundleBorderColor: {
      colorOne: string;
      colorTwo?: string;
    };
    addToHomeScreen: {
      active: boolean;
      buttonImage?: string;
      iconImage: string;
      shortcutName: string;
      borderColor: {
        colorOne: string;
        colorTwo?: string;
      };
      backgroundColor: {
        colorOne: string;
        colorTwo?: string;
      };
    };
    noOffersMessageText: string;
    noOffersTitleText: string;
    defaultBanner?: string;
  };
  storeTemplate: {
    bodyBlocksOrder: StoreBodyBlock[];
  };
  integration: {
    deepLinks: {
      ios?: string;
      android?: string;
      web?: string;
    };
  };
  checkout: {
    backgroundImage: string;
    buttonColor: string;
    buttonTextColor: string;
    mobileBgImage: string;
    primaryColor: string;
    publisherId: string;
    textColor: string;
    _id: string;
    logo: string;
  };
}

export enum EStoreState {
  GENERAL = 'general',
  STORE = 'store',
  LOGIN = 'login',
  COMPLETED = 'completed',
  CHECKOUT = 'checkout'
}
