import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  AddToHomeScreenButton,
  Background,
  createGradientOrSolid,
  overrideCssVars
} from '@appcharge/shared-ui';
import { Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import useImages from 'api/useImages';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import { EAssetType, EFeatureFlag, ESectionViewModel } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';
import { mapModelSection } from 'utils/mapModelSection';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';

import AcSolidInput from '../../AcSolidInput/AcSolidInput';

import { StoreThemeBundles } from './components/StoreThemeBundles';
import StoreThemeSectionsTable from './components/StoreThemeSectionsTable';
import {
  CurrentSectionItem,
  SectionsItem,
  StoreThemeFormValues
} from './storeTheme.types';

import 'style/forms.scss';

export const StoreTheme: React.FC = () => {
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setTouched,
    validateField
  } = useFormikContext<StoreThemeFormValues>();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );

  const pictures = useImages(currentPublisherId).getImages;
  const [currentSection, setCurrentSection] =
    useState<CurrentSectionItem | null>(null);
  const [sections, setSections] = useState<SectionsItem[]>(values.sections);
  const [editedSectionId, setEditedSectionId] = useState('');

  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const showAddToHomeScreen: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_ADD_TO_HOME_SCREEN
      ],
    [fetchFeatureFlags.data]
  );

  const showBannerSection: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_BANNERS],
    [fetchFeatureFlags.data]
  );

  const cssMappingOverride = {
    '--button-text-color': values.buttonTextColor,
    '--button-color': createGradientOrSolid(values.buttonColor),
    '--bundle-border-color': createGradientOrSolid(values.bundleBorderColor)
  };

  const SectionLayout =
    mapModelSection[
      storeTheme.data.general.sectionViewModel as ESectionViewModel
    ];

  const getSectionTableItems = () => {
    if (sections.length > 0) {
      return sections.map((item, index) => ({
        ...item,
        _id: item.publisherSectionId || index.toString(),
        type: 'Section'
      }));
    }
    return [];
  };

  useEffect(() => {
    if (values.sections.length > 0) {
      setSections(values.sections);
    }
  }, [values.sections]);

  overrideCssVars(cssMappingOverride, document);

  const handleChangeSectionsList = () => {
    return !!editedSectionId ? editSection() : addSection();
  };

  const addSection = () => {
    if (!currentSection) return;

    const sectionToAdd = {
      _id: currentSection.publisherSectionId,
      publisherSectionId: currentSection.publisherSectionId,
      name: currentSection.name,
      type: 'Section',
      image: currentSection?.image
    };
    setSections((prevSections) => [...prevSections, sectionToAdd]);

    const sectionFieldValue: SectionsItem = {
      _id: sectionToAdd.publisherSectionId || sectionToAdd.name,
      publisherSectionId: sectionToAdd.publisherSectionId,
      name: sectionToAdd.name,
      toAdd: true
    };
    if (sectionToAdd.image) {
      sectionFieldValue.image = sectionToAdd.image;
    }
    setFieldValue('sections', [...values.sections, sectionFieldValue]);
    setCurrentSection(null);
  };

  const editSection = () => {
    if (!currentSection) return;
    const updatedSectionsList = sections.map((section) => {
      if (section.publisherSectionId === editedSectionId) {
        const updatedSection: SectionsItem = {
          _id: currentSection.publisherSectionId,
          publisherSectionId: currentSection.publisherSectionId,
          sectionId: currentSection.sectionId,
          name: currentSection.name
        };
        if (currentSection.image) {
          updatedSection.image = currentSection.image;
        }
        return updatedSection;
      }
      return section;
    });
    setEditedSectionId('');
    setSections(updatedSectionsList);
    setFieldValue('sections', updatedSectionsList);
    setCurrentSection(null);
  };
  const getSectionImgItems = () => {
    if (!pictures.isLoading && pictures.data) {
      const filteredPic = pictures.data.filter(
        (p: any) => p.type === EAssetType.GENERAL
      );
      return filteredPic.length > 0
        ? filteredPic.map((picture: any) => ({
            content: picture.name,
            key: picture.url,
            value: picture.url,
            renderFunction: () => (
              <Stack
                className="formContent-imageSelected"
                direction="row"
                spacing={2}
              >
                <img alt={picture.name} src={picture.url} />
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: 0
                  }}
                >
                  {picture.name}
                </div>
              </Stack>
            )
          }))
        : [];
    }

    return [];
  };

  const sectionsData = sections.filter((section) => !section.toBeDeleted);

  const getAddToHomeScreenImgItems = ({
    assetType,
    picturesData,
    imageWidth = 30,
    defaultImageUrl = '',
    defaultAltText = ''
  }: {
    defaultImageUrl?: string;
    defaultAltText?: string;
    assetType: EAssetType;
    picturesData: any[];
    imageWidth?: string | number;
  }) => {
    const createItem = (url: string, alt: string, content = '') => ({
      content,
      key: url,
      value: url,
      renderFunction: () => (
        <Stack direction="row" alignItems="center" gap={1}>
          <img
            src={url}
            alt={alt}
            style={{
              width: imageWidth,
              maxHeight: 30
            }}
          />
        </Stack>
      )
    });

    const defaultItem = defaultImageUrl
      ? createItem(defaultImageUrl, defaultAltText || '')
      : null;

    const filteredItems = picturesData
      .filter((p) => p.type === assetType)
      .map((picture) => createItem(picture.url, picture.name, picture.name));

    return defaultItem ? [defaultItem, ...filteredItems] : filteredItems;
  };

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            {showBannerSection && (
              <>
                <AcCard stackContainer={false} title="Banner">
                  <Grid item xs={12}>
                    <AcSelect
                      header="Banner Image"
                      name="defaultBanner"
                      value={values?.defaultBanner}
                      onClear={() => {
                        setFieldValue('defaultBanner', '');
                      }}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue('defaultBanner', uploadData!.data.url);
                        },
                        uploadType: EAssetType.BANNER,
                        uploadMessage: UploadsTooltips[EAssetType.BANNER]
                      }}
                      items={[
                        ...pictures.data
                          .filter((p: any) => p.type === EAssetType.BANNER)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.defaultBanner && Boolean(errors.defaultBanner)
                      }
                    />
                  </Grid>
                </AcCard>
                <Divider />
              </>
            )}
            <AcCard stackContainer={false} title="Sections">
              <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                <Grid item xs={6}>
                  <AcInput
                    header="Section name"
                    name="sectionName"
                    value={currentSection?.name || ''}
                    tooltip="This will be the name of your section"
                    type="text"
                    onChange={(e) => {
                      setTouched({
                        ...touched
                      });
                      handleChange({
                        target: {
                          name: 'sectionName',
                          value: String(e.target.value)
                        }
                      });
                      setCurrentSection({
                        ...currentSection,
                        publisherSectionId:
                          currentSection?.publisherSectionId || '',
                        name: (e.target.value as string) || ''
                      });
                      validateField('sectionName');
                    }}
                    onBlur={handleBlur}
                    error={touched?.sectionName && Boolean(errors?.sectionName)}
                    helperText={
                      touched?.sectionName
                        ? errors?.sectionName?.toString()
                        : ''
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <AcInput
                    header="ID"
                    name="sectionId"
                    value={currentSection?.publisherSectionId || ''}
                    type="text"
                    onChange={(e) => {
                      setTouched({
                        ...touched
                      });
                      handleChange({
                        target: {
                          name: 'sectionId',
                          value: String(e.target.value)
                        }
                      });
                      setCurrentSection({
                        ...currentSection,
                        publisherSectionId: (e.target.value as string) || '',
                        name: currentSection?.name || ''
                      });
                      validateField('sectionId');
                    }}
                    onBlur={handleBlur}
                    error={touched?.sectionId && Boolean(errors?.sectionId)}
                    helperText={
                      touched?.sectionId ? errors?.sectionId?.toString() : ''
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <AcSelect
                    header="Image"
                    name="sectionImage"
                    value={currentSection?.image || ''}
                    onChange={(e) => {
                      setCurrentSection({
                        ...currentSection,
                        name: currentSection?.name || '',
                        image: e.target.value as string,
                        publisherSectionId:
                          currentSection?.publisherSectionId || ''
                      });
                    }}
                    onBlur={handleBlur}
                    onClear={() => {
                      if (currentSection) {
                        const { image, ...rest } = currentSection;
                        setCurrentSection(rest);
                      }
                    }}
                    disabled={pictures.isLoading || pictures.isRefetching}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setCurrentSection({
                          name: currentSection?.name || '',
                          image: uploadData!.data.url,
                          publisherSectionId:
                            currentSection?.publisherSectionId || ''
                        });
                      },
                      uploadType: EAssetType.GENERAL,
                      uploadMessage: UploadsTooltips[EAssetType.SECTION]
                    }}
                    items={getSectionImgItems()}
                    tooltip={
                      'For optimized display, make sure your image are at least 300px with 1:1 aspect ratio'
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <ActionButton
                    text={!!editedSectionId ? 'Save' : 'Add'}
                    variant="outlined"
                    disabled={
                      !currentSection?.publisherSectionId ||
                      !currentSection?.name
                    }
                    onClick={handleChangeSectionsList}
                  />
                </Grid>
                <Grid item xs={12} mt={-3}>
                  <StoreThemeSectionsTable
                    sections={getSectionTableItems()}
                    setSections={setSections}
                    setFieldValue={setFieldValue}
                    setCurrentSection={setCurrentSection}
                    setEditedSectionId={setEditedSectionId}
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Store Buttons"
              description="These settings will apply to buttons throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={6}>
                  <AcGradientInput
                    header="Button Color"
                    name="buttonColor"
                    defaultValue={values.buttonColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.buttonColor?.colorOne &&
                      Boolean(errors.buttonColor?.colorOne)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AcGradientInput
                    header="Button Text Color"
                    name="buttonTextColor"
                    defaultValue={{
                      colorOne: values.buttonTextColor
                    }}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    onlySolid
                    outputAsSingleColor
                    error={
                      touched.buttonTextColor && Boolean(errors.buttonTextColor)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            {showAddToHomeScreen && (
              <>
                <Divider />
                <AcCard
                  stackContainer={false}
                  title="Add To Home Screen Button"
                >
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{ paddingLeft: '10px' }}
                        slotProps={{
                          typography: {
                            fontSize: 14,
                            sx: {
                              marginLeft: '2px'
                            },
                            color: '#717188'
                          }
                        }}
                        control={
                          <Checkbox
                            name="addToHomeScreen.active"
                            checked={values.addToHomeScreen.active}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFieldValue(
                                'addToHomeScreen.active',
                                isChecked
                              );
                            }}
                            size="small"
                            sx={{
                              padding: 0,
                              marginRight: '6px',
                              '&.Mui-checked': {
                                color: '#7122FF'
                              }
                            }}
                          />
                        }
                        label="Activate this feature in your store"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSolidInput
                        header="Border and Text Color"
                        name="addToHomeScreen.borderColor"
                        defaultValue={
                          values.addToHomeScreen.borderColor.colorOne
                        }
                        setValue={(fieldName: string, color: string) => {
                          setFieldValue(fieldName, {
                            colorOne: color
                          });
                        }}
                        onBlur={handleBlur}
                        onlySolid
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcGradientInput
                        header="Background Color"
                        name="addToHomeScreen.backgroundColor"
                        defaultValue={values.addToHomeScreen.backgroundColor}
                        setValue={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcSelect
                        header="Button Image"
                        name="addToHomeScreen.buttonImage"
                        value={values.addToHomeScreen?.buttonImage}
                        onClear={() => {
                          setFieldValue('addToHomeScreen.buttonImage', '');
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={pictures.isLoading || pictures.isRefetching}
                        uploadConfig={{
                          onUploadSuccess: async (uploadData: any) => {
                            await pictures.refetch();
                            setFieldValue(
                              'addToHomeScreen.buttonImage',
                              uploadData!.data.url
                            );
                          },
                          uploadType: EAssetType.ADD_TO_HOME_BUTTON_IMAGE,
                          uploadMessage:
                            UploadsTooltips[EAssetType.ADD_TO_HOME_BUTTON_IMAGE]
                        }}
                        items={getAddToHomeScreenImgItems({
                          assetType: EAssetType.ADD_TO_HOME_BUTTON_IMAGE,
                          picturesData: pictures.data,
                          imageWidth: 'auto'
                        })}
                        tooltip="Image will always be with 32px hight"
                        error={
                          touched.addToHomeScreen?.buttonImage &&
                          Boolean(errors.addToHomeScreen?.buttonImage)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcSelect
                        required
                        header="Icon Image"
                        name="addToHomeScreen.iconImage"
                        value={values.addToHomeScreen.iconImage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={pictures.isLoading || pictures.isRefetching}
                        uploadConfig={{
                          onUploadSuccess: async (uploadData: any) => {
                            await pictures.refetch();
                            setFieldValue(
                              'addToHomeScreen.iconImage',
                              uploadData!.data.url
                            );
                          },
                          uploadType: EAssetType.ADD_TO_HOME_ICON_IMAGE,
                          uploadMessage:
                            UploadsTooltips[EAssetType.ADD_TO_HOME_ICON_IMAGE]
                        }}
                        items={getAddToHomeScreenImgItems({
                          defaultImageUrl:
                            'https://media.appcharge.com/defaults/logo.svg',
                          defaultAltText: 'Default Appcharge icon',
                          picturesData: pictures.data,
                          assetType: EAssetType.ADD_TO_HOME_ICON_IMAGE
                        })}
                        tooltip="Image with 1:1 ratio with max 192px width"
                        error={
                          touched.addToHomeScreen?.iconImage &&
                          Boolean(errors.addToHomeScreen?.iconImage)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AcInput
                        required
                        header="Store Title"
                        name="addToHomeScreen.shortcutName"
                        value={values.addToHomeScreen.shortcutName}
                        tooltip="Will be the name of your home screen icon"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched?.addToHomeScreen?.shortcutName &&
                          Boolean(errors?.addToHomeScreen?.shortcutName)
                        }
                        helperText={
                          touched.addToHomeScreen?.shortcutName
                            ? errors.addToHomeScreen?.shortcutName?.toString()
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </AcCard>
              </>
            )}
            <Divider />
            <AcCard stackContainer={false} title="Lack of Special Offers">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Title Text"
                    name="noOffersTitleText"
                    value={values.noOffersTitleText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.noOffersTitleText &&
                      Boolean(errors.noOffersTitleText)
                    }
                    helperText={
                      touched.noOffersTitleText
                        ? errors.noOffersTitleText?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Message Text"
                    name="noOffersMessageText"
                    value={values.noOffersMessageText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.noOffersMessageText &&
                      Boolean(errors.noOffersMessageText)
                    }
                    helperText={
                      touched.noOffersMessageText
                        ? errors.noOffersMessageText?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Borders"
              description="These settings will apply to borders throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcGradientInput
                    header="Bundle Frame Color"
                    name="bundleBorderColor"
                    defaultValue={values.bundleBorderColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.bundleBorderColor &&
                      Boolean(errors.bundleBorderColor)
                    }
                    helperText={
                      touched.bundleBorderColor
                        ? errors.bundleBorderColor?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
          </Stack>
        )}
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div className="button-container">
          {showAddToHomeScreen && (
            <AddToHomeScreenButton
              borderColor={values.addToHomeScreen?.borderColor.colorOne}
              backgroundColor={
                values.addToHomeScreen.backgroundColor?.colorOne
                  ? values.addToHomeScreen.backgroundColor
                  : {
                      colorOne: '#522AD8',
                      colorTwo: '#7946FA'
                    }
              }
              buttonImage={values.addToHomeScreen?.buttonImage}
              onClick={() => ''}
              isMockPreview
            />
          )}
        </div>
        <div
          className="store-preview-wrapper iphone-mock"
          style={{ overflow: 'scroll' }}
        >
          <Background
            backgroundImageMobile={
              storeTheme.data.general.backgroundImageMobile
            }
            backgroundImageDesktop={
              storeTheme.data.general.backgroundImageMobile
            }
            width="100%"
            height="100%"
            position="absolute"
          />
          <img
            style={{ position: 'relative' }}
            alt="logo"
            className={`storeLogo ${storeTheme.data.general.logoSize}`}
            src={storeTheme.data.general.logo}
          />
          <div className="bundles-wrapper">
            {sectionsData.length > 0 ? (
              <>
                {sectionsData.map((section, index) => (
                  <div
                    key={section.publisherSectionId}
                    className="store-theme-sections"
                  >
                    <SectionLayout
                      sectionName={section.name}
                      image={section.image}
                    />
                    <StoreThemeBundles
                      bundleBorderColor={values.bundleBorderColor}
                    />
                  </div>
                ))}
              </>
            ) : (
              <StoreThemeBundles bundleBorderColor={values.bundleBorderColor} />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
