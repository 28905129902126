import { ChangeEvent, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Grid, SelectChangeEvent, Stack } from '@mui/material';
import useUsers from 'api/useUsers';
import { EFeatureFlag } from 'constants/enums';
import { AuthSliceState } from 'store/store.types';
import { permissionsUtil } from 'utils/permissionsUtil';
import { timeUtils } from 'utils/scheduleUtils';
import { roundedHoursOptions } from 'utils/selectOptionsUtils';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

const PermanentOffer = ({
  formikProps,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron
}: any) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formikProps;

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const hasIntervalsFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_INTERVALS];

  useEffect(() => {
    if (intervalCron && values?.schedule?.permanent) {
      setFieldValue('schedule', {
        ...values.schedule,
        interval: intervalCron
      });
    }
  }, [intervalCron]);

  const handleSelectHour = (e: SelectChangeEvent<unknown>) => {
    const selectedHour = e.target.value as string;
    setStartHour(selectedHour);
    if (selectedHour) {
      setIntervalCron(timeUtils.generateCronString(selectedHour));
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '2rem'
      }}
    >
      <AcCard
        stackContainer={false}
        title="Permanent Offer"
        description="Permanent events won’t have an end date"
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <AcInput
              header="Availability"
              name="playerAvailability"
              value={values.playerAvailability || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required={values?.setAsFree || values?.schedule?.interval}
              type="number"
              error={
                touched.playerAvailability && Boolean(errors.playerAvailability)
              }
              helperText={
                touched.playerAvailability
                  ? errors.playerAvailability?.toString()
                  : ''
              }
            />
          </Grid>
          {hasIntervalsFeatureFlag && (
            <>
              {currentPublisherId && permissionsUtil.isSuperAdminByProject(currentPublisherId) ? (
                <Grid item xs={1}>
                  <AcInput
                    header="Daily refresh at (UTC)"
                    name="startHour"
                    type="time"
                    value={startHour}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSelectHour(e)
                    }
                    error={
                      touched.playerAvailability &&
                      Boolean(errors.playerAvailability)
                    }
                    sx={{ minWidth: '100px' }}
                  />
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <AcSelect
                    header="Daily refresh at (UTC)"
                    name="startHour"
                    value={startHour}
                    items={roundedHoursOptions}
                    onChange={(e: SelectChangeEvent<unknown>) =>
                      handleSelectHour(e)
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </AcCard>
    </Stack>
  );
};

export default PermanentOffer;
