import { useEffect, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HelpCenterOutlined } from '@mui/icons-material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';
import useUsers from 'api/useUsers';
import { EFeatureFlag, ELocalStorageKeys } from 'constants/enums';
import { debounce } from 'lodash';
import { UserDetails } from 'pages/Login/Login.types';
import { authActions } from 'store/authSlice';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import AcAvatar from '../../AcAvatar/AcAvatar';

import './style.scss';

const SidebarProfile = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const arrowRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [shouldShowSupport, setShouldShowSupport] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { logout } = useUsers({
    publisherId: currentPublisherId,
    enableFeatureFlags: false
  });
  const { getIsFeatureEnabledInAnyProject, fetchFeatureFlags } = useUsers({
    publisherId: currentPublisherId
  });

  // TODO: change when supporting ff in company level
  const debouncedSetSupport = useMemo(
    () =>
      debounce((publisherId, isFFOn) => {
        if (publisherId) {
          setShouldShowSupport(isFFOn);
        } else {
          getIsFeatureEnabledInAnyProject(
            EFeatureFlag.DASHBOARD_SHOW_SUPPORT
          ).then((isEnabled) => setShouldShowSupport(isEnabled));
        }
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSetSupport(
      currentPublisherId,
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_SHOW_SUPPORT
      ]
    );
  }, [
    currentPublisherId,
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_SHOW_SUPPORT]
  ]);
  // TODO: end

  useEffect(() => {
    if (!document.getElementById('ze-snippet')) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=3e57c36b-1366-45f6-afc7-a283c785edd3';
      script.async = true;
      script.onload = () => {
        if (window.zE) {
          window.zE('webWidget', 'hide');
        }
      };
      document.body.appendChild(script);
    } else if (window.zE) {
      window.zE('webWidget', 'hide');
    }
  }, []);

  const dispatch = useDispatch();
  const userDetails = localStorageUtil.get<UserDetails>(
    ELocalStorageKeys.USER_DETAILS
  );
  const navigate = useNavigate();

  const handleArrowClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(arrowRef.current);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const onSupportClicked = () => {
    const userDetailsJSON = localStorage.getItem('user_details');
    if (userDetailsJSON) {
      const userDetails = JSON.parse(userDetailsJSON);
      const { email, firstName, lastName } = userDetails;

      const fullName = `${firstName} ${lastName}`.trim();

      if (window.zE) {
        window.zE('webWidget', 'identify', {
          name: fullName || 'Anonymous',
          email: email || ''
        });
      }
    }

    handlePopoverClose();
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');

      window.zE('webWidget', 'set', 'contactForm', {
        tags: ['backoffice_tickets']
      });
    } else {
      console.error('Zendesk Web Widget API is not available yet.');
    }
  };

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide');
      });
    }
  }, []);

  return (
    <>
      <Popover
        open={isPopoverOpen}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 54
        }}
        sx={{
          width: 200,
          padding: 16
        }}
        elevation={2}
      >
        <ul
          style={{
            width: 240,
            padding: '1em .5em'
          }}
          className="sidebar-profile-actions"
        >
          <li
            onClick={() => {
              handlePopoverClose();
              navigate(
                `${currentPublisherId ? `${currentPublisherId}/profile/personal` : `profile/personal`}`
              );
            }}
          >
            <PersonOutlineOutlinedIcon />
            <div className="sidebar-profile-textBlock">
              <h3>Profile</h3>
            </div>
          </li>
          {shouldShowSupport && (
            <li onClick={onSupportClicked}>
              <HelpCenterOutlined />
              <div className="sidebar-profile-textBlock">
                <h3>Support</h3>
              </div>
            </li>
          )}
          <li
            onClick={() => {
              if (window.zE) {
                window.zE('webWidget', 'hide');
              }
              dispatch(authActions.login({ authJwt: null }));
              logout.mutate();
            }}
          >
            <LogoutOutlinedIcon />
            <h3>Logout</h3>
          </li>
        </ul>
      </Popover>
      <hr />
      <Stack
        flexDirection="row"
        gap={1.2}
        className="sidebar-profile"
        position="relative"
        onClick={handleArrowClick}
      >
        <Box position="absolute" sx={{ right: 8, top: 0 }}>
          <IconButton className="arrow-down-button" ref={arrowRef}>
            <ArrowRight />
          </IconButton>
        </Box>
        <Box position="relative" justifyContent="center" alignItems="center">
          <AcAvatar
            isSuperAdmin={permissionsUtil.isSuperAdmin()}
            content={userDetails?.firstName?.charAt(0)}
            dataTestId="sideBarFooterAvatar"
          />
        </Box>
        <Stack direction="column">
          <Typography
            fontFamily="'Montserrat', sans-serif"
            className="sidebar-profile-userName"
            data-testid="sideBarFooterUsername"
          >
            {userDetails.firstName} {userDetails.lastName}
          </Typography>
          <Typography
            fontFamily="'Montserrat', sans-serif"
            fontSize={11}
            color="#757575"
            className="sidebar-profile-userEmail"
            data-testid="sideBarFooterEmail"
          >
            {userDetails.email}
          </Typography>
        </Stack>
      </Stack>
      <hr />
    </>
  );
};

export default SidebarProfile;
