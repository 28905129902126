import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

export const completedValidationSchema = yup.object().shape({
  headerText: yup.string().required(required('Header')),
  headerColor: yup.object().shape({
    colorOne: yup.string().required(required('Button main color')),
    colorTwo: yup.string()
  }),
  headerSize: yup.string().required(required('Header font size')),
  headerWeight: yup.string().required(required('Header font weight')),
  text: yup.string(),
  textColor: yup.string(),
  textSize: yup.string(),
  textWeight: yup.string(),
  backToGameButtonText: yup.string().required(required('Back to game text')),
  deepLinks: yup.object().shape({
    ios: yup
      .string()
      .matches(/.+:\/\/.*/, 'Apple deep link must be a valid url'),
    android: yup
      .string()
      .matches(/.+:\/\/.*/, 'Android deep link must be a valid url'),
    web: yup.string().matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
  })
});
