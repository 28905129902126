import { EButtonColor } from 'constants/enums';
import { ModalProps } from 'views/RollingOffers/types';

import DialogModal from 'components/Dialog/Dialog';

const RollingOfferModals: React.FC<ModalProps> = ({
  isSaveDialogOpen,
  isUpdateDialogOpen,
  setIsSaveDialogOpen,
  setIsUpdateDialogOpen,
  submitForm
}) => {
  return (
    <>
      <DialogModal
        isOpen={isUpdateDialogOpen}
        headline="Rolling Offer is currently live"
        text="Your changes will update automatically"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsUpdateDialogOpen(false);
            }
          },
          {
            text: 'Update',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: submitForm
          }
        ]}
        closeDialog={() => setIsUpdateDialogOpen(false)}
      />
      <DialogModal
        isOpen={isSaveDialogOpen}
        headline="Please note"
        text="No products were selected"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsSaveDialogOpen(false);
            }
          },
          {
            text: 'Save',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: submitForm
          }
        ]}
        closeDialog={() => setIsSaveDialogOpen(false)}
      />
    </>
  );
};

export default RollingOfferModals;
