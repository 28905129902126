import { Divider, Grid } from '@mui/material';
import { OfferUiProps } from 'common/contracts';
import { EAssetType } from 'constants/enums';
import { useUploads } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';

import { OfferDesignTypes } from './types';

const PopupUiForm: React.FC<OfferUiProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  addPictureInputRef,
  getImages,
  setCurrentUploadingField,
  currentUploadingField,
  dup
}) => {
  const { uploadImage, getAssetUploadMessage } = useUploads();
  return (
    <>
      <Grid item xs={6}>
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={(e) =>
            uploadImage(
              e,
              addPictureInputRef.current as HTMLInputElement,
              'badgeCoverImage',
              setFieldValue,
              setCurrentUploadingField,
              currentUploadingField
            )
          }
          ref={addPictureInputRef}
          accept="image/*"
        />
        <AcSelect
          header="Badge Image"
          name="badgeCoverImage"
          value={values.badgeCoverImage}
          uploadConfig={{
            onUploadSuccess: async (uploadData: any) => {
              await getImages.refetch();
              setFieldValue('badgeCoverImage', uploadData!.data.url);
            },
            uploadType: EAssetType.BG_BUNDLE,
            uploadMessage: getAssetUploadMessage(values.offerUiType)
          }}
          renderType={EAcSelectItemRenderType.IMAGE}
          items={[
            ...getImages.data
              .filter((p: any) => p.type === EAssetType.BG_BUNDLE)
              .map((picture: any) => ({
                content: picture.name,
                key: picture.url,
                value: picture.url,
                url: picture.url,
                name: picture.name
              }))
          ]}
          onChange={handleChange}
          onBlur={handleBlur}
          onClear={
            values.offerUiType === OfferDesignTypes.Bundle
              ? () => {
                  setFieldValue('badgeCoverImage', '');
                }
              : undefined
          }
          error={touched.badgeCoverImage && Boolean(errors.badgeCoverImage)}
          helperText={
            touched.badgeCoverImage || dup
              ? errors.badgeCoverImage?.toString()
              : ''
          }
          imgHeight={'32'}
        />
      </Grid>
      <Divider />
      <AcCard title="Border">
        <Grid container columnSpacing={{ xs: 1 }}>
          <Grid item xs={6}>
            <AcGradientInput
              header="Fill Color"
              name="borderColor"
              defaultValue={values.borderColor}
              onChange={handleChange}
              setValue={setFieldValue}
              onBlur={handleBlur}
              imgWidth={'32px'}
              imgHeight={'32px'}
              roundedBorders={false}
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="Width (in px)"
              name="borderWidth"
              value={values.borderWidth || '0'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.borderWidth && Boolean(errors?.borderWidth)}
              helperText={
                touched?.borderWidth ? errors?.borderWidth?.toString() : ''
              }
            />
          </Grid>
        </Grid>
      </AcCard>
    </>
  );
};

export default PopupUiForm;
