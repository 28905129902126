import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import useTheme from 'api/useTheme';
import { AuthSliceState } from 'store/store.types';

import { defaultCompletedTheme } from './defaults';

export const useGetCompletedInitialValues = () => {
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [initialValues, setInitialValue] = useState<any>({});

  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );

  const { getIntegration } = useIntegration(currentPublisherId);

  const completedTheme = useMemo(
    () => ({ data: storeTheme?.data?.completedScreen }),
    [storeTheme?.data]
  );

  useEffect(() => {
    setInitialValue({
      headerText:
        completedTheme.data?.headerText || defaultCompletedTheme.headerText,
      headerColor: {
        colorOne:
          completedTheme.data?.headerColor.colorOne ||
          defaultCompletedTheme.headerColor.colorOne,
        colorTwo:
          completedTheme.data?.headerColor.colorTwo ||
          defaultCompletedTheme.headerColor.colorTwo,
        gradientDirection:
          completedTheme.data?.headerColor.gradientDirection ||
          defaultCompletedTheme.headerColor.gradientDirection
      },
      headerSize:
        completedTheme.data?.headerSize || defaultCompletedTheme.headerSize,
      headerWeight:
        completedTheme.data?.headerWeight || defaultCompletedTheme.headerWeight,
      text: completedTheme.data?.text || defaultCompletedTheme.text,
      textColor:
        completedTheme.data?.textColor || defaultCompletedTheme.textColor,
      textSize: completedTheme.data?.textSize || defaultCompletedTheme.textSize,
      textWeight:
        completedTheme.data?.textWeight || defaultCompletedTheme.textWeight,
      backToGameButtonText:
        completedTheme.data?.backToGameButtonText ||
        defaultCompletedTheme.backToGameButtonText,
      deepLinks: {
        ios:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'ios'
          )?.deepLink || defaultCompletedTheme.deepLinks.ios,
        android:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'android'
          )?.deepLink || defaultCompletedTheme.deepLinks.android,
        web:
          getIntegration.data?.deepLinks?.find(
            (platform: { platform: string; deepLink: string }) =>
              platform.platform === 'web'
          )?.deepLink || defaultCompletedTheme.deepLinks.web
      }
    });
  }, [completedTheme?.data, storeTheme?.data, getIntegration.data, location]);

  return { initialValues };
};
