import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

export const loginValidationSchema = yup.object().shape({
  text: yup.string().required(required('Header')),
  textColor: yup.string().required(required('Font color')),
  textSize: yup.string().required(required('Font size')),
  loginLogoImage: yup.string().notRequired(),
  loginBackgroundImageMobile: yup.string().notRequired(),
  loginBackgroundImageDesktop: yup.string().notRequired(),
  loginLogoSize: yup.string().required(required('Logo size')),
  otpButton: yup.object().shape({
    backgroundColor: yup.object().shape({
      colorOne: yup.string().required(required('Button main color')),
      colorTwo: yup.string()
    }),
    text: yup.string(),
    icon: yup.string(),
    textColor: yup.object().shape({
      colorOne: yup.string().required(required('Text main color')),
      colorTwo: yup.string()
    })
  }),
  consentSection: yup.object().shape({
    htmlText: yup.string(),
    consentRequired: yup.boolean()
  }),
  accountApprovalModal: yup.object().shape({
    backgroundImage: yup.string().required(required('Background image')),
    backgroundColor: yup.object().shape({
      colorOne: yup.string().required(required('Background color')),
      colorTwo: yup.string()
    }),
    buttonBackgroundColor: yup.object().shape({
      colorOne: yup.string().required(required('Button background color')),
      colorTwo: yup.string()
    }),
    borderWidth: yup
      .number()
      .min(0, 'Border width cannot be negative')
      .max(20, 'Border width cannot be bigger than 20px')
  })
});
