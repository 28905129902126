import { useSelector } from 'react-redux';

import useSections from 'api/useSections';
import { TRIMMED_INPUT_REGEX } from 'constants/constants';
import { AuthSliceState } from 'store/store.types';
import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

import { SectionsItem } from './storeTheme.types';

export const useGetStoreValidationSchema = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const { getSections } = useSections(currentPublisherId);

  let storeValidationSchema = yup.object().shape({
    defaultBanner: yup.string().notRequired(),
    sectionName: yup
      .string()
      .test('is-unique', 'Section name already exists', (value) => {
        return !getSections.data?.result.some(
          (item: SectionsItem) => item.name === value
        );
      }),
    sectionId: yup
      .string()
      .test('is-unique', 'Section id already exists', (value) => {
        return !getSections.data?.result.some(
          (item: SectionsItem) => item.publisherSectionId === value
        );
      }),
    buttonColor: yup.object().shape({
      colorOne: yup.string().required(required('Button main color')),
      colorTwo: yup.string()
    }),
    addToHomeScreen: yup.object().shape({
      active: yup.boolean().notRequired(),
      buttonImage: yup.string().notRequired(),
      iconImage: yup.string().required(required('Icon Image')),
      shortcutName: yup
        .string()
        .trim()
        .when('active', {
          is: true,
          then: yup
            .string()
            .trim()
            .matches(TRIMMED_INPUT_REGEX, 'Store Title should not be spaces')
            .required('Store Title is required'),
          otherwise: yup.string().notRequired()
        })
    }),
    bundleBorderColor: yup.object().shape({
      colorOne: yup.string().required(required('Bundle frame main color')),
      colorTwo: yup.string()
    })
  });

  return { storeValidationSchema };
};
