import { EBundlesViewModel } from '@appcharge/shared-ui';
import {
  BannerAspectRatio,
  DEFAULT_BODY_BLOCKS_ORDER,
  EBalancesTheme,
  EBundlesInternalViewModel,
  ELogoSize,
  ESectionViewModel
} from 'constants/enums';

export const defaultTheme = {
  general: {
    logo: 'https://media.appcharge.com/defaults/logo.svg',
    favicon: 'https://media.appcharge.com/defaults/logo.svg',
    storeTabName: 'Gaming Store',
    backgroundImageMobile:
      'https://media.appcharge.com/defaults/background.png',
    backgroundImageDesktop:
      'https://media.appcharge.com/defaults/background.png',
    storeSaleImage: '',
    font: 'montserrat',
    fontWeightForHeaders: '',
    fontWeightForText: '',
    buttonColor: {
      gradientDirection: '',
      colorOne: '#7122ff',
      colorTwo: '',
      solid: true
    },
    buttonTextColor: 'white',
    bundlesViewModel: EBundlesViewModel.LINEAR,
    bundlesInternalViewModel: EBundlesInternalViewModel.AK,
    specialOffersViewModel: 'gallery',
    specialOffersInternalViewModel: 'hug',
    footer: {
      imageMobile: '',
      imageDesktop: '',
      appstoreLink: '',
      googlePlayLink: '',
      privacyPolicyUrl: '',
      termsAndConditionsUrl: '',
      supportLink: '',
      openLinksInNewTab: false
    },
    balancesTheme: EBalancesTheme.DEFAULT,
    sectionViewModel: ESectionViewModel.DEFAULT,
    sections: [],
    popupRatio: BannerAspectRatio.SQUARE,
    productQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    },
    balanceQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    },
    specialOfferQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    },
    accountApprovalModal: {
      backgroundImage: 'https://media.appcharge.com/defaults/background.png',
      backgroundColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      },
      borderColor: '#FFFFFF',
      borderWidth: 0,
      buttonTextColor: '#FFFFFF',
      buttonBackgroundColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      },
      textColor: '#FFFFFF'
    }
  },
  login: {
    text: 'Welcome to a new members-only clubs',
    font: 'montserrat',
    textColor: 'white',
    textSize: 28,
    textWeight: 'bold',
    textOn: true,
    loginLogoImage: '',
    loginBackgroundImageMobile: '',
    loginBackgroundImageDesktop: '',
    loginLogoSize: ELogoSize.SMALL,
    otpButton: {
      backgroundColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      },
      text: 'OTP Text',
      icon: '',
      textColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      }
    },
    consentSection: {
      htmlText:
        '<p>By logging in you acknowledge that you have read and agree to the Privacy Policy And Terms and Conditions</p>',
      consentRequired: false
    }
  },
  storeScreen: {
    bundleBorderColor: {
      gradientDirection: '',
      colorOne: '#e3d3ff',
      colorTwo: '',
      solid: true
    },
    defaultBanner: '',
    noOffersTitleText: 'No offers available',
    noOffersMessageText: 'Please check back later.',
    borderSize: 0,
    addToHomeScreen: {
      active: false,
      buttonImage: '',
      iconImage: 'https://media.appcharge.com/defaults/logo.svg',
      shortcutName: 'Gaming Store',
      borderColor: {
        colorOne: '#ffffff',
        colorTwo: ''
      },
      backgroundColor: {
        colorOne: '#522AD8',
        colorTwo: ''
      }
    }
  },
  storeTemplate: {
    bodyBlocksOrder: DEFAULT_BODY_BLOCKS_ORDER
  },
  loaderScreen: {
    headerText: 'Your order is on the way',
    headerColor: {
      gradientDirection: '',
      colorOne: 'white',
      colorTwo: '',
      solid: true
    },
    headerSize: 44,
    headerWeight: 'bolder',
    headerOn: false,
    text: 'Please wait a few moments until we load your assets',
    textColor: 'white',
    textSize: 24,
    textWeight: 'bold',
    textOn: false,
    gif: ''
  },
  completedScreen: {
    headerText: 'Success',
    headerColor: {
      gradientDirection: '',
      colorOne: 'white',
      colorTwo: '',
      solid: true
    },
    headerSize: 44,
    headerWeight: 'bold',
    headerOn: true,
    text: 'Open the game to see yuor new items',
    textColor: 'white',
    textSize: 24,
    textWeight: 'bold',
    textOn: true,
    backToGameButtonDeepLink: '',
    backToGameButtonText: 'Back to game'
  },
  integration: {
    deepLinks: {
      android: '',
      ios: '',
      web: ''
    }
  }
};
