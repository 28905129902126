import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DataTable from 'components/DataTable/DataTable';
import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { IS_NO_IP_ONLY_HTTPS } from 'constants/constants';
import { ENotificationType } from 'constants/enums';
import { required } from 'utils/errorsTextHelper';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import ActionButton from 'components/ActionButton/ActionButton';
import { useNotifications } from 'hooks/useNotifications';

import { IntegrationProfile } from '../Settings.types';

import './style.scss';
import {
  convertProfilesArrayToObject,
  convertProfilesObjectToArray
} from '../utils';
import IntegrationLinkCell from './IntegrationLinkCell';

export enum EActions {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete'
}

interface IntegrationProfileRow extends IntegrationProfile {
  name: string;
}

const IntegrationProfiles = () => {
  const [profiles, setProfiles] = useState<IntegrationProfileRow[]>([]);
  const [editedProfile, setEditedProfile] = useState<IntegrationProfileRow>();
  const { id } = useParams<{ id: string }>();
  const { getIntegration, updateIntegration } = useIntegration(id as string);
  const { getPublisher } = usePublisher(id || undefined);
  const { enqueueSnackbar } = useNotifications();

  const webhooksSchema = yup.object().shape({
    name: yup
      .string()
      .required(required('Name'))
      .test('unique', 'Name must be unique', (value: any) => {
        return !profiles.some((p: IntegrationProfileRow) => p.name === value);
      }),
    ordersReportingApiUrl: yup
      .string()
      .required(required('Order deporting URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    playerInfoSyncUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    eventsWebhookUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    playersAuthWebhook: yup
      .string()
      .required(required('Login authentication URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    fbAppId: yup.string()
  });

  const integrationProfileForm = useFormik({
    initialValues: {
      name: '',
      playerInfoSyncUrl: '',
      playersAuthWebhook: '',
      ordersReportingApiUrl: '',
      eventsWebhookUrl: '',
      fbAppId: ''
    },
    validationSchema: webhooksSchema,
    onSubmit: async (values) => {
      integrationProfileForm.resetForm({
        values: {
          name: '',
          playerInfoSyncUrl: '',
          playersAuthWebhook: '',
          ordersReportingApiUrl: '',
          eventsWebhookUrl: '',
          fbAppId: ''
        }
      });
      if (values) {
        saveProfile(
          values as IntegrationProfileRow,
          editedProfile ? EActions.UPDATE : EActions.CREATE
        );
      }
    }
  });

  const saveProfile = (profile: IntegrationProfileRow, action: EActions) => {
    const newProfilesList = [...profiles, profile];
    setProfiles(newProfilesList);
    updateProfiles(newProfilesList, action);
  };

  const deleteProfile = (profile: IntegrationProfileRow) => {
    const newProfilesList = profiles.filter(
      (p: IntegrationProfileRow) => p.name !== profile.name
    );
    setProfiles(newProfilesList);
    updateProfiles(newProfilesList, EActions.DELETE);
  };

  const updateProfiles = (
    newProfiles: IntegrationProfileRow[],
    action: EActions
  ) => {
    const integrationProfiles = convertProfilesArrayToObject(newProfiles);
    setEditedProfile(undefined);
    updateIntegration.mutate(
      { integrationProfiles },
      {
        onSuccess: () => {
          getPublisher.refetch();
          enqueueSnackbar(
            `Integration profile ${action}d successfully`,
            ENotificationType.SUCCESS
          );
        },
        onError: (data: any) => {
          enqueueSnackbar(
            `Failed to ${action} Integration profile`,
            ENotificationType.ERROR
          );
        }
      }
    );
  };

  const editProfile = (profile: IntegrationProfileRow) => {
    setEditedProfile(profile);
    setProfiles(
      profiles.filter((p: IntegrationProfileRow) => p.name !== profile.name)
    );
    integrationProfileForm.setValues(profile);
  };

  useEffect(() => {
    if (!getIntegration.data) return;
    const profilesRows = convertProfilesObjectToArray({
      ...getIntegration.data.integrationProfiles
    });
    setProfiles(profilesRows);
  }, [getIntegration.data]);

  useEffect(() => {
    if (!editedProfile) return;
    integrationProfileForm.setTouched({}, true);
  }, [editedProfile]);

  return (
    <Grid container className="formContent">
      <Grid item xs={12} className="formContent-minWIdth">
        <Stack>
          <Box className="description">
            <h3>Integration Profiles</h3>
            <p>
              {
                'You can create and manage multiple profiles to personalize your experience in your sandbox. These profiles will be available every time you visit the webstore, making it simple to switch between them and customize your webhooks to ease your testing proccess.'
              }
            </p>
          </Box>
          <AcCard stackContainer={false}>
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={6}>
                <AcInput
                  header="Name"
                  name="name"
                  value={integrationProfileForm.values.name}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.name &&
                    Boolean(integrationProfileForm.errors.name)
                  }
                  helperText={
                    integrationProfileForm.touched.name
                      ? integrationProfileForm.errors.name?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Orders Reporting API"
                  name="ordersReportingApiUrl"
                  value={integrationProfileForm.values.ordersReportingApiUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.ordersReportingApiUrl &&
                    Boolean(integrationProfileForm.errors.ordersReportingApiUrl)
                  }
                  helperText={
                    integrationProfileForm.touched.ordersReportingApiUrl
                      ? integrationProfileForm.errors.ordersReportingApiUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Player Info Sync URL"
                  name="playerInfoSyncUrl"
                  value={integrationProfileForm.values.playerInfoSyncUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.playerInfoSyncUrl &&
                    Boolean(integrationProfileForm.errors.playerInfoSyncUrl)
                  }
                  helperText={
                    integrationProfileForm.touched.playerInfoSyncUrl
                      ? integrationProfileForm.errors.playerInfoSyncUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Authentication Webhook"
                  name="playersAuthWebhook"
                  value={integrationProfileForm.values.playersAuthWebhook}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched?.playersAuthWebhook &&
                    Boolean(integrationProfileForm.errors?.playersAuthWebhook)
                  }
                  helperText={
                    integrationProfileForm.touched?.playersAuthWebhook
                      ? integrationProfileForm.errors?.playersAuthWebhook?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <AcInput
                  header="Events Webhook"
                  name="eventsWebhookUrl"
                  value={integrationProfileForm.values.eventsWebhookUrl}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched?.eventsWebhookUrl &&
                    Boolean(integrationProfileForm.errors?.eventsWebhookUrl)
                  }
                  helperText={
                    integrationProfileForm.touched?.eventsWebhookUrl
                      ? integrationProfileForm.errors?.eventsWebhookUrl?.toString()
                      : ''
                  }
                  customClass="url-input"
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Facebook ID"
                  name="fbAppId"
                  value={integrationProfileForm.values.fbAppId}
                  onChange={integrationProfileForm.handleChange}
                  onBlur={integrationProfileForm.handleBlur}
                  error={
                    integrationProfileForm.touched.fbAppId &&
                    Boolean(integrationProfileForm.errors.fbAppId)
                  }
                  helperText={
                    integrationProfileForm.touched.fbAppId
                      ? integrationProfileForm.errors.fbAppId?.toString()
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={6}>
          <ActionButton
            variant="outlined"
            text={editedProfile ? 'Update Profile' : 'Add Profile'}
            onClick={integrationProfileForm.submitForm}
            disabled={
              !integrationProfileForm.isValid ||
              !integrationProfileForm.dirty ||
              integrationProfileForm.isSubmitting
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} mt={-3}>
          <DataTable
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                maxWidth: 100
              },
              {
                field: 'ordersReportingApiUrl',
                headerName: 'Orders Reporting API',
                flex: 1,
                renderCell: (params: any) => (
                  <IntegrationLinkCell link={params.value} />
                )
              },
              {
                field: 'playerInfoSyncUrl',
                headerName: 'Player Info Sync URL',
                flex: 1,
                renderCell: (params: any) => (
                  <IntegrationLinkCell link={params.value} />
                )
              },
              {
                field: 'playersAuthWebhook',
                headerName: 'Authentication Webhook',
                flex: 1,
                renderCell: (params: any) => (
                  <IntegrationLinkCell link={params.value} />
                )
              },
              {
                field: 'eventsWebhookUrl',
                headerName: 'Events Webhook',
                flex: 1,
                renderCell: (params: any) => (
                  <IntegrationLinkCell link={params.value} />
                )
              },
              {
                field: 'fbAppId',
                headerName: 'Facebook ID',
                flex: 1,
                renderCell: (params: any) => (
                  <IntegrationLinkCell link={params.value} />
                )
              },
              {
                field: 'actions',
                flex: 0,
                type: 'actions',
                width: 80,
                disableReorder: true,
                getActions: (params: any) => [
                  <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => {
                      editProfile(params.row);
                    }}
                    disabled={false}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger" />}
                    label="Delete"
                    onClick={() => {
                      deleteProfile(params.row);
                    }}
                    disabled={false}
                    showInMenu
                  />
                ]
              }
            ]}
            rowIdIdentifier="name"
            rows={profiles}
            loading={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationProfiles;
