import { useEffect, useState } from 'react';

import { EBadgePosition } from '@appcharge/shared-ui';
import { Grid, InputAdornment } from '@mui/material';
import useBadges from 'api/useBadges';
import {
  Badge,
  BundleBadge,
  EBadgeType,
  FormikProps,
  RollingOfferProductSequence
} from 'common/contracts';
import { ESaleDiscountDisplayType } from 'constants/enums';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';

interface CardExtrasCollapseProps {
  formikProps: FormikProps;
  currentPublisherId: string | null;
  productSequenceItem: RollingOfferProductSequence;
  updateSubOfferData: (
    updatedData: Partial<RollingOfferProductSequence>,
    e?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const CardExtrasCollapse: React.FC<CardExtrasCollapseProps> = ({
  formikProps,
  currentPublisherId,
  productSequenceItem,
  updateSubOfferData
}) => {
  const { touched, handleBlur, errors, setFieldValue } =
    formikProps;
  const [badgeList, setBadgeList] = useState<BundleBadge[]>([]);

  const { getBadges } = useBadges(currentPublisherId);

  useEffect(() => {
    if (!getBadges.data?.result) return;
    const badgesDropdownItems = getBadges.data.result
      .filter((badge: Badge) => badge.type === EBadgeType.EMBLEM)
      .map((badge: Badge) => ({
        content: badge.name,
        name: badge.name,
        key: badge.badgeId,
        value: badge.badgeId,
        url: badge.emblem?.imageUrl,
        position: badge.position as EBadgePosition
      }));
    setBadgeList(badgesDropdownItems);
  }, [getBadges.data]);

  const handleChangeProductSale = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const sale = Number(event.target.value);
    const updatedProductSequenceItem = {
      ...productSequenceItem,
      ...(sale !== 0 && {
        productSale: {
          sale,
          type: ESaleDiscountDisplayType.PERCENTAGE
        }
      })
    };

    updateSubOfferData(updatedProductSequenceItem, event);
  };

  const handleChangePriceDiscount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const discount = Number(event.target.value);
    const updatedProductSequenceItem = {
      ...productSequenceItem,
      ...(discount !== 0 && {
        priceDiscount: {
          discount,
          type: ESaleDiscountDisplayType.PERCENTAGE
        }
      })
    };

    updateSubOfferData(updatedProductSequenceItem, event);
  };

  const handleBadgeChange = (event: any) => {
    const selectedBadgeId = event.target.value as string;

    const currentBadge = getBadges?.data?.result?.find(
      (b: any) => b.badgeId === selectedBadgeId
    );

    const updatedProductSequenceItem = {
      ...productSequenceItem,
      ...(currentBadge && {
        badges: [
          {
            ...currentBadge,
            badgeId: currentBadge?.badgeId,
            publisherBadgeId: currentBadge?.publisherBadgeId
          }
        ]
      })
    };

    updateSubOfferData(updatedProductSequenceItem, event);
  };

  return (
    <Grid container columnSpacing={1.5} rowSpacing={1.5}>
      {productSequenceItem?.products?.length === 1 && (
        <Grid item xs={4}>
          <AcInput
            header="Main Product Sale"
            name="productSale"
            value={productSequenceItem?.productSale?.sale}
            onChange={handleChangeProductSale}
            onBlur={handleBlur}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
              inputProps: {
                min: 0,
                step: 0.1
              }
            }}
            error={touched.productSale && Boolean(errors.productSale)}
            helperText={touched.productSale ? errors.productSale?.toString() : ''}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <AcInput
          header="Price Discount"
          name="priceDiscount"
          value={productSequenceItem?.priceDiscount?.discount}
          onChange={handleChangePriceDiscount}
          onBlur={handleBlur}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            inputProps: {
              min: 0,
              step: 0.1
            }
          }}
          error={touched.priceDiscount && Boolean(errors.priceDiscount)}
          helperText={
            touched.priceDiscount ? errors.priceDiscount?.toString() : ''
          }
        />
      </Grid>
      <Grid item xs={4}>
        <AcSelect
          header="Emblem Badge"
          name="badges"
          value={productSequenceItem.badges?.[0].badgeId || ''}
          onChange={handleBadgeChange}
          onBlur={handleBlur}
          renderType={EAcSelectItemRenderType.TEXT}
          items={badgeList}
          onClear={() => {
            setFieldValue('badge', null);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CardExtrasCollapse;
