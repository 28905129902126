import { useSelector } from 'react-redux';

import useSections from 'api/useSections';
import { IS_NO_IP_ONLY_HTTPS, TRIMMED_INPUT_REGEX } from 'constants/constants';
import { AuthSliceState } from 'store/store.types';
import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

import { SectionsItem } from './StoreTheme/storeTheme.types';

export const useGetThemeValidationSchema = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const { getSections } = useSections(currentPublisherId);

  let themeValidationSchema = yup.object().shape({
    login: yup.object().shape({
      text: yup.string().required(required('Header')),
      textColor: yup.string().required(required('Font color')),
      textSize: yup.string().required(required('Font size')),
      textWeight: yup.string().required(required('Font weight')),
      loginLogoImage: yup.string().notRequired(),
      loginBackgroundImageMobile: yup.string().notRequired(),
      loginBackgroundImageDesktop: yup.string().notRequired(),
      loginLogoSize: yup.string().required(required('Logo size')),
      otpButton: yup.object().shape({
        backgroundColor: yup.object().shape({
          colorOne: yup.string().required(required('Button main color')),
          colorTwo: yup.string()
        }),
        text: yup.string(),
        icon: yup.string(),
        textColor: yup.object().shape({
          colorOne: yup.string().required(required('Text main color')),
          colorTwo: yup.string()
        })
      }),
      consentSection: yup.object().shape({
        htmlText: yup.string(),
        consentRequired: yup.boolean()
      })
    }),
    general: yup.object().shape({
      logo: yup.string().required(required('Logo')),
      font: yup.string().required(required('Font')),
      storeTabName: yup.string().required(required('Store Tab Title')),
      favicon: yup.string().required(required('Favicon')),
      backgroundImageMobile: yup
        .string()
        .required(required('Background image')),
      backgroundImageDesktop: yup
        .string()
        .required(required('Background image')),
      storeSaleImage: yup.string().notRequired(),
      buttonColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      footer: yup.object().shape({
        imageMobile: yup.string().notRequired(),
        imageDesktop: yup.string().notRequired(),
        appstoreLink: yup
          .string()
          .test(
            'Must be a valid URL, starting with https://, and not an IP address',
            'Must be a valid URL, starting with https://, and not an IP address',
            (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
          )
          .notRequired(),
        googlePlayLink: yup
          .string()
          .test(
            'Must be a valid URL, starting with https://, and not an IP address',
            'Must be a valid URL, starting with https://, and not an IP address',
            (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
          )
          .notRequired(),
        privacyPolicyUrl: yup
          .string()
          .test(
            'Must be a valid URL, starting with https://, and not an IP address',
            (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
          )
          .required(required('Privacy policy URL')),
        termsAndConditionsUrl: yup
          .string()
          .test(
            'Must be a valid URL, starting with https://, and not an IP address',
            (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
          )
          .required(required('Terms and conditions URL')),
        supportLink: yup
          .string()
          .test(
            'Must be a valid URL, starting with https://, and not an IP address',
            'Must be a valid URL, starting with https://, and not an IP address',
            (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
          )
          .notRequired(),
        openLinksInNewTab: yup.boolean()
      }),
      sectionName: yup
        .string()
        .test('is-unique', 'Section name already exists', (value) => {
          return !getSections.data?.result.some(
            (item: SectionsItem) => item.name === value
          );
        }),
      sectionId: yup
        .string()
        .test('is-unique', 'Section id already exists', (value) => {
          return !getSections.data?.result.some(
            (item: SectionsItem) => item.publisherSectionId === value
          );
        }),
      accountApprovalModal: yup.object().shape({
        backgroundImage: yup.string().required(required('Background image')),
        backgroundColor: yup.object().shape({
          colorOne: yup.string().required(required('Background color')),
          colorTwo: yup.string()
        }),
        buttonBackgroundColor: yup.object().shape({
          colorOne: yup.string().required(required('Button background color')),
          colorTwo: yup.string()
        }),
        borderWidth: yup
          .number()
          .min(0, 'Border width cannot be negative')
          .max(20, 'Border width cannot be bigger than 20px')
      })
    }),
    loaderScreen: yup.object().shape({
      headerText: yup.string().required(required('Header')),
      headerColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      headerSize: yup.string().required(required('Header font size')),
      headerWeight: yup.string().required(required('Header font weight')),
      text: yup.string(),
      textColor: yup.string(),
      textSize: yup.string(),
      textWeight: yup.string(),
      headerOn: yup.boolean(),
      textOn: yup.boolean()
    }),
    completedScreen: yup.object().shape({
      headerText: yup.string().required(required('Header')),
      headerColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      headerSize: yup.string().required(required('Header font size')),
      headerWeight: yup.string().required(required('Header font weight')),
      text: yup.string(),
      textColor: yup.string(),
      textSize: yup.string(),
      textWeight: yup.string(),
      backToGameButtonText: yup.string().required(required('Back to game text'))
    }),
    storeScreen: yup.object().shape({
      bundleBorderColor: yup.object().shape({
        colorOne: yup.string().required(required('Bundle frame main color')),
        colorTwo: yup.string()
      }),
      addToHomeScreen: yup.object().shape({
        active: yup.boolean().notRequired(),
        buttonImage: yup.string().notRequired(),
        iconImage: yup.string().required(required('Icon Image')),
        shortcutName: yup
          .string()
          .trim()
          .when('active', {
            is: true,
            then: yup
              .string()
              .trim()
              .matches(TRIMMED_INPUT_REGEX, 'Store Title should not be spaces')
              .required('Store Title is required'),
            otherwise: yup.string().notRequired()
          })
      }),
      defaultBanner: yup.string().notRequired()
    }),
    integration: yup.object().shape({
      deepLinks: yup.object().shape({
        ios: yup
          .string()
          .matches(/.+:\/\/.*/, 'Apple deep link must be a valid url'),
        android: yup
          .string()
          .matches(/.+:\/\/.*/, 'Android deep link must be a valid url'),
        web: yup
          .string()
          .matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
      })
    })
  });

  return { themeValidationSchema };
};
