import React from 'react';

import { CircularProgress } from '@mui/material';

export const ThemeLoader: React.FC = () => (
  <CircularProgress
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      height: '60px',
      width: '60px'
    }}
  />
);
