import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import useSections from 'api/useSections';
import useTheme from 'api/useTheme';
import { AxiosError } from 'axios';
import { EPublisherSolutionModel } from 'common/contracts';
import {
  EPermissionAction,
  EPermissionGroup,
  IPermissions
} from 'common/permissions.types';
import {
  EButtonColor,
  ELocalStorageKeys,
  ENotificationType
} from 'constants/enums';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { Form, Formik, FormikConfig } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { uiActions } from 'store/uiSlice';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import DialogModal from 'components/Dialog/Dialog';
import PageTopBar from 'components/Topbar/PageTopBar';

import { useGetGeneralInitialValues } from './GeneralSettings/useGetGeneralInitialValues';
import { generalSchema } from './GeneralSettings/validation-schema';
import { SectionsItem } from './StoreTheme/storeTheme.types';
import { EStoreState } from './theme.types';
import { useGetThemeInitialValues } from './useGetThemeInitialValues';
import { useGetThemeValidationSchema } from './validation-schema';

import './style.scss';

export const ThemeView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialPath = useMemo(
    () => location.pathname.split('/').filter(Boolean).pop(),
    []
  );

  const initialTab = useMemo(
    () =>
      Object.values(EStoreState).includes(initialPath as EStoreState)
        ? (initialPath as EStoreState)
        : EStoreState.GENERAL,
    []
  );

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const isCheckoutUser =
    localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
    EPublisherSolutionModel.CHECKOUT;

  const [tab, setTab] = useState<EStoreState>(
    isCheckoutUser ? EStoreState.CHECKOUT : initialTab
  );
  const [showTabs, setShowTabs] = useState();
  const {
    getStoreTheme: storeTheme,
    updateStoreTheme,
    updateGeneralTheme
  } = useTheme(currentPublisherId || undefined);
  const { updatePublisher } = usePublisher(currentPublisherId || undefined);

  const generalTheme = useMemo(
    () => ({ data: storeTheme?.data?.general }),
    [storeTheme?.data?.general]
  );

  const { getIntegration, updateIntegration } =
    useIntegration(currentPublisherId);
  const { getSections, upsertSections } = useSections(currentPublisherId);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useNotifications();
  const [isCheckoutDialogOpen, setIsCheckoutDialogOpen] = useState(false);

  const { themeValidationSchema: themeSchema } = useGetThemeValidationSchema();

  const { themeInitialValues } = useGetThemeInitialValues();
  const { initialValues: generalInitialValues } = useGetGeneralInitialValues();

  const tabConfig: Partial<Record<EStoreState, FormikConfig<any>>> = useMemo(
    () => ({
      [EStoreState.GENERAL]: {
        initialValues: generalInitialValues,
        validationSchema: generalSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
          try {
            await updateGeneralTheme.mutateAsync(values);
            dispatch(uiActions.setUnsavedChanges(false));
            setSubmitting(false);

            enqueueSnackbar('General theme updated', ENotificationType.SUCCESS);

            // await generalTheme.refetch();

            await storeTheme.refetch();

            resetForm({ values });
          } catch (error) {
            enqueueSnackbar(
              'Error updating general theme',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              )?.message || 'Unknown error occurred'
            );
          }
        }
      }
      // [EStoreState.STORE]: {},
      // [EStoreState.LOGIN]: {},
      // [EStoreState.CHECKOUT]: {},
      // [EStoreState.COMPLETED]: {},
      // [EStoreState.PAYMENTS]: {},
      // [EStoreState.EVENTS]: {}
    }),
    [generalInitialValues, generalSchema]
  );

  const defaultConfig: FormikConfig<any> = useMemo(
    () => ({
      initialValues: themeInitialValues,
      validationSchema: themeSchema,
      onSubmit: async (values, { resetForm, setSubmitting, setFieldValue }) => {
        try {
          const publisherValues = {
            storeTabName: values.general.tabName
          };
          const integrationValues = {
            deepLinks: [
              {
                platform: 'ios',
                deepLink: values.integration.deepLinks.ios
              },
              {
                platform: 'android',
                deepLink: values.integration.deepLinks.android
              },
              {
                platform: 'web',
                deepLink: values.integration.deepLinks.web
              }
            ]
          };
          if (values.checkout) {
            values.checkout.publisherId =
              currentPublisherId ||
              localStorageUtil.get(ELocalStorageKeys.PUBLISHER_ID) ||
              '';
            if (
              values?.checkout?.mobileBgImage &&
              values?.checkout?.mobileBgImage === 'none'
            ) {
              values.checkout.mobileBgImage = '';
            }
          }

          const sectionsValues = values.general.sections.map(
            (value: SectionsItem) => {
              const { _id, ...rest } = value;
              return rest;
            }
          ) as SectionsItem[];

          const filteredSectionsData = sectionsValues.filter(
            (item) => !item.toBeDeleted
          );

          const newSectionsData = filteredSectionsData.map((section) => {
            const sectionData: Partial<SectionsItem> = {
              name: section.name,
              publisherSectionId: section.publisherSectionId
            };
            if (section.image) {
              sectionData.image = section.image;
            }
            if (section.sectionId) {
              sectionData.sectionId = section.sectionId;
            }
            return sectionData;
          });
          await upsertSections.mutateAsync(newSectionsData);
          await updateStoreTheme.mutateAsync(values);
          await updatePublisher.mutateAsync(publisherValues);
          await updateIntegration.mutateAsync(integrationValues);
          dispatch(uiActions.setUnsavedChanges(false));
          await getSections.refetch();
          setSubmitting(false);

          enqueueSnackbar('Theme updated', ENotificationType.SUCCESS);
          if (tab === 'checkout') {
            setIsCheckoutDialogOpen(true);
          }
          await storeTheme.refetch();
          resetForm({ values });
        } catch (error) {
          enqueueSnackbar(
            'Error updating theme',
            ENotificationType.ERROR,
            (
              (error as AxiosError).response?.data as {
                message: string;
              }
            ).message || 'Unknown error occurred'
          );
          if (getSections.data?.result.length > 0) {
            await setFieldValue('general.sections', getSections.data?.result);
          }
        }
      }
    }),
    [themeInitialValues, themeSchema, location]
  );

  const { initialValues, validationSchema, onSubmit }: FormikConfig<any> =
    useMemo(
      () => tabConfig[tab] || defaultConfig,
      [tab, tabConfig, themeSchema, location]
    );

  useEffect(() => {
    const segments = location.pathname.split('/');
    segments[segments.length - 1] = tab;
    const newPath = segments.join('/');
    navigate(newPath);
  }, [tab]);

  useEffect(() => {
    const hasData = storeTheme.data && getIntegration.data && generalTheme.data;
    const isDoneLoading = !storeTheme.isLoading && !getIntegration.isLoading;
    setShowTabs(isDoneLoading && hasData);
  }, [
    storeTheme.isLoading,
    getIntegration.isLoading,
    storeTheme.data,
    generalTheme.data,
    getIntegration.data
  ]);

  const getTabs = useMemo(() => {
    const tabs = [
      { label: 'General Settings', value: EStoreState.GENERAL },
      { label: 'Store Page', value: EStoreState.STORE },
      { label: 'Login Page', value: EStoreState.LOGIN }
    ];
    const shouldAdminTabsBeShown =
      permissionsUtil.isSuperAdminByProject(currentPublisherId || '') ||
      permissionsUtil.isAdminByProject(currentPublisherId || '') ||
      permissionsUtil.canAccessBuilder(
        localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
        currentPublisherId || ''
      );
    if (shouldAdminTabsBeShown) {
      tabs.push({ label: 'Checkout Page', value: EStoreState.CHECKOUT });
    }
    tabs.push({ label: 'Completed Page', value: EStoreState.COMPLETED });
    return tabs;
  }, [currentPublisherId]);

  const handleTabChange = (event: SyntheticEvent, newValue: EStoreState) => {
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/theme/${newValue}`;
    navigate(newPath);
  };

  return (
    <Formik
      key={tab}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ isValid, dirty, isSubmitting, submitForm, values }) => {
        const hasValues = Object.keys(values).length > 0;

        if (!(showTabs && hasValues)) {
          return (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: '60px',
                width: '60px'
              }}
            />
          );
        }

        return (
          <Form className="storeTheme-form">
            <TabsPanel
              tabs={getTabs}
              headerComponent={
                <PageTopBar
                  withTabsDesign={true}
                  disable={false}
                  headline="Store"
                  buttons={[
                    {
                      text: 'Save',
                      action: submitForm,
                      disabled:
                        !permissionsUtil.canUserEdit() ||
                        !isValid ||
                        !dirty ||
                        isSubmitting,
                      hidden: !permissionsUtil.isActionEnabled(
                        null,
                        EPermissionGroup.BUILDER,
                        currentPublisherId,
                        EPermissionAction.MANAGE
                      )
                    }
                  ]}
                />
              }
              handleTabChange={handleTabChange}
              activeTabVal={tab}
            />
            <DialogModal
              headline="Please note"
              isOpen={isCheckoutDialogOpen}
              text="Your changes have been saved. Please allow a few minutes for them to take effect."
              buttons={[
                {
                  text: 'Got it',
                  color: EButtonColor.PRIMARY,
                  variant: 'contained',
                  func: () => setIsCheckoutDialogOpen(false)
                }
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
