import { Divider, Grid, InputAdornment } from '@mui/material';
import { OfferUiProps } from 'common/contracts';
import { EAssetType } from 'constants/enums';
import { useUploads } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';
import AcSolidInput from 'components/AcSolidInput/AcSolidInput';

const RollingOfferUiForm: React.FC<OfferUiProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  addPictureInputRef,
  getImages,
  setCurrentUploadingField,
  currentUploadingField,
  dup
}) => {
  const { uploadImage, getAssetUploadMessage } = useUploads();

  return (
    <>
      <AcCard
        stackContainer={false}
        title="Main Offer Design"
        padded={false}
        className="offersUIFormCard"
      >
        <Grid container columnSpacing={{ xs: 1 }}>
          <Grid item xs={6}>
            <input
              style={{ display: 'none' }}
              type="file"
              onChange={(e) =>
                uploadImage(
                  e,
                  addPictureInputRef.current as HTMLInputElement,
                  'backgroundImage',
                  setFieldValue,
                  setCurrentUploadingField,
                  currentUploadingField
                )
              }
              ref={addPictureInputRef}
              accept="image/*"
            />
            <AcSelect
              header="Offer background image"
              name="backgroundImage"
              value={values.backgroundImage}
              uploadConfig={{
                onUploadSuccess: async (uploadData: any) => {
                  await getImages.refetch();
                  setFieldValue('backgroundImage', uploadData!.data.url);
                },
                uploadType: EAssetType.BG_BUNDLE,
                uploadMessage: getAssetUploadMessage(values.offerUiType)
              }}
              renderType={EAcSelectItemRenderType.IMAGE}
              items={[
                {
                  content: 'Default Appcharge background image',
                  key: 'https://media.appcharge.com/defaults/background.png',
                  value: 'https://media.appcharge.com/defaults/background.png',
                  url: 'https://media.appcharge.com/defaults/background.png',
                  name: 'Default Appcharge background image'
                },
                ...getImages.data
                  .filter((p: any) => p.type === EAssetType.BG_BUNDLE)
                  .map((picture: any) => {
                    return {
                      content: picture.name,
                      key: picture.url,
                      value: picture.url,
                      url: picture.url,
                      name: picture.name
                    };
                  })
              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              onClear={() => {
                setFieldValue('backgroundImage', '');
              }}
              error={touched.backgroundImage && Boolean(errors.backgroundImage)}
              helperText={
                touched.backgroundImage || dup
                  ? errors.backgroundImage?.toString()
                  : ''
              }
              imgHeight={'32'}
            />
          </Grid>
          <Grid item xs={6}>
            <AcGradientInput
              required
              header="Offer background color"
              name="rollingOffer.backgroundColor"
              defaultValue={
                values?.rollingOffer?.backgroundColor || { colorOne: '#323281' }
              }
              onChange={handleChange}
              setValue={setFieldValue}
              onBlur={handleBlur}
              error={
                touched?.rollingOffer?.backgroundColor &&
                Boolean(errors?.rollingOffer?.backgroundColor)
              }
              helperText={
                touched?.rollingOffer?.backgroundColor
                  ? errors?.rollingOffer?.backgroundColor?.toString()
                  : ''
              }
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 1 }} rowSpacing={{ xs: 2 }}>
          <Grid item xs={6}>
            <AcGradientInput
              header="Offer border"
              name="borderColor"
              defaultValue={values.borderColor}
              onChange={handleChange}
              setValue={setFieldValue}
              onBlur={handleBlur}
              imgWidth={'32px'}
              imgHeight={'32px'}
              roundedBorders={false}
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="Offer border width"
              name="borderWidth"
              type="number"
              required
              value={Number(values.borderWidth)}
              onFocus={(e) => e.target.select()}
              onMouseUp={(e) => (e.target as HTMLInputElement).select()}
              onChange={(e) => {
                const newValue = Number(e.target.value);
                if (newValue >= 0 && newValue <= 8) {
                  setFieldValue('borderWidth', newValue);
                }
              }}
              onBlur={handleBlur}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 8
                },
                endAdornment: <InputAdornment position="end">px</InputAdornment>
              }}
              onKeyDown={(e) => {
                const currentValue = Number(values.borderWidth);
                if (
                  (e.key === 'ArrowUp' && currentValue >= 8) ||
                  (e.key === 'ArrowDown' && currentValue <= 0)
                ) {
                  e.preventDefault();
                }
              }}
              error={touched.borderWidth && Boolean(errors?.borderWidth)}
              helperText={
                touched.borderWidth ? errors?.borderWidth?.toString() : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AcSolidInput
              header="Arrows Color"
              name="rollingOffer.arrowColor"
              defaultValue={values?.rollingOffer?.arrowColor || '#FFFF00'}
              setValue={setFieldValue}
              onBlur={handleBlur}
              error={
                touched?.rollingOffer?.arrowColor &&
                Boolean(errors?.rollingOffer?.arrowColor)
              }
              helperText={
                touched?.rollingOffer?.arrowColor
                  ? errors?.rollingOffer?.arrowColor?.toString()
                  : ''
              }
            />
          </Grid>
        </Grid>
      </AcCard>
      <Divider />
      <AcCard
        stackContainer={false}
        title="Sub Offer Design"
        padded={false}
        className="offersUIFormCard"
      >
        <Grid container columnSpacing={{ xs: 1 }}>
          <Grid item xs={6}>
            <input
              style={{ display: 'none' }}
              type="file"
              onChange={(e) =>
                uploadImage(
                  e,
                  addPictureInputRef.current as HTMLInputElement,
                  'rollingOffer.subRollingOffer.backgroundImage',
                  setFieldValue,
                  setCurrentUploadingField,
                  currentUploadingField
                )
              }
              ref={addPictureInputRef}
              accept="image/*"
            />
            <AcSelect
              header="Offers background image"
              name="rollingOffer.subRollingOffer.backgroundImage"
              value={
                values?.rollingOffer?.subRollingOffer?.backgroundImage || ''
              }
              uploadConfig={{
                onUploadSuccess: async (uploadData: any) => {
                  await getImages.refetch();
                  setFieldValue(
                    'rollingOffer.subRollingOffer.backgroundImage',
                    uploadData!.data.url
                  );
                },
                uploadType: EAssetType.BG_BUNDLE,
                uploadMessage: getAssetUploadMessage(values.offerUiType)
              }}
              renderType={EAcSelectItemRenderType.IMAGE}
              items={[
                {
                  content: 'Default Appcharge background image',
                  key: 'https://media.appcharge.com/defaults/background.png',
                  value: 'https://media.appcharge.com/defaults/background.png',
                  url: 'https://media.appcharge.com/defaults/background.png',
                  name: 'Default Appcharge background image'
                },
                ...getImages.data
                  .filter((p: any) => p.type === EAssetType.BG_BUNDLE)
                  .map((picture: any) => {
                    return {
                      content: picture.name,
                      key: picture.url,
                      value: picture.url,
                      url: picture.url,
                      name: picture.name
                    };
                  })
              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              onClear={() => {
                setFieldValue(
                  'rollingOffer.subRollingOffer.backgroundImage',
                  ''
                );
              }}
              error={
                touched?.rollingOffer?.subRollingOffer?.backgroundImage &&
                Boolean(errors?.rollingOffer?.subRollingOffer?.backgroundImage)
              }
              helperText={
                touched?.rollingOffer?.subRollingOffer?.backgroundImage || dup
                  ? errors?.rollingOffer?.subRollingOffer?.backgroundImage?.toString()
                  : ''
              }
              imgHeight={'32'}
            />
          </Grid>
          <Grid item xs={6}>
            <AcGradientInput
              required={true}
              header="Offers background color"
              name="rollingOffer.subRollingOffer.backgroundColor"
              defaultValue={
                values?.rollingOffer?.subRollingOffer?.backgroundColor || {
                  colorOne: '#ffffff'
                }
              }
              onChange={handleChange}
              setValue={setFieldValue}
              onBlur={handleBlur}
              error={
                touched?.rollingOffer?.subRollingOffer?.backgroundColor &&
                Boolean(errors?.rollingOffer?.subRollingOffer?.backgroundColor)
              }
              helperText={
                touched.rollingOffer?.subRollingOffer?.backgroundColor
                  ? errors.rollingOffer?.subRollingOffer?.backgroundColor?.toString()
                  : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              required
              header="Collect Text"
              name="rollingOffer.subRollingOffer.collectText"
              value={
                values?.rollingOffer?.subRollingOffer?.collectText || 'FREE'
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.rollingOffer?.subRollingOffer?.collectText &&
                Boolean(errors.rollingOffer?.subRollingOffer?.collectText)
              }
              helperText={
                touched.rollingOffer?.subRollingOffer?.collectText || dup
                  ? errors.rollingOffer?.subRollingOffer?.collectText?.toString()
                  : ''
              }
            />
          </Grid>
        </Grid>
      </AcCard>
    </>
  );
};

export default RollingOfferUiForm;
