import { Grid } from '@mui/material';

import AcCard from '../../../AcCard/AcCard';
import AcSelect from '../../../AcSelect/AcSelect';
import { adminSettingsUtils } from 'utils/selectOptionsUtils';

import '../../../../style/forms.scss';
import '../../../Theme/style.scss';

export const QuantityFormat: React.FC<{
  title: string;
  quantityFormat: any;
  name: string;
  isFetching: boolean;
  touched: any;
  errors: any;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
}> = ({
  title,
  quantityFormat,
  name,
  isFetching,
  touched,
  errors,
  handleChange,
  handleBlur
}) => {
  return (
    <AcCard stackContainer={false} title={title}>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={6}>
          <AcSelect
            loading={isFetching}
            header={'Thousands separator'}
            name={`${name}.milSeparator`}
            value={quantityFormat.milSeparator}
            items={adminSettingsUtils.separatorOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.milSeparator && Boolean(errors?.milSeparator)}
          />
        </Grid>
        <Grid item xs={6}>
          <AcSelect
            loading={isFetching}
            header={'Decimal separator'}
            name={`${name}.fractionalSeparator`}
            value={quantityFormat.fractionalSeparator}
            items={adminSettingsUtils.separatorOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.fractionalSeparator &&
              Boolean(errors?.fractionalSeparator)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <AcSelect
            loading={isFetching}
            header="Abbreviate thousands (K)"
            name={`${name}.thousandShorthand`}
            value={String(quantityFormat.thousandShorthand)}
            items={adminSettingsUtils.trueFalseOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.thousandShorthand && Boolean(errors?.thousandShorthand)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <AcSelect
            loading={isFetching}
            header="Abbreviate millions (M)"
            name={`${name}.millionShorthand`}
            value={String(quantityFormat.millionShorthand)}
            items={adminSettingsUtils.trueFalseOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.millionShorthand && Boolean(errors?.millionShorthand)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <AcSelect
            loading={isFetching}
            header="Abbreviate billions (B)"
            name={`${name}.billionShorthand`}
            value={String(quantityFormat.billionShorthand)}
            items={adminSettingsUtils.trueFalseOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.billionShorthand && Boolean(errors?.billionShorthand)
            }
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};
