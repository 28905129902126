import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import useSections from 'api/useSections';
import useTheme from 'api/useTheme';
import { ELogoSize, ESectionViewModel } from 'constants/enums';
import { AuthSliceState } from 'store/store.types';

import { defaultTheme } from './defaults';

export const useGetThemeInitialValues = () => {
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [initialValues, setInitialValue] = useState<any>({});
  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );
  const { getPublisher } = usePublisher(currentPublisherId || undefined);
  const { getSections } = useSections(currentPublisherId);
  const { getIntegration } = useIntegration(currentPublisherId);

  useEffect(() => {
    setInitialValue({
      general: {
        logo: storeTheme.data?.general?.logo || defaultTheme.general.logo,
        font: storeTheme.data?.general?.font || defaultTheme.general.font,
        tabName:
          getPublisher.data?.storeTabName || defaultTheme.general.tabName,
        favicon:
          storeTheme.data?.general?.favicon || defaultTheme.general.favicon,
        buttonColor: {
          colorOne:
            storeTheme.data?.general?.buttonColor.colorOne ||
            defaultTheme.general.buttonColor.colorOne,
          colorTwo:
            storeTheme.data?.general?.buttonColor.colorTwo ||
            defaultTheme.general.buttonColor.colorTwo,
          gradientDirection:
            storeTheme.data?.general?.buttonColor.gradientDirection ||
            defaultTheme.general.buttonColor.gradientDirection
        },
        backgroundImageMobile:
          storeTheme.data?.general?.backgroundImageMobile ||
          defaultTheme.general.backgroundImageMobile,
        backgroundImageDesktop:
          storeTheme.data?.general?.backgroundImageDesktop ||
          defaultTheme.general.backgroundImageDesktop,
        storeSaleImage:
          storeTheme.data?.general?.storeSaleImage ||
          defaultTheme.general.storeSaleImage,
        buttonTextColor:
          storeTheme.data?.general?.buttonTextColor ||
          defaultTheme.general.buttonTextColor,
        bundlesViewModel:
          storeTheme.data?.general?.bundlesViewModel ||
          defaultTheme.general.bundlesViewModel,
        bundlesInternalViewModel:
          storeTheme.data?.general?.bundlesInternalViewModel ||
          defaultTheme.general.bundlesInternalViewModel,
        specialOffersViewModel:
          storeTheme.data?.general?.specialOffersViewModel ||
          defaultTheme.general.specialOffersViewModel,
        specialOffersInternalViewModel:
          storeTheme.data?.general?.specialOffersInternalViewModel ||
          defaultTheme.general.specialOffersInternalViewModel,
        footer: {
          imageMobile:
            storeTheme.data?.general?.footer?.imageMobile ||
            defaultTheme.general.footer.imageMobile,
          imageDesktop:
            storeTheme.data?.general?.footer?.imageDesktop ||
            defaultTheme.general.footer.imageDesktop,
          appstoreLink:
            storeTheme.data?.general?.footer?.appstoreLink ||
            defaultTheme.general.footer.appstoreLink,
          googlePlayLink:
            storeTheme.data?.general?.footer?.googlePlayLink ||
            defaultTheme.general.footer.googlePlayLink,
          privacyPolicyUrl:
            storeTheme.data?.general?.footer?.privacyPolicyUrl ||
            defaultTheme.general.footer.privacyPolicyUrl,
          termsAndConditionsUrl:
            storeTheme.data?.general?.footer?.termsAndConditionsUrl ||
            defaultTheme.general.footer.termsAndConditionsUrl,
          supportLink:
            storeTheme.data?.general?.footer?.supportLink ||
            defaultTheme.general.footer.supportLink,
          openLinksInNewTab:
            storeTheme.data?.general?.footer?.openLinksInNewTab ||
            defaultTheme.general?.footer.openLinksInNewTab
        },
        sections: getSections.data?.result || defaultTheme.general.sections,
        sectionId: '',
        sectionViewModel:
          storeTheme.data?.general?.sectionViewModel ||
          ESectionViewModel.DEFAULT,
        accountApprovalModal: {
          backgroundImage:
            storeTheme.data?.general?.accountApprovalModal?.backgroundImage ||
            defaultTheme.general.accountApprovalModal.backgroundImage,
          backgroundColor: {
            colorOne:
              storeTheme.data?.general?.accountApprovalModal?.backgroundColor
                ?.colorOne ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .colorOne,
            colorTwo:
              storeTheme.data?.general?.accountApprovalModal?.backgroundColor
                ?.colorTwo ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .colorTwo,
            gradientDirection:
              storeTheme.data?.general?.accountApprovalModal?.backgroundColor
                ?.gradientDirection ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .gradientDirection
          },
          borderColor:
            storeTheme.data?.general?.accountApprovalModal?.borderColor ||
            defaultTheme.general.accountApprovalModal.borderColor,
          borderWidth:
            storeTheme.data?.general?.accountApprovalModal?.borderWidth ||
            defaultTheme.general.accountApprovalModal.borderWidth,
          buttonBackgroundColor: {
            colorOne:
              storeTheme.data?.general?.accountApprovalModal
                ?.buttonBackgroundColor?.colorOne ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .colorOne,
            colorTwo:
              storeTheme.data?.general?.accountApprovalModal
                ?.buttonBackgroundColor?.colorTwo ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .colorTwo,
            gradientDirection:
              storeTheme.data?.general?.accountApprovalModal
                ?.buttonBackgroundColor?.gradientDirection ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .gradientDirection
          },
          buttonTextColor:
            storeTheme.data?.general?.accountApprovalModal?.buttonTextColor ||
            defaultTheme.general.accountApprovalModal.buttonTextColor,
          textColor:
            storeTheme.data?.general?.accountApprovalModal?.textColor ||
            defaultTheme.general.accountApprovalModal.textColor
        }
      },
      login: {
        text: storeTheme.data?.login?.text || defaultTheme.login.text,
        font: storeTheme.data?.login?.font || defaultTheme.login.font,
        textSize:
          storeTheme.data?.login?.textSize || defaultTheme.login.textSize,
        textColor:
          storeTheme.data?.login?.textColor || defaultTheme.login.textColor,
        textWeight:
          storeTheme.data?.login?.textWeight || defaultTheme.login.textWeight,
        loginLogoImage:
          storeTheme.data?.login?.loginLogoImage ||
          defaultTheme.login.loginLogoImage,
        loginBackgroundImageMobile:
          storeTheme.data?.login?.loginBackgroundImageMobile ||
          defaultTheme.login.loginBackgroundImageMobile,
        loginBackgroundImageDesktop:
          storeTheme.data?.login?.loginBackgroundImageDesktop ||
          defaultTheme.login.loginBackgroundImageDesktop,
        loginLogoSize: storeTheme.data?.login?.loginLogoSize || ELogoSize.SMALL,
        otpButton: {
          backgroundColor: {
            colorOne:
              storeTheme.data?.login?.otpButton?.backgroundColor?.colorOne ||
              defaultTheme.login.otpButton.backgroundColor.colorOne,
            colorTwo:
              storeTheme.data?.login?.otpButton?.backgroundColor?.colorTwo ||
              defaultTheme.login.otpButton.backgroundColor.colorTwo,
            gradientDirection:
              storeTheme.data?.login?.otpButton?.backgroundColor
                ?.gradientDirection ||
              defaultTheme.login.otpButton.backgroundColor.gradientDirection
          },
          icon:
            storeTheme.data?.login?.otpButton?.icon ||
            defaultTheme.login.otpButton.icon,
          text:
            storeTheme.data?.login?.otpButton?.text ||
            defaultTheme.login.otpButton.text,
          textColor: {
            colorOne:
              storeTheme.data?.login?.otpButton?.textColor?.colorOne ||
              defaultTheme.login.otpButton.textColor.colorOne,
            colorTwo:
              storeTheme.data?.login?.otpButton?.textColor?.colorTwo ||
              defaultTheme.login.otpButton.textColor.colorTwo,
            gradientDirection:
              storeTheme.data?.login?.otpButton?.textColor?.gradientDirection ||
              defaultTheme.login.otpButton.textColor.gradientDirection
          }
        },
        consentSection: {
          htmlText:
            storeTheme.data?.login?.consentSection?.htmlText ||
            defaultTheme.login.consentSection.htmlText,
          consentRequired:
            storeTheme.data?.login?.consentSection?.consentRequired ||
            defaultTheme.login.consentSection.consentRequired
        }
      },
      loaderScreen: {
        headerText:
          storeTheme.data?.loaderScreen?.headerText ||
          defaultTheme.loaderScreen.headerText,
        headerColor: {
          colorOne:
            storeTheme.data?.loaderScreen?.headerColor.colorOne ||
            defaultTheme.loaderScreen.headerColor.colorOne,
          colorTwo:
            storeTheme.data?.loaderScreen?.headerColor.colorTwo ||
            defaultTheme.loaderScreen.headerColor.colorTwo,
          gradientDirection:
            storeTheme.data?.loaderScreen?.headerColor.gradientDirection ||
            defaultTheme.loaderScreen.headerColor.gradientDirection
        },
        headerSize:
          storeTheme.data?.loaderScreen?.headerSize ||
          defaultTheme.loaderScreen.headerSize,
        headerWeight:
          storeTheme.data?.loaderScreen?.headerWeight ||
          defaultTheme.loaderScreen.headerWeight,
        text:
          storeTheme.data?.loaderScreen?.text || defaultTheme.loaderScreen.text,
        textColor:
          storeTheme.data?.loaderScreen?.textColor ||
          defaultTheme.loaderScreen.textColor,
        textSize:
          storeTheme.data?.loaderScreen?.textSize ||
          defaultTheme.loaderScreen.textSize,
        textWeight:
          storeTheme.data?.loaderScreen?.textWeight ||
          defaultTheme.loaderScreen.textWeight,
        headerOn: defaultTheme.loaderScreen.headerOn,
        textOn: defaultTheme.loaderScreen.textOn
      },
      completedScreen: {
        headerText:
          storeTheme.data?.completedScreen?.headerText ||
          defaultTheme.completedScreen.headerText,
        headerColor: {
          colorOne:
            storeTheme.data?.completedScreen?.headerColor.colorOne ||
            defaultTheme.completedScreen.headerColor.colorOne,
          colorTwo:
            storeTheme.data?.completedScreen?.headerColor.colorTwo ||
            defaultTheme.completedScreen.headerColor.colorTwo,
          gradientDirection:
            storeTheme.data?.completedScreen?.headerColor.gradientDirection ||
            defaultTheme.completedScreen.headerColor.gradientDirection
        },
        headerSize:
          storeTheme.data?.completedScreen?.headerSize ||
          defaultTheme.completedScreen.headerSize,
        headerWeight:
          storeTheme.data?.completedScreen?.headerWeight ||
          defaultTheme.completedScreen.headerWeight,
        text:
          storeTheme.data?.completedScreen?.text ||
          defaultTheme.completedScreen.text,
        textColor:
          storeTheme.data?.completedScreen?.textColor ||
          defaultTheme.completedScreen.textColor,
        textSize:
          storeTheme.data?.completedScreen?.textSize ||
          defaultTheme.completedScreen.textSize,
        textWeight:
          storeTheme.data?.completedScreen?.textWeight ||
          defaultTheme.completedScreen.textWeight,
        backToGameButtonText:
          storeTheme.data?.completedScreen?.backToGameButtonText ||
          defaultTheme.completedScreen.backToGameButtonText
      },
      storeScreen: {
        bundleBorderColor: {
          colorOne:
            storeTheme.data?.storeScreen?.bundleBorderColor.colorOne ||
            defaultTheme.storeScreen.bundleBorderColor.colorOne,
          colorTwo:
            storeTheme.data?.storeScreen?.bundleBorderColor.colorTwo ||
            defaultTheme.storeScreen.bundleBorderColor.colorTwo,
          gradientDirection:
            storeTheme.data?.storeScreen?.bundleBorderColor.gradientDirection ||
            defaultTheme.storeScreen.bundleBorderColor.gradientDirection
        },
        noOffersTitleText:
          storeTheme.data?.storeScreen?.noOffersTitleText ||
          defaultTheme.storeScreen,
        noOffersMessageText: storeTheme.data?.storeScreen?.noOffersMessageText,
        defaultBanner:
          storeTheme.data?.storeScreen?.defaultBanner ||
          defaultTheme.storeScreen.defaultBanner,
        addToHomeScreen: {
          active:
            storeTheme.data?.storeScreen?.addToHomeScreen?.active ||
            defaultTheme.storeScreen.addToHomeScreen.active,
          buttonImage:
            storeTheme.data?.storeScreen?.addToHomeScreen?.buttonImage ||
            defaultTheme.storeScreen.addToHomeScreen.buttonImage,
          iconImage:
            storeTheme.data?.storeScreen?.addToHomeScreen?.iconImage ||
            defaultTheme.storeScreen.addToHomeScreen.iconImage,
          shortcutName:
            storeTheme.data?.storeScreen?.addToHomeScreen?.shortcutName ||
            defaultTheme.storeScreen.addToHomeScreen.shortcutName,
          borderColor: {
            colorOne:
              storeTheme.data?.storeScreen?.addToHomeScreen?.borderColor
                ?.colorOne ||
              defaultTheme.storeScreen.addToHomeScreen.borderColor.colorOne
          },
          backgroundColor: {
            colorOne:
              storeTheme.data?.storeScreen?.addToHomeScreen?.backgroundColor
                ?.colorOne ||
              defaultTheme.storeScreen.addToHomeScreen.backgroundColor.colorOne,
            colorTwo:
              storeTheme.data?.storeScreen?.addToHomeScreen?.backgroundColor
                ?.colorTwo ||
              defaultTheme.storeScreen.addToHomeScreen.backgroundColor.colorTwo
          }
        }
      },
      storeTemplate: {
        bodyBlocksOrder:
          storeTheme.data?.storeTemplate?.bodyBlocksOrder ||
          defaultTheme.storeTemplate.bodyBlocksOrder
      },
      integration: {
        deepLinks: {
          ios:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'ios'
            )?.deepLink || '',
          android:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'android'
            )?.deepLink || '',
          web:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'web'
            )?.deepLink || ''
        }
      },
      checkout: {
        backgroundImage: storeTheme.data?.checkout?.backgroundImage || '',
        buttonColor: storeTheme.data?.checkout?.buttonColor,
        buttonTextColor: storeTheme.data?.checkout?.buttonTextColor || '',
        mobileBgImage: storeTheme.data?.checkout?.mobileBgImage || '',
        primaryColor: storeTheme.data?.checkout?.primaryColor || '',
        publisherId: storeTheme.data?.checkout?.publisherId || '',
        textColor: storeTheme.data?.checkout?.textColor || '',
        _id: storeTheme.data?.checkout?._id || '',
        logo:
          storeTheme.data?.checkout?.logo ||
          storeTheme.data?.general?.logo ||
          defaultTheme.general.logo ||
          ''
      }
    });
  }, [storeTheme.data, getPublisher.data, location]);

  return { themeInitialValues: initialValues };
};
