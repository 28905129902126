import { FC, useCallback } from 'react';
import { Autocomplete, Box, TextField, FormLabel } from '@mui/material';
import { StyledImgSelect } from './style';

export interface ImgOption {
  key: string;
  url: string;
  name: string;
  value?: string
}
export interface AutocompleteInputProps {
  options: ImgOption[];
  onChange: (newValue: any) => void;
  currentOption: ImgOption | null;
  label?: string;
  placeholder?: string;
  sx?: Record<string, unknown>;
  autoComplete?: string;
}

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  options,
  onChange,
  currentOption,
  label,
  placeholder= "",
  sx,
  autoComplete = "new-password",
}) => {
  const handleChange = useCallback((e: any, newValue: any) => {
    onChange(newValue);
  }, [onChange]);

  return (
    <StyledImgSelect>
      {label && <FormLabel className="imgSelect-label" htmlFor="autocomplete-input">{label}</FormLabel>}
      <Autocomplete
        id="autocomplete-input"
        sx={{ width: '100%' }}
        options={options}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ cursor: 'pointer' }}
                {...optionProps}
              >
                <img
                  width="32"
                  height="32"
                  src={option.url}
                  alt={option.name}
                  style={{'marginRight': '8px'}}
                />
                {option.name}
              </Box>
            );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder={placeholder}
            sx={sx}
            InputProps={{
              ...params.InputProps,
              startAdornment: currentOption ? <img width="32" src={currentOption.url} alt={currentOption.name} /> : null
            }}
            slotProps={{
              htmlInput: {
                ...params.inputProps,
                autoComplete
              },
            }}
          />
        )}
        onChange={handleChange}
        value={currentOption || null}
      />
    </StyledImgSelect>
  )
}
