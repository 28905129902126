import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Background,
  BundlesLayout,
  EBadgePosition,
  EBundlesViewModel
} from '@appcharge/shared-ui';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useBadges from 'api/useBadges';
import useImages from 'api/useImages';
import useTheme from 'api/useTheme';
import { Badge, EBadgeType } from 'common/contracts';
import AcCard from 'components/AcCard/AcCard';
import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import { GradientDirection } from 'components/AcGradientInput/AcGraidentInput.types';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import PageTopBar from 'components/Topbar/PageTopBar';
import { DefaultAssetsURLs, TRIMMED_INPUT_REGEX } from 'constants/constants';
import {
  EAssetType,
  EBundlesInternalViewModel,
  ENotificationType
} from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { UploadsTooltips, useUploads } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';
import { renderingUtil } from 'utils/renderingUtil';

import 'style/forms.scss';

const BadgesForm = ({
  edit = false,
  dup = false
}: {
  edit?: boolean;
  dup?: boolean;
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { offerId } = useParams();
  const navigate = useNavigate();
  const { uploadImage } = useUploads();
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadingField, setCurrentUploadingField] = useState('');
  const [skuExists, setSkuExists] = useState<boolean>(false);
  const [data, setData] = useState<Partial<Badge>>({});
  const { getImages } = useImages(currentPublisherId);
  const { addBadge, updateBadge, getBadge, getBadges } = useBadges(
    currentPublisherId,
    offerId
  );
  const { enqueueSnackbar } = useNotifications();
  const { getStoreTheme } = useTheme(currentPublisherId);
  const [disabled, setDisabled] = useState<boolean>(true);

  const viewModel = getStoreTheme?.data?.general
    .bundlesViewModel as EBundlesViewModel;
  const bundleTemplate = getStoreTheme?.data?.general
    .bundlesInternalViewModel as EBundlesInternalViewModel;
  const checkUniqueness = (value: any, field: keyof Badge) => {
    const isTaken = getBadges.data?.result.some((b: any) => {
      if (!edit) return b[field] === value;
      return b[field] === value && data[field] !== value;
    });
    return !isTaken;
  };

  const relevantBadgePosition =
    viewModel === EBundlesViewModel.LINEAR
      ? ('left' as EBadgePosition)
      : ('center' as EBadgePosition);

  const updateInfoSchemaEmblem = yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    type: yup.string().required(),
    badgeImage: yup.string().required(),
    publisherBadgeId: yup
      .string()
      .trim()
      .matches(TRIMMED_INPUT_REGEX, 'External ID should not be spaces')
      .required('External ID is required')
      .test('uniqueness', 'External ID already exists', (value) => {
        return checkUniqueness(value, 'publisherBadgeId');
      })
  });

  const updateInfoSchemaRibbon = yup.object().shape({
    name: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    type: yup.string().required(),
    ribbon: yup.object().shape({
      text: yup.string().required('Ribbon text should not be empty'),
      backgroundColor: yup.object().required(),
      textColor: yup.object().required(),
      position: yup.string().required('Ribbon position is required')
    }),
    publisherBadgeId: yup
      .string()
      .trim()
      .matches(TRIMMED_INPUT_REGEX, 'External ID should not be spaces')
      .required('External ID is required')
      .test('uniqueness', 'External ID already exists', (value) => {
        return checkUniqueness(value, 'publisherBadgeId');
      })
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    setFieldValue,
    dirty,
    isSubmitting
  } = useFormik({
    initialValues: {
      name: getBadge.data?.result?.name
        ? `${getBadge.data?.result?.name}${dup ? '_copy' : ''}`
        : '',
      type:
        getBadge.data?.result?.type ||
        (viewModel === EBundlesViewModel.LINEAR &&
        bundleTemplate !== EBundlesInternalViewModel.PLAY_SIMPLE
          ? 'emblem'
          : 'ribbon'),
      badgeImage: getBadge.data?.result?.emblem?.imageUrl || '',
      publisherBadgeId: getBadge.data?.result?.publisherBadgeId
        ? `${getBadge.data?.result?.publisherBadgeId}${dup ? '_copy' : ''}`
        : '',
      ribbon: {
        text: getBadge.data?.result?.ribbon?.text || '',
        backgroundColor: {
          colorOne:
            getBadge.data?.result?.ribbon?.backgroundColor?.colorOne ||
            '#6109FF',
          colorTwo:
            getBadge.data?.result?.ribbon?.backgroundColor?.colorTwo || '',
          gradientDirection:
            getBadge.data?.result?.ribbon?.backgroundColor?.gradientDirection ||
            GradientDirection.TopToBottom
        },
        textColor: {
          colorOne: getBadge.data?.result?.ribbon?.textColor?.colorOne || '#fff'
        },
        position:
          getBadge.data?.result?.ribbon?.position ||
          ('top-right' as EBadgePosition.CENTER)
      }
    },
    validateOnMount: true,
    validationSchema: () => {
      if (values.type === EBadgeType.EMBLEM) {
        return updateInfoSchemaEmblem;
      } else {
        return updateInfoSchemaRibbon;
      }
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newBadge: Badge = {
        name: values.name,
        type: values.type as EBadgeType,
        publisherBadgeId: values.publisherBadgeId,
        [values.type as EBadgeType]:
          EBadgeType.EMBLEM === values.type
            ? {
                imageUrl: values.badgeImage
              }
            : {
                text: values.ribbon.text,
                backgroundColor: values.ribbon.backgroundColor,
                textColor: values.ribbon.textColor,
                position: values.ribbon.position
              },
        position: relevantBadgePosition
      } as any;

      if (edit && offerId && !dup) {
        updateBadge.mutate(
          { badgeId: offerId, form: newBadge },
          {
            onSuccess: () => {
              enqueueSnackbar(
                'Badge updated successfully',
                ENotificationType.SUCCESS
              );
              navigate('../');
            },
            onError: () => {
              enqueueSnackbar('Error updating badge', ENotificationType.ERROR);
            }
          }
        );
      } else {
        addBadge.mutate(newBadge, {
          onSuccess: () => {
            enqueueSnackbar(
              'Badge added successfully',
              ENotificationType.SUCCESS
            );
            navigate('../');
          },
          onError: () => {
            enqueueSnackbar('Error adding badge', ENotificationType.ERROR);
          }
        });
      }
    }
  });

  useEffect(() => {
    if (offerId && currentPublisherId) {
      getBadge.refetch();
    }
  }, [offerId, currentPublisherId]);

  useEffect(() => {
    if (!!offerId && !getBadge.isLoading && getBadge.data) {
      setData(getBadge.data.result);
    }
  }, [getBadge.data, getBadge.isLoading, offerId]);

  const ribbonPositionList = [
    {
      content: 'Upper Right Corner',
      key: 'top-right' as EBadgePosition.TOP_RIGHT,
      value: 'top-right' as EBadgePosition.TOP_RIGHT
    },
    {
      content: 'Center',
      key: 'center' as EBadgePosition.CENTER,
      value: 'center' as EBadgePosition.CENTER
    }
  ];

  const getBadgeTypeList = () => {
    return [
      {
        content: 'Emblem',
        key: 'emblem',
        value: 'emblem'
      },
      {
        content: 'Ribbon',
        key: 'ribbon',
        value: 'ribbon'
      }
    ];
  };

  const isSingleBundle =
    values.type === EBadgeType.RIBBON &&
    (bundleTemplate === EBundlesInternalViewModel.MATCH ||
      bundleTemplate === EBundlesInternalViewModel.CANDIVORE ||
      bundleTemplate === EBundlesInternalViewModel.COMMUNIX);

  useEffect(() => {
    setDisabled(!isValid || !dirty || isSubmitting);
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    setSkuExists(
      getBadges.data?.result.find((b: any) => {
        return b.publisherBadgeId === values.publisherBadgeId;
      }) && !edit
    );
  }, [values.publisherBadgeId]);

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={`${edit ? 'Edit' : 'New'} Badge ${
            edit ? `(${offerId})` : ''
          }`}
          buttons={[
            {
              text: 'Save',
              action: submitForm
              //disabled: !permissionsUtil.canUserEdit() || disabled
            }
          ]}
          backFunction={() => navigate('../')}
        />
      }
    >
      <AcContentWrapper className="formContent">
        {!getImages.isLoading && getImages.data && (
          <Grid container pt={3}>
            <Grid item xs={5}>
              <Stack>
                <AcCard
                  stackContainer={false}
                  title="Badge Settings"
                  padded={false}
                >
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcInput
                        header="Name"
                        required
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        headerSize="12"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name ? errors.name?.toString() : ''}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingTop: '12px' }}>
                      <AcInput
                        header="External ID"
                        required
                        name="publisherBadgeId"
                        value={values.publisherBadgeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        headerSize="12"
                        error={
                          touched.publisherBadgeId &&
                          Boolean(errors.publisherBadgeId)
                        }
                        helperText={
                          touched.publisherBadgeId
                            ? errors.publisherBadgeId?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingTop: '12px' }}>
                      <AcSelect
                        header="Type"
                        name="type"
                        defaultValue="emblem"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        items={getBadgeTypeList()}
                        headerSize="12"
                      />
                    </Grid>
                    {isSingleBundle && (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          paddingTop: '12px'
                        }}
                      >
                        <AcSelect
                          header="Position"
                          name="ribbon.position"
                          value={values.ribbon.position}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          items={ribbonPositionList}
                          headerSize="12"
                          error={
                            touched.ribbon?.position &&
                            Boolean(errors.ribbon?.position)
                          }
                          helperText={
                            touched.ribbon?.position
                              ? errors.ribbon?.position?.toString()
                              : ''
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </AcCard>
                <Divider />
                <div>
                  {values.type === EBadgeType.EMBLEM ? (
                    <AcCard
                      stackContainer={false}
                      title={'Badge Asset'}
                      padded={false}
                      className="badgesFormCard"
                    >
                      <Grid container rowSpacing={2} columnSpacing={1.5}>
                        <Grid item xs={6}>
                          <input
                            style={{
                              display: 'none'
                            }}
                            type="file"
                            onChange={(e) =>
                              uploadImage(
                                e,
                                addPictureInputRef.current as HTMLInputElement,
                                getImages,
                                setFieldValue,
                                setCurrentUploadingField,
                                currentUploadingField
                              )
                            }
                            ref={addPictureInputRef}
                            accept="image/*"
                          />
                          <AcSelect
                            header="Image File"
                            required
                            name="badgeImage"
                            value={values.badgeImage}
                            uploadConfig={{
                              onUploadSuccess: async (uploadData: any) => {
                                await getImages.refetch();
                                await setFieldValue(
                                  'badgeImage',
                                  uploadData!.data.url
                                );
                              },
                              uploadType: EAssetType.BADGE,
                              uploadMessage: UploadsTooltips[EAssetType.BADGE]
                            }}
                            items={[
                              {
                                content: 'Default Appcharge logo',
                                key: DefaultAssetsURLs.defaultLogo,
                                value: DefaultAssetsURLs.defaultLogo,
                                renderFunction: () => {
                                  return (
                                    <Stack
                                      className="formContent-imageSelected"
                                      direction="row"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      <img
                                        src={DefaultAssetsURLs.defaultLogo}
                                        alt="Default Appcharge logo"
                                        style={{
                                          maxWidth: 30
                                        }}
                                      />
                                      <Typography>
                                        Default Appcharge logo
                                      </Typography>
                                    </Stack>
                                  );
                                }
                              },
                              ...getImages.data
                                .filter((p: any) => p.type === EAssetType.BADGE)
                                .map((picture: any) => {
                                  return {
                                    content: picture.name,
                                    key: picture.url,
                                    value: picture.url,
                                    renderFunction: () => {
                                      return (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          overflow="hidden"
                                          gap={1}
                                        >
                                          <img
                                            src={picture.url}
                                            alt={picture.name}
                                            style={{
                                              width: 32,
                                              maxHeight: 32
                                            }}
                                          />
                                          <Typography>
                                            {picture.name}
                                          </Typography>
                                        </Stack>
                                      );
                                    }
                                  };
                                })
                            ]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched?.badgeImage && Boolean(errors?.badgeImage)
                            }
                            headerSize="12"
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                  ) : (
                    <AcCard
                      stackContainer={false}
                      title={'Text'}
                      padded={false}
                      className="badgesFormCard"
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <AcInput
                            header="Ribbon Text"
                            required
                            name="ribbon.text"
                            value={values.ribbon.text}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            headerSize={'12'}
                            error={
                              touched.ribbon?.text &&
                              Boolean(errors.ribbon?.text)
                            }
                            helperText={
                              touched.ribbon?.text
                                ? errors.ribbon?.text?.toString()
                                : ''
                            }
                          />
                        </Grid>

                        <Divider
                          sx={{
                            margin: '24px 0'
                          }}
                        />

                        <Grid item xs={12}>
                          <AcCard
                            stackContainer={false}
                            title={'Color'}
                            padded={false}
                            className="badgesFormCard"
                          >
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                              <Grid item xs={6} paddingTop={'12px'}>
                                <AcGradientInput
                                  header="Primary Color"
                                  name="ribbon.backgroundColor"
                                  defaultValue={values.ribbon?.backgroundColor}
                                  onChange={handleChange}
                                  setValue={setFieldValue}
                                  onBlur={handleBlur}
                                  error={
                                    touched.ribbon?.backgroundColor &&
                                    Boolean(errors.ribbon?.backgroundColor)
                                  }
                                  helperText={
                                    touched.ribbon?.backgroundColor
                                      ? errors.ribbon?.backgroundColor?.toString()
                                      : ''
                                  }
                                  imgWidth={'32px'}
                                  imgHeight={'32px'}
                                  roundedBorders={false}
                                />
                              </Grid>

                              <Grid item xs={6} paddingTop={'12px'}>
                                <AcGradientInput
                                  onlySolid
                                  header="Text Color"
                                  name="ribbon.textColor.colorOne"
                                  defaultValue={values.ribbon.textColor}
                                  onChange={handleChange}
                                  setValue={setFieldValue}
                                  onBlur={handleBlur}
                                  error={
                                    touched.ribbon?.textColor &&
                                    Boolean(errors.ribbon?.textColor)
                                  }
                                  imgWidth={'32px'}
                                  imgHeight={'32px'}
                                  roundedBorders={false}
                                />
                              </Grid>
                            </Grid>
                          </AcCard>
                        </Grid>
                      </Grid>
                    </AcCard>
                  )}
                </div>
              </Stack>
            </Grid>
            <Grid item xs={7} className="iphone-mock-wrapper">
              <div className="iphone-mock">
                <Background
                  backgroundImageMobile={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general.backgroundImageMobile
                  }
                  backgroundImageDesktop={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general.backgroundImageMobile
                  }
                  width="100%"
                  height="100%"
                  position="absolute"
                />
                <BundlesLayout bundlesViewModel={viewModel} isPreview={true}>
                  <div
                    className="bundles-wrapper"
                    style={{
                      justifyContent: 'space-between'
                    }}
                  >
                    {!getStoreTheme.isLoading &&
                      Array.from(
                        {
                          length:
                            viewModel === EBundlesViewModel.TWO_IN_ONE
                              ? 2
                              : viewModel === EBundlesViewModel.THREE_IN_ONE
                                ? 3
                                : 1
                        },
                        () => null
                      ).map((_, index) =>
                        renderingUtil.renderBundleByInternalModel(
                          getStoreTheme.data.general.bundlesInternalViewModel,
                          {
                            inMobileMock: true,
                            badges: [
                              {
                                type: values.type,
                                position:
                                  values.type === EBadgeType.EMBLEM
                                    ? ('right' as EBadgePosition)
                                    : 'center',
                                [values.type as EBadgeType]:
                                  EBadgeType.EMBLEM === values.type
                                    ? {
                                        imageUrl: values.badgeImage
                                      }
                                    : {
                                        text: values.ribbon.text,
                                        backgroundColor:
                                          values.ribbon.backgroundColor,
                                        textColor: values.ribbon.textColor,
                                        position: values.ribbon.position
                                      },
                                name: 'preview'
                              }
                            ], // ACDEV-9385: remove old badge schema
                            emblemBadges:
                              values.type === EBadgeType.EMBLEM
                                ? [
                                    {
                                      emblemUrl: values.badgeImage,
                                      badgeName: 'preview'
                                    }
                                  ]
                                : [],
                            ribbonBadges:
                              values.type === EBadgeType.RIBBON
                                ? [
                                    {
                                      text: values.ribbon.text,
                                      backgroundColor:
                                        values.ribbon.backgroundColor,
                                      textColor: values.ribbon.textColor,
                                      badgeName: 'preview'
                                    }
                                  ]
                                : [],
                            bundleBorderColor:
                              getStoreTheme.data.storeScreen.bundleBorderColor,
                            productList: [],
                            bundleButtonColor:
                              getStoreTheme.data.general.buttonColor,
                            bundleButtonTextColor:
                              getStoreTheme.data.general.buttonTextColor,
                            fontFamily: getStoreTheme.data.general.font,
                            prices: {
                              priceDisplay: '$0.00',
                              priceInUsdCents: 1
                            },
                            gameName:
                              getStoreTheme.data.general
                                .bundlesInternalViewModel
                          }
                        )
                      )}
                  </div>
                </BundlesLayout>
              </div>
            </Grid>
          </Grid>
        )}
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default BadgesForm;
