import { GeneralThemeFormValues } from './generalTheme.types';

export const defaultGeneralTheme: GeneralThemeFormValues = {
  storeTabName: 'Gaming Store',
  logo: 'https://media.appcharge.com/defaults/logo.svg',
  favicon: 'https://media.appcharge.com/defaults/logo.svg',
  backgroundImageMobile: 'https://media.appcharge.com/defaults/background.png',
  backgroundImageDesktop: 'https://media.appcharge.com/defaults/background.png',
  storeSaleImage: '',
  font: 'montserrat',
  footer: {
    imageMobile: '',
    imageDesktop: '',
    appstoreLink: '',
    googlePlayLink: '',
    privacyPolicyUrl: '',
    termsAndConditionsUrl: '',
    supportLink: '',
    openLinksInNewTab: false
  }
};
