const settingsTexts = {
  THEME_UPDATED: 'Theme updated',
  THEME_UPDATE_ERROR: 'Error updating theme',
  TITLES: {
    PRODUCT_QUANTITY_FORMAT: 'Product quantity format',
    BALANCE_QUANTITY_FORMAT: 'Balance quantity format',
    SPECIAL_OFFER_QUANTITY_FORMAT: 'Special Offer quantity format'
  }
};

const actionTexts = {
  SAVE: 'Save',
  UPDATE: 'Update'
};

export const systemTexts = { settingsTexts, actionTexts };
