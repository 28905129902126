import { Route, Routes } from 'react-router-dom';

import RollingOfferForm from './Form/RollingOfferForm';
import RollingOffersTable from './RollingOffersTable';

const RollingOffersView = () => {
  return (
    <Routes>
      <Route path="/" element={<RollingOffersTable />} />
      <Route path="/form" element={<RollingOfferForm />} />
      <Route
        path="/form/:rollingOfferId"
        element={<RollingOfferForm edit={true} />}
      />
      <Route
        path="/dup/:rollingOfferId"
        element={<RollingOfferForm edit={false} dup={true} />}
      />
    </Routes>
  );
};

export default RollingOffersView;
