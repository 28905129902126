import { useMutation, useQuery } from '@tanstack/react-query';

import { GeneralThemeFormValues } from 'components/Theme/GeneralSettings/generalTheme.types';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useTheme(currentPublisherId?: string | null) {
  const axios = useAxios();

  const getStoreTheme = useQuery<any, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [
      EQueryKeys.STORE_THEME + currentPublisherId ? currentPublisherId : ''
    ],
    queryFn: async () => {
      const checkoutTheme = await axios.get(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      const theme = await axios.get(
        getApiRouteOrMock(EApiRoutes.THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      // ACDEV-10489
      return { ...theme, checkout: checkoutTheme };
    },
    enabled: !!currentPublisherId
  });

  const getGeneralTheme = useQuery<any, Error>({
    ...fetchConfig.general,
    retry: false,
    queryKey: [
      EQueryKeys.GENERAL_STORE_THEME + currentPublisherId
        ? currentPublisherId
        : ''
    ],
    queryFn: async () =>
      await axios.get(
        getApiRouteOrMock(EApiRoutes.GENERAL_THEME),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      ),
    enabled: !!currentPublisherId
  });

  const updateStoreTheme = useMutation(async (theme: any) => {
    const promises = [];

    if (theme.checkout || theme.checkoutScreen) {
      const checkoutUpdate = await axios.put(
        getApiRouteOrMock(EApiRoutes.CHECKOUT_THEME),
        theme.checkout,
        { ...(currentPublisherId && { 'x-publishers': currentPublisherId }) }
      );
      promises.push(checkoutUpdate);
    }

    const themeUpdate = await axios
      .put(
        getApiRouteOrMock(EApiRoutes.THEME),
        theme,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      )
      .catch((err) => {
        return err;
      });
    promises.push(themeUpdate);

    return await Promise.allSettled(promises);
  }, {});

  const updateGeneralTheme = useMutation(
    async (generalTheme: GeneralThemeFormValues) => {
      return await axios
        .put(
          getApiRouteOrMock(EApiRoutes.GENERAL_THEME),
          generalTheme,
          currentPublisherId
            ? { 'x-publishers': currentPublisherId }
            : undefined
        )
        .catch((err) => {
          return err;
        });
    },
    {}
  );

  return {
    getStoreTheme,
    updateStoreTheme,
    // getGeneralTheme,
    updateGeneralTheme
  };
}
