import { EFontWeight } from 'constants/enums';

import { CompletedThemeFormValues } from './types';

export const defaultCompletedTheme: CompletedThemeFormValues = {
  headerText: 'Success',
  headerColor: {
    colorOne: 'white',
    colorTwo: ''
  },
  headerSize: 44,
  headerWeight: EFontWeight.Bold,
  text: 'Open the game to see yuor new items',
  textColor: 'white',
  textSize: 24,
  textWeight: EFontWeight.Bold,
  backToGameButtonText: 'Back to game',
  deepLinks: {
    android: '',
    ios: '',
    web: ''
  }
};
