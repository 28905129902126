import { IS_NO_IP_ONLY_HTTPS } from 'constants/constants';
import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

export const generalSchema = yup.object().shape({
  storeTabName: yup.string().required(required('Store Tab Title')),
  logo: yup.string().required(required('Logo')),
  favicon: yup.string().required(required('Favicon')),
  backgroundImageMobile: yup.string().required(required('Background image')),
  backgroundImageDesktop: yup.string().required(required('Background image')),
  storeSaleImage: yup.string().notRequired(),
  font: yup.string().required(required('Font')),
  footer: yup.object().shape({
    imageMobile: yup.string().notRequired(),
    imageDesktop: yup.string().notRequired(),
    appstoreLink: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      )
      .notRequired(),
    googlePlayLink: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      )
      .notRequired(),
    privacyPolicyUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      )
      .required(required('Privacy policy URL')),
    termsAndConditionsUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      )
      .required(required('Terms and conditions URL')),
    supportLink: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      )
      .notRequired(),
    openLinksInNewTab: yup.boolean()
  })
});
