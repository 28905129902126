import { useEffect, useRef, useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip
} from '@mui/material';

import { GradientDirection } from './AcGraidentInput.types';

const PICK_TIMEOUT = 180;

// Change colors to hex
const standardizeColor = (color: string) => {
  let ctx: CanvasRenderingContext2D | null = document
    .createElement('canvas')
    .getContext('2d');
  if (ctx == null) {
    return '';
  } else {
    ctx.fillStyle = color;
    return ctx.fillStyle;
  }
};

const AcGradientInput = ({
  header = '',
  tooltip = '',
  textarea = false,
  onGradientOrSolidChange = () => {},
  onlySolid = false,
  defaultValue = {
    colorOne: '#FFFFFF',
    colorTwo: '#FFFFFF',
    gradientDirection: GradientDirection.LeftToRight
  },
  required = false,
  setValue,
  outputAsSingleColor = false,
  roundedBorders = false,
  imgWidth = '32px',
  imgHeight = '32px',
  ...props
}: TextFieldProps & {
  header?: string;
  tooltip?: string;
  textarea?: boolean;
  onGradientOrSolidChange?: (value: any) => any;
  onlySolid?: boolean;
  defaultValue?: {
    colorOne: string;
    colorTwo?: string;
    gradientDirection?: GradientDirection;
  };
  setValue: any;
  outputAsSingleColor?: boolean;
  roundedBorders?: boolean;
  imgWidth?: string;
  imgHeight?: string;
}) => {
  const textInputRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSolid, setIsSolid] = useState(!defaultValue?.colorTwo);
  const [colorOne, setColorOne] = useState(
    standardizeColor(defaultValue?.colorOne) ||
      (defaultValue as unknown as string)
  );
  const [colorTwo, setColorTwo] = useState(defaultValue?.colorTwo || '');
  const [gradientDirection, setGradientDirection] = useState(
    defaultValue?.gradientDirection
      ? defaultValue.gradientDirection
      : GradientDirection.LeftToRight
  );

  useEffect(() => {
    defaultValue?.colorOne && setColorOne(defaultValue?.colorOne);
    setIsSolid(!defaultValue.colorTwo);
    defaultValue?.colorTwo &&
      defaultValue?.colorTwo?.length >= 0 &&
      setColorTwo(defaultValue.colorTwo);
    defaultValue?.gradientDirection &&
      setGradientDirection(defaultValue.gradientDirection);
  }, [defaultValue]);

  const changeHandler = (
    colorOne: string,
    colorTwo: string,
    gradientDirection: GradientDirection
  ) => {
    if (isSolid) {
      setTimeout(() => {
        if (onlySolid) setValue(props.name, colorOne);
        else setValue(`${props.name}.colorOne`, colorOne);
      }, PICK_TIMEOUT);
    } else {
      setTimeout(() => {
        setValue(`${props.name}.colorOne`, colorOne);
        setValue(`${props.name}.colorTwo`, colorTwo || colorOne);
        setValue(`${props.name}.gradientDirection`, gradientDirection);
      }, PICK_TIMEOUT);
    }
  };

  const onSolidStateChange = (color: string, isSolid: boolean) => {
    setValue(`${props.name}.colorOne`, color);
    setValue(`${props.name}.colorTwo`, isSolid ? '' : color);
  };

  const whiteColors: string[] = ['#ffffff', 'white'];
  const containsWhite: boolean =
    whiteColors.includes(colorOne.toLowerCase()) ||
    whiteColors.includes(colorTwo.toLowerCase());

  return (
    <div>
      <Stack
        className="gradientInput-container formContent"
        direction="column"
        style={{ position: 'relative', marginBottom: 1.5 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={'5px'}
          marginBottom={'4px'}
          className="formContent-input-fieldTitle"
        >
          {header && (
            <Tooltip arrow title={tooltip} placement="top">
              <h3>
                {required && (
                  <span
                    style={{
                      color: 'red',
                      marginRight: '6px'
                    }}
                  >
                    *
                  </span>
                )}
                {header}
              </h3>
            </Tooltip>
          )}
          {tooltip && (
            <Tooltip arrow title={tooltip}>
              <HelpOutlineIcon sx={{ color: 'black' }} />
            </Tooltip>
          )}
        </Stack>
        <TextField
          {...props}
          variant={props.variant ?? 'outlined'}
          sx={{ width: '100%', cursor: 'pointer' }}
          value={isSolid ? colorOne.toUpperCase() : 'Linear'}
          multiline={!!textarea}
          rows={textarea ? 2 : undefined}
          onClick={() => setIsPopoverOpen(true)}
          ref={textInputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div
                  style={{
                    background:
                      isSolid || onlySolid
                        ? colorOne
                        : `linear-gradient(${gradientDirection}, ${colorOne}, ${colorTwo})`,
                    border: containsWhite ? '1px solid grey' : '',
                    borderRadius: roundedBorders ? '4px' : 0,
                    cursor: 'pointer',
                    width: imgWidth,
                    height: imgHeight
                  }}
                ></div>
              </InputAdornment>
            )
          }}
          className="formContent-input-inputField formContent-input-gradientInput"
        />
        <Popover
          id={'gradient-popover'}
          open={isPopoverOpen}
          anchorEl={textInputRef.current}
          onClose={() => setIsPopoverOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack
            direction="row"
            style={{ minWidth: '360px', padding: '1em' }}
            gap={1}
          >
            <Select
              style={{ minWidth: '100px', maxWidth: '100px' }}
              value={isSolid || onlySolid ? 'solid' : 'linear'}
              disabled={onlySolid}
              onChange={(e) => {
                const solid = e.target.value === 'solid';
                setColorTwo(solid ? '' : colorOne);
                setIsSolid(solid);
                onSolidStateChange(colorOne, solid);
              }}
              size="small"
            >
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="linear">Linear</MenuItem>
            </Select>
            <TextField
              type="color"
              style={{
                minWidth: isSolid || onlySolid ? '215px' : '50px',
                width: isSolid || onlySolid ? '215px' : '50px',
                minHeight: '37px'
              }}
              value={colorOne}
              onChange={(e) => {
                setColorOne(e.target.value);
                changeHandler(e.target.value, colorTwo, gradientDirection);
              }}
              size="small"
              sx={{
                '.MuiInputBase-root': {
                  height: '100%'
                }
              }}
            />
            {!isSolid && !onlySolid && (
              <TextField
                type="color"
                style={{
                  minWidth: '50px',
                  width: '50px',
                  minHeight: '37px'
                }}
                value={colorTwo}
                onChange={(e) => {
                  setColorTwo(e.target.value);
                  changeHandler(colorOne, e.target.value, gradientDirection);
                }}
                size="small"
                sx={{
                  '.MuiInputBase-root': {
                    height: '100%'
                  }
                }}
              />
            )}
            {!isSolid && !onlySolid && (
              <Select
                value={gradientDirection}
                onChange={(e) => {
                  setGradientDirection(e.target.value as GradientDirection);
                  changeHandler(
                    colorOne,
                    colorTwo,
                    e.target.value as GradientDirection
                  );
                }}
                size="small"
                style={{ minWidth: '100px', maxWidth: '100px' }}
              >
                {Object.keys(GradientDirection).map((key) => (
                  <MenuItem
                    key={key}
                    value={
                      GradientDirection[key as keyof typeof GradientDirection]
                    }
                  >
                    {GradientDirection[key as keyof typeof GradientDirection]}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
        </Popover>
      </Stack>
    </div>
  );
};

export default AcGradientInput;
