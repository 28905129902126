import { IntegrationSettingsData } from '../Settings/Settings.types';

export interface ThemeTabProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  sectionViewModel?: string;
  setFieldValue?: any;
  setTouched?: any;
  validateField?: any;
  integration?: IntegrationSettingsData;
}

export enum EStoreState {
  GENERAL = 'general',
  STORE = 'store',
  LOGIN = 'login',
  COMPLETED = 'completed',
  CHECKOUT = 'checkout'
}
