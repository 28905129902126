import { EPermissionGroup, IPermissions } from 'common/permissions.types';
import { EFeatureFlag, StoreBodyBlock } from 'constants/enums';
import { permissionsUtil } from 'utils/permissionsUtil';

import { ESettingsState, IntegrationProfile } from './Settings.types';

const getActiveSettingsTab = (
    permissions: IPermissions | undefined,
    id: string | undefined,
    isCheckoutProject: boolean | undefined
): ESettingsState | undefined => {
  if (!permissionsUtil.shouldHideGroupInPermission(
    permissions,
    EPermissionGroup.AUTHENTICATION,
    id
   ) &&
    !isCheckoutProject
  ) {
    return ESettingsState.PLAYER_AUTH;
  }

  if (!permissionsUtil.shouldHideGroupInPermission(
     permissions,
     EPermissionGroup.USERS,
      id)
  ) {
    return ESettingsState.USERS;
  }

  if (!permissionsUtil.shouldHideGroupInPermission(
    permissions,
    EPermissionGroup.INTEGRATION,
    id)
  ) {
    return ESettingsState.INTEGRATION;
  }

  if (!permissionsUtil.shouldHideGroupInPermission(
    permissions,
    EPermissionGroup.COMPANY,
    id)
  ) {
    return ESettingsState.COMPANY;
  }
};

export const settingsTabUtils = {
  getActiveSettingsTab
};

export const convertProfilesObjectToArray = (
  obj: Record<string, IntegrationProfile>
) => {
  return Object.keys(obj).map((profileName: string) => {
    return {
      name: profileName,
      ordersReportingApiUrl: obj[profileName].ordersReportingApiUrl,
      playerInfoSyncUrl: obj[profileName].playerInfoSyncUrl,
      playersAuthWebhook: obj[profileName].playersAuthWebhook,
      eventsWebhookUrl: obj[profileName].eventsWebhookUrl,
      fbAppId: obj[profileName].fbAppId
    };
  });
};

export const convertProfilesArrayToObject = (arr: any) => {
  return arr.reduce((acc: Record<string, any>, profile: any) => {
    acc[profile.name] = {
      ordersReportingApiUrl: profile.ordersReportingApiUrl,
      playerInfoSyncUrl: profile.playerInfoSyncUrl,
      playersAuthWebhook: profile.playersAuthWebhook,
      eventsWebhookUrl: profile.eventsWebhookUrl
    };
    return acc;
  }, {});
};

const mapBodyBlockToDisplayName: Record<StoreBodyBlock, string> = {
  [StoreBodyBlock.dailyBonus]: 'Daily Bonus Badge',
  [StoreBodyBlock.playerLevel]: 'Player Level',
  [StoreBodyBlock.banners]: 'Banner',
  [StoreBodyBlock.specialOffers]: 'Special Offers',
  [StoreBodyBlock.rollingOffers]: 'Rolling Offers',
  [StoreBodyBlock.bundles]: 'Bundles'
};

export const filterBodyBlocksByFeatureFlags =
  (featureFlags: Record<string, boolean>) => (item: StoreBodyBlock) =>
    (item !== StoreBodyBlock.playerLevel ||
      featureFlags[EFeatureFlag.DASHBOARD_BODYBLOCK_SUPPORT_PLAYER_LEVEL]) &&
    (item !== StoreBodyBlock.rollingOffers ||
      featureFlags[EFeatureFlag.DASHBOARD_ROLLING_OFFER]) &&
    (item !== StoreBodyBlock.banners ||
      featureFlags[EFeatureFlag.DASHBOARD_BANNERS]);

export const mapBodyBlocksToListItems =
  (currentFormValue: StoreBodyBlock[]) => (block: StoreBodyBlock) => ({
    value: block,
    name: mapBodyBlockToDisplayName[block],
    enabled: currentFormValue.includes(block)
  });
