import { Grid } from '@mui/material';
import { OfferUiProps } from 'common/contracts';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

const OfferUiSettings: React.FC<OfferUiProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isNameUnique,
  isExternalIdUnique,
  offerDesignTypesItems
}) => {
  return (
    <AcCard title="Offer Settings" padded={false} className="offersUIFormCard">
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <AcInput
            required
            header="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(touched.name && Boolean(errors.name)) || !isNameUnique}
            helperText={
              (touched.name ? errors.name : '') ||
              (!isNameUnique ? 'Name already exists' : '')
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcSelect
            header="Type"
            name="offerUiType"
            value={values.offerUiType}
            onChange={handleChange}
            onBlur={handleBlur}
            items={offerDesignTypesItems}
          />
        </Grid>
      </Grid>
      <Grid container mt={2} columnSpacing={1}>
        <Grid item xs={6}>
          <AcInput
            required
            header="External ID"
            name="externalId"
            value={values.externalId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              (touched.externalId && Boolean(errors.externalId)) ||
              !isExternalIdUnique
            }
            helperText={
              (touched.externalId ? errors.externalId : '') ||
              (!isExternalIdUnique ? 'External ID already exists' : '')
            }
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};

export default OfferUiSettings;
