import { useEffect, useState } from 'react';

import { Alert, Box, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import dayjs from 'dayjs';
import { getDurationTextFromMinutes } from 'utils/formattingUtil';
import { validationUtils } from 'utils/scheduleUtils';

import AcInput from 'components/AcInput/AcInput';

import ActionButton from '../ActionButton/ActionButton';

export default function AcDateTimePicker({
  onApply,
  minDate,
  disableFuture = true,
  editRangeDatesValues,
  applyText,
  notes,
  setNotes,
  formikProps,
  dateRange,
  setDateRange,
  resetScheduleValues
}: any) {
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formattedDuration, setFormattedDuration] = useState<string | null>(null);

  useEffect(() => {
    if (!editRangeDatesValues) {
      setDateRange([null, null]);
      return;
    }
    const [startDate, endDate, notes] = editRangeDatesValues;
    setDateRange([dayjs.utc(startDate), dayjs.utc(endDate)]); // Ensure UTC on edit
    setNotes(notes);
  }, [editRangeDatesValues]);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    setErrorMessage(null);

    if (!startDate || !endDate) {
      setIsApplyDisabled(true);
      setFormattedDuration(null);
      return;
    }

    setIsApplyDisabled(false);

    const diffMinutes = endDate.diff(startDate, 'minute');
    const duration = getDurationTextFromMinutes(diffMinutes);
    setFormattedDuration(duration);

    const errorMessage = validationUtils.getErrorMessage(
      startDate,
      endDate,
      diffMinutes,
      formikProps.values,
      editRangeDatesValues
    );

    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
  }, [dateRange, editRangeDatesValues, formikProps.values]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={2} sx={{ maxWidth: '500px' }}>
        <DateTimeRangePicker
          value={dateRange}
          timezone="UTC"
          onChange={(newRange) => {
            setDateRange(newRange);
          }}
          disableFuture={disableFuture}
          minDate={minDate || undefined}
        />
        {formattedDuration && (
          <AcInput
            value={formattedDuration}
            header={'Duration'}
            disabled
            sx={{ width: `${formattedDuration.length}ch`, minWidth: 'fit-content' }}
          />
        )}
        {errorMessage && (
          <Box mt={2} mb={1}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
          <AcInput
            header="Notes"
            name="notes"
            placeholder="Type your notes here"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{ minWidth: '230px' }}
          />
        <Stack direction="row" justifyContent="flex-start" gap={1} pt={1}>
          {editRangeDatesValues && (
            <ActionButton
              text={'Cancel'}
              disabled={isApplyDisabled || !!errorMessage}
              onClick={resetScheduleValues}
            />
          )}
          <ActionButton
            text={applyText}
            disabled={
              isApplyDisabled ||
              !!errorMessage ||
              (formikProps?.touched.playerAvailability && Boolean(formikProps?.errors.playerAvailability))
            }
            onClick={() => {
              onApply(dateRange, notes);
              resetScheduleValues();
            }}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}
