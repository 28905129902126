import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import useIntegration from 'api/useIntegration';
import { ENotificationType } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';

import AcCard from 'components/AcCard/AcCard';
import DataTable from 'components/DataTable/DataTable';
import FirstActionModal from 'components/FirstActionModal/FirstActionModal';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import { PublisherEventType } from '../Settings.types';

interface EventCategorySectionProps {
  category: string;
  events: PublisherEventType[];
  enabledEvents: string[];
  isTableDataEmpty: boolean;
}

const EventCategorySection: React.FC<EventCategorySectionProps> = ({
  category,
  events,
  enabledEvents,
  isTableDataEmpty
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { enqueueSnackbar } = useNotifications();

  const { updateIntegration, getIntegration }: any =
    useIntegration(currentPublisherId);

  const getToggleHandler = (isEnabled: boolean, eventKey: string) => {
    return () => {
      const updatedEvents = isEnabled
        ? enabledEvents.filter((e: string) => e !== eventKey)
        : [...enabledEvents, eventKey];

      updateIntegration.mutate(
        { enabledWebhookEvents: updatedEvents },
        {
          onSuccess: () => {
            enqueueSnackbar(
              'Webhook event updated successfully',
              ENotificationType.SUCCESS
            );
            getIntegration.refetch();
          },
          onError: () => {
            enqueueSnackbar(
              'Something went wrong with updating the event',
              ENotificationType.ERROR
            );
          }
        }
      );
    };
  };

  const columns = [
    {
      field: '_id',
      headerName: '',
      disableReorder: true,
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Event Name',
      disableReorder: true,
      flex: 1,
      sortable: false
    },
    {
      field: 'key',
      headerName: 'Key',
      disableReorder: true,
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Value',
      disableReorder: true,
      flex: 1,
      sortable: false
    },
    {
      field: 'subCategory',
      headerName: 'Category',
      disableReorder: true,
      flex: 1,
      sortable: false
    },
    {
      field: 'isActive',
      headerName: 'Is Active',
      disableReorder: true,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        const isEnabled = enabledEvents.includes(params.row.key);
        const handleToggle = getToggleHandler(isEnabled, params.row.key);
        return (
          <CustomizedSwitch
            status={isEnabled}
            texts={[]}
            functions={[handleToggle, handleToggle]}
          />
        );
      }
    }
  ];

  const rows = events.map((event: PublisherEventType, i: number) => ({
    _id: i,
    name: event.displayName,
    value: event.value,
    key: event.value,
    subCategory: event?.subCategory
  }));

  return (
    <Grid container columnSpacing={4}>
      <Grid item mt={5} xs={15}>
        <AcCard stackContainer={false} title={category}>
          <DataTable
            columns={columns}
            rows={rows}
            defaultHiddenFields={['_id', 'key']}
            loading={false}
            onNoData={
              isTableDataEmpty ? (
                <FirstActionModal
                  headline="No events found"
                  text="Please check your spelling or try different keywords."
                />
              ) : (
                <FirstActionModal
                  headline="No exact matches"
                  text="Try changing or removing your filter or adjusting your search area"
                />
              )
            }
            className="eventsTable"
          />
        </AcCard>
      </Grid>
    </Grid>
  );
};

export default EventCategorySection;
