import { Grid } from '@mui/material';
import { FormikProps } from 'common/contracts';
import { fontWeightOptions } from 'utils/selectOptionsUtils';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { OfferDesignTypes } from '../types';

const OfferUiTitle: React.FC<FormikProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}) => {
  return (
    <>
      {values.offerUiType !== OfferDesignTypes.Bundle && (
        <AcCard
          title="Title"
          stackContainer={false}
          padded={false}
          className="offersUIFormTextCard"
        >
          <Grid container columnSpacing={{ xs: 1 }}>
            <Grid item xs={12} className="offersUIFormTextCard-offerContent">
              <AcInput
                header="Text"
                name="specialOffer.title"
                value={values?.specialOffer?.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                paddingTop: '12px'
              }}
            >
              <AcGradientInput
                header="Color"
                name="specialOffer.fontColor"
                defaultValue={values.specialOffer?.fontColor}
                onChange={handleChange}
                setValue={setFieldValue}
                onBlur={handleBlur}
                error={
                  touched.specialOffer?.fontColor &&
                  Boolean(errors.specialOffer?.fontColor)
                }
                helperText={
                  touched.specialOffer?.fontColor
                    ? errors.specialOffer?.fontColor?.toString()
                    : ''
                }
                imgWidth={'32px'}
                imgHeight={'32px'}
                roundedBorders={false}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                paddingTop: '12px'
              }}
            >
              <AcInput
                header="Size"
                name="specialOffer.fontSize"
                value={values.specialOffer?.fontSize}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                paddingTop: '12px'
              }}
            >
              <AcSelect
                header="Weight"
                name="specialOffer.fontWeight"
                defaultValue="normal"
                value={values.specialOffer?.fontWeight}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.specialOffer?.fontWeight &&
                  Boolean(errors.specialOffer?.fontWeight)
                }
                items={fontWeightOptions}
              />
            </Grid>
          </Grid>
        </AcCard>
      )}
    </>
  );
};

export default OfferUiTitle;
