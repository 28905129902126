import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import useSections from 'api/useSections';
import useTheme from 'api/useTheme';
import { AxiosError } from 'axios';
import { EPublisherSolutionModel } from 'common/contracts';
import {
  EPermissionAction,
  EPermissionGroup,
  IPermissions
} from 'common/permissions.types';
import {
  EButtonColor,
  ELocalStorageKeys,
  ENotificationType
} from 'constants/enums';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { Form, Formik, FormikConfig } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { uiActions } from 'store/uiSlice';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import DialogModal from 'components/Dialog/Dialog';
import PageTopBar from 'components/Topbar/PageTopBar';

import { CheckoutThemeFormValues } from './CheckoutTheme/types';
import { useGetCheckoutInitialValues } from './CheckoutTheme/useGetCheckoutInitialValues';
import { CompletedThemeFormValues } from './CompletedTheme/types';
import { useGetCompletedInitialValues } from './CompletedTheme/useGetCompletedInitialValues';
import { completedValidationSchema } from './CompletedTheme/validation-schema';
import { GeneralThemeFormValues } from './GeneralSettings/generalTheme.types';
import { useGetGeneralInitialValues } from './GeneralSettings/useGetGeneralInitialValues';
import { generalSchema } from './GeneralSettings/validation-schema';
import { LoginThemeFormValues } from './LoginTheme/types';
import { useGetLoginInitialValues } from './LoginTheme/useGetLoginInitialValues';
import { loginValidationSchema } from './LoginTheme/validation-schema';
import {
  SectionsItem,
  StoreThemeFormValues
} from './StoreTheme/storeTheme.types';
import { useGetStoreInitialValues } from './StoreTheme/useGetStoreInitialValues';
import { useGetStoreValidationSchema } from './StoreTheme/validation-schema';
import { EStoreState } from './theme.types';

import './style.scss';

export const ThemeView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialPath = useMemo(
    () => location.pathname.split('/').filter(Boolean).pop(),
    []
  );

  const initialTab = useMemo(
    () =>
      Object.values(EStoreState).includes(initialPath as EStoreState)
        ? (initialPath as EStoreState)
        : EStoreState.GENERAL,
    []
  );

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const isCheckoutUser =
    localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
    EPublisherSolutionModel.CHECKOUT;

  const [tab, setTab] = useState<EStoreState>(
    isCheckoutUser ? EStoreState.CHECKOUT : initialTab
  );

  const {
    getStoreTheme: storeTheme,
    updateStoreTheme,
    updateGeneralTheme,
    updateStoreScreen,
    updateLoginTheme,
    getCheckoutTheme: checkoutTheme,
    updateCheckoutTheme,
    updateCompletedTheme
  } = useTheme(currentPublisherId || undefined);

  const { updateIntegration } = useIntegration(currentPublisherId);
  const { getSections, upsertSections } = useSections(currentPublisherId);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useNotifications();
  const [isCheckoutDialogOpen, setIsCheckoutDialogOpen] = useState(false);

  const { storeValidationSchema: storeSchema } = useGetStoreValidationSchema();

  const { initialValues: generalInitialValues } = useGetGeneralInitialValues();
  const { initialValues: storeInitialValues } = useGetStoreInitialValues();
  const { initialValues: loginInitialValues } = useGetLoginInitialValues();
  const { initialValues: checkoutInitialValues } =
    useGetCheckoutInitialValues();
  const { initialValues: completedInitialValues } =
    useGetCompletedInitialValues();

  const tabConfig: Record<EStoreState, FormikConfig<any>> = useMemo(
    () => ({
      [EStoreState.GENERAL]: {
        initialValues: generalInitialValues,
        validationSchema: generalSchema,
        onSubmit: async (
          values: GeneralThemeFormValues,
          { resetForm, setSubmitting }
        ) => {
          try {
            await updateGeneralTheme.mutateAsync(values);
            dispatch(uiActions.setUnsavedChanges(false));
            setSubmitting(false);

            enqueueSnackbar('General theme updated', ENotificationType.SUCCESS);

            // ACDEV-10482: use general get
            // await generalTheme.refetch();

            await storeTheme.refetch();

            resetForm({ values });
          } catch (error) {
            enqueueSnackbar(
              'Error updating general theme',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              )?.message || 'Unknown error occurred'
            );
          }
        }
      },
      [EStoreState.STORE]: {
        initialValues: storeInitialValues,
        validationSchema: storeSchema,
        onSubmit: async (
          values: StoreThemeFormValues,
          { resetForm, setSubmitting, setFieldValue }
        ) => {
          try {
            const sectionsValues = values.sections.map(
              (value: SectionsItem) => {
                const { _id, ...rest } = value;
                return rest;
              }
            ) as SectionsItem[];

            const filteredSectionsData = sectionsValues.filter(
              (item) => !item.toBeDeleted
            );

            const newSectionsData = filteredSectionsData.map((section) => {
              const sectionData: Partial<SectionsItem> = {
                name: section.name,
                publisherSectionId: section.publisherSectionId
              };
              if (section.image) {
                sectionData.image = section.image;
              }
              if (section.sectionId) {
                sectionData.sectionId = section.sectionId;
              }
              return sectionData;
            });

            // ACDEV-10485: use get screen and move props from general to store
            const generalThemeValues = {
              buttonColor: values.buttonColor,
              buttonTextColor: values.buttonTextColor
            };

            const storeScreenValues = {
              ...(values.defaultBanner !== ''
                ? { defaultBanner: values.defaultBanner }
                : {}),
              addToHomeScreen: values.addToHomeScreen,
              noOffersTitleText: values.noOffersTitleText,
              noOffersMessageText: values.noOffersMessageText,
              bundleBorderColor: values.bundleBorderColor
            };

            await upsertSections.mutateAsync(newSectionsData);
            await updateGeneralTheme.mutateAsync(generalThemeValues);
            await updateStoreScreen.mutateAsync(storeScreenValues);

            dispatch(uiActions.setUnsavedChanges(false));
            await getSections.refetch();
            setSubmitting(false);

            enqueueSnackbar('Store page updated', ENotificationType.SUCCESS);

            // ACDEV-10485: use get screen and move props from general to store
            await storeTheme.refetch();
            // await screenTheme.refetch();

            resetForm({ values });
          } catch (error) {
            enqueueSnackbar(
              'Error updating store theme',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              ).message || 'Unknown error occurred'
            );
            if (getSections.data?.result.length > 0) {
              await setFieldValue('sections', getSections.data?.result);
            }
          }
        }
      },
      [EStoreState.LOGIN]: {
        initialValues: loginInitialValues,
        validationSchema: loginValidationSchema,
        onSubmit: async (
          values: LoginThemeFormValues,
          { resetForm, setSubmitting }
        ) => {
          try {
            // ACDEV-10488: use get login and move props from general to login
            const generalThemeValues = {
              buttonColor: values.accountApprovalModal
            };

            await updateStoreTheme.mutateAsync(generalThemeValues);
            await updateLoginTheme.mutateAsync(values);
            dispatch(uiActions.setUnsavedChanges(false));
            setSubmitting(false);

            enqueueSnackbar('Login theme updated', ENotificationType.SUCCESS);

            // ACDEV-10485: use get login and move props from general to store
            await storeTheme.refetch();
            // await loginTheme.refetch();

            resetForm({ values });
          } catch (error) {
            enqueueSnackbar(
              'Error updating login theme',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              ).message || 'Unknown error occurred'
            );
          }
        }
      },
      [EStoreState.CHECKOUT]: {
        initialValues: checkoutInitialValues,
        onSubmit: async (
          values: CheckoutThemeFormValues,
          { setSubmitting }
        ) => {
          try {
            values.publisherId =
              currentPublisherId ||
              localStorageUtil.get(ELocalStorageKeys.PUBLISHER_ID) ||
              '';
            if (values?.mobileBgImage && values?.mobileBgImage === 'none') {
              values.mobileBgImage = '';
            }

            await updateCheckoutTheme.mutateAsync(values);

            dispatch(uiActions.setUnsavedChanges(false));
            setSubmitting(false);

            enqueueSnackbar(
              'Checkout theme updated',
              ENotificationType.SUCCESS
            );
            setIsCheckoutDialogOpen(true);

            await checkoutTheme.refetch();
            await storeTheme.refetch();
          } catch (error) {
            enqueueSnackbar(
              'Error updating checkout theme',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              ).message || 'Unknown error occurred'
            );
          }
        }
      },
      [EStoreState.COMPLETED]: {
        initialValues: completedInitialValues,
        validationSchema: completedValidationSchema,
        onSubmit: async (
          values: CompletedThemeFormValues,
          { resetForm, setSubmitting }
        ) => {
          try {
            const integrationValues = {
              deepLinks: [
                {
                  platform: 'ios',
                  deepLink: values.deepLinks.ios
                },
                {
                  platform: 'android',
                  deepLink: values.deepLinks.android
                },
                {
                  platform: 'web',
                  deepLink: values.deepLinks.web
                }
              ]
            };

            await updateCompletedTheme.mutateAsync(values);
            await updateIntegration.mutateAsync(integrationValues);

            dispatch(uiActions.setUnsavedChanges(false));
            setSubmitting(false);

            enqueueSnackbar(
              'Completed page updated',
              ENotificationType.SUCCESS
            );

            // ACDEV-10485: use get completed
            await storeTheme.refetch();
            // await completedTheme.refetch();

            resetForm({ values });
          } catch (error) {
            enqueueSnackbar(
              'Error updating completed page',
              ENotificationType.ERROR,
              (
                (error as AxiosError).response?.data as {
                  message: string;
                }
              ).message || 'Unknown error occurred'
            );
          }
        }
      },
      fetcher: storeTheme
    }),
    [
      generalInitialValues,
      generalSchema,
      storeInitialValues,
      storeSchema,
      loginInitialValues,
      checkoutInitialValues,
      completedInitialValues
    ]
  );

  const { initialValues, validationSchema, onSubmit }: FormikConfig<any> =
    useMemo(() => tabConfig[tab], [tab, tabConfig, location]);

  useEffect(() => {
    const segments = location.pathname.split('/');
    segments[segments.length - 1] = tab;
    const newPath = segments.join('/');
    navigate(newPath);
  }, [tab]);

  const shouldAdminTabsBeShown: boolean = useMemo(
    () =>
      Boolean(
        permissionsUtil.isSuperAdminByProject(currentPublisherId || '') ||
          permissionsUtil.isAdminByProject(currentPublisherId || '') ||
          permissionsUtil.canAccessBuilder(
            localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) ||
              {},
            currentPublisherId || ''
          )
      ),
    [currentPublisherId]
  );

  const tabs = useMemo(() => {
    return [
      { label: 'General Settings', value: EStoreState.GENERAL },
      { label: 'Store Page', value: EStoreState.STORE },
      { label: 'Login Page', value: EStoreState.LOGIN },
      {
        label: 'Checkout Page',
        value: EStoreState.CHECKOUT,
        shouldHide: !shouldAdminTabsBeShown
      },
      { label: 'Completed Page', value: EStoreState.COMPLETED }
    ];
  }, [shouldAdminTabsBeShown]);

  const handleTabChange = (event: SyntheticEvent, newValue: EStoreState) => {
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/theme/${newValue}`;
    navigate(newPath);
  };

  // console.log('completedInitialValues', completedInitialValues);
  return (
    <Formik
      key={tab}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid, dirty, isSubmitting, submitForm, values }) => {
        return (
          <Form className="storeTheme-form">
            <TabsPanel
              tabs={tabs}
              headerComponent={
                <PageTopBar
                  withTabsDesign={true}
                  disable={false}
                  headline="Store"
                  buttons={[
                    {
                      text: 'Save',
                      action: submitForm,
                      disabled:
                        !permissionsUtil.canUserEdit() ||
                        !isValid ||
                        !dirty ||
                        isSubmitting,
                      hidden: !permissionsUtil.isActionEnabled(
                        null,
                        EPermissionGroup.BUILDER,
                        currentPublisherId,
                        EPermissionAction.MANAGE
                      )
                    }
                  ]}
                />
              }
              handleTabChange={handleTabChange}
              activeTabVal={tab}
            />
            <DialogModal
              headline="Please note"
              isOpen={isCheckoutDialogOpen}
              text="Your changes have been saved. Please allow a few minutes for them to take effect."
              buttons={[
                {
                  text: 'Got it',
                  color: EButtonColor.PRIMARY,
                  variant: 'contained',
                  func: () => setIsCheckoutDialogOpen(false)
                }
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
