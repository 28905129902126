import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';

import useImages from '../../api/useImages';
import { ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { UploadTooltipsDescription } from '../../hooks/useUpload';
import { AuthSliceState } from '../../store/store.types';
import { permissionsUtil } from '../../utils/permissionsUtil';

import {
  AcSelectImageItem,
  AcSelectItem,
  AcSelectProps,
  EAcSelectItemRenderType,
  EAcSelectOptions
} from './AcSelect.types';
import useAcSelect from './useAcSelect';

import './style.scss';

const AcSelect = ({
  items = [],
  className = '',
  isRepeatSelect,
  renderValue,
  header = '',
  helperText = '',
  tooltip = '',
  uploadConfig = undefined,
  required = false,
  headerSize = '14',
  isCheckBox = false,
  isLimited,
  setSelectedItems = () => {},
  loading = false,
  defaultCheckedItems,
  isSelectAllEnabled = false,
  isApplyBtnEnabled = true,
  multipleSelect = false,
  isSearchEnabled = false,
  renderType,
  onClear,
  ...props
}: AcSelectProps) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { imageRenderFunction } = useAcSelect();
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useNotifications();
  const [isUploading, setIsUploading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>(
    defaultCheckedItems ?? []
  );
  const [isOpen, setIsOpen] = useState(false);
  const { addImage } = useImages(currentPublisherId);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    defaultCheckedItems && multipleSelect
      ? setCheckedItems(defaultCheckedItems)
      : setCheckedItems(props.value);
  }, [props.value, defaultCheckedItems]);

  const uploadImage = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const file = addPictureInputRef?.current?.files?.[0];
    const formData = new FormData();
    if (!file) return;
    if (file && file.size > 2000000) {
      enqueueSnackbar(`File size limit is 2MB`, ENotificationType.ERROR);
      return;
    }
    if (uploadConfig?.validator && !(await uploadConfig.validator(file))) {
      return;
    }
    setIsUploading(true);
    formData.append('image', file as File);
    formData.append('type', uploadConfig!.uploadType as string);
    formData.append('name', file.name);
    addImage.mutate(formData, {
      onSuccess: async (data) => {
        enqueueSnackbar(
          `File has been uploaded successfully`,
          ENotificationType.SUCCESS
        );
        uploadConfig!.onUploadSuccess?.(data);
      },
      onError(error) {
        console.log({ error });
        enqueueSnackbar(
          `Asset has failed to upload`,
          ENotificationType.ERROR,
          (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message
        );
      },
      onSettled() {
        setIsUploading(false);
      }
    });
  };

  const handleCheckboxClick = (checkedItem: any, key?: string | number) => {
    const itemValue =
      items.find((item) => item.key === checkedItem)?.value || checkedItem;

    if (isSelectAllEnabled && key === EAcSelectOptions.ALL) {
      let newSelectedValues: any = items.map((item) => item.value);
      if (checkedItems.length === newSelectedValues.length) {
        newSelectedValues = [];
      }
      setCheckedItems(newSelectedValues);
      if (!isApplyBtnEnabled) {
        setSelectedItems(newSelectedValues);
      }
      return;
    }

    let checkedItemsArray = Array.isArray(checkedItems)
      ? checkedItems
      : [checkedItems];

    let newCheckedItems;
    const itemToCheck = multipleSelect ? itemValue : checkedItem;

    newCheckedItems = checkedItemsArray?.includes(itemToCheck)
      ? checkedItemsArray.filter((item) => item !== itemToCheck)
      : isLimited
        ? [itemToCheck]
        : [...checkedItemsArray, itemToCheck];

    // Remove undefined values from newCheckedItems
    newCheckedItems = newCheckedItems.filter((item) => item !== undefined);
    setCheckedItems(newCheckedItems);
    setSelectedItems(newCheckedItems);

    if (!isApplyBtnEnabled) {
      setSelectedItems(newCheckedItems);
    }
  };

  const handleIsItemChecked = (filterBy: any) => {
    if (isSelectAllEnabled && filterBy === EAcSelectOptions.ALL) {
      return checkedItems.length === items.length;
    }
    return checkedItems?.includes(filterBy);
  };

  const getItemContentByType = (item: AcSelectItem | AcSelectImageItem) => {
    const { renderFunction, ...itemProps } = item ?? {};

    if (renderType === EAcSelectItemRenderType.IMAGE) {
      return imageRenderFunction(item, props.imgHeight);
    }

    return renderFunction ? renderFunction(itemProps) : itemProps.content;
  };

  const filteredItems = useMemo(() => {
    return items.filter((item) =>
      item.value !== undefined
        ? item.value
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : false
    );
  }, [items, searchQuery]);

  const renderSelectItems = useCallback(() => {
    if (!Array.isArray(items)) {
      return [];
    }

    const selectItems = isSearchEnabled ? filteredItems : items;

    return selectItems?.map((item, index) => {
      const { renderFunction, ...itemProps } = item ?? {};
      const filterBy = itemProps.filterBy || itemProps.value;

      return (
        <MenuItem
          {...itemProps}
          key={itemProps.key || index}
          sx={{
            padding: isCheckBox ? '8px 12px' : '6px 16px',
            fontSize: isCheckBox ? '14px' : '16px'
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxClick(filterBy, itemProps?.key);
          }}
        >
          {isCheckBox && (
            <Checkbox
              checked={
                checkedItems.length === items.length && multipleSelect
                  ? handleIsItemChecked(EAcSelectOptions.ALL)
                  : handleIsItemChecked(filterBy)
              }
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxClick(filterBy, itemProps?.key);
              }}
              size="small"
              sx={{
                padding: 0,
                marginRight: '6px'
              }}
            />
          )}
          <Box component="span" sx={{ flexGrow: 1 }}>
            {getItemContentByType(item)}
          </Box>
        </MenuItem>
      );
    });
  }, [items, checkedItems, searchQuery]);

  const handleRenderValue = (selected: string | string[]) => {
    if (renderValue) return renderValue(selected);
    if (Array.isArray(selected)) {
      return selected.join(', ');
    }
    const currentItem = items.find((item) => item.value === selected);
    return currentItem ? getItemContentByType(currentItem) : selected;
  };

  const handleClose = () => {
    setIsOpen(false);
    if (searchQuery.length > 0) {
      setSearchQuery('');
    }
  };

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    if (props?.onOpen) props.onOpen();
  }, [props?.onOpen])

  return (
    <Stack
      className={`select-container formContent-select ${className}`}
      direction="column"
    >
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={uploadImage}
        ref={addPictureInputRef}
        accept="image/*"
      />
      <Stack
        className="select-content"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        marginBottom={'4px'}
        gap={1}
      >
        <Stack
          className="select-headerBlock formContent-select-fieldTitle"
          direction="row"
          alignItems="center"
          gap={1}
        >
          {header && (
            <Tooltip arrow title={tooltip} placement="top">
              <h3>
                {required && (
                  <span
                    style={{
                      color: 'red',
                      marginRight: '6px'
                    }}
                  >
                    *
                  </span>
                )}
                {header}
              </h3>
            </Tooltip>
          )}
          {loading && <CircularProgress size={12} />}
        </Stack>
      </Stack>
      <Stack className="select-block">
        <>
          {onClear && props?.value && (
            <div className="select-block__close-btn">
              <IconButton onClick={onClear} size="small">
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </div>
          )}
        <Select
            {...props}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            disabled={props.disabled || isUploading || loading}
            sx={{
              '.MuiTypography-root': {
                overflowX: 'clip'
              },
              ...props.sx
            }}
            multiple={isCheckBox}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '280px',
                  width: isCheckBox ? '200px' : 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }
              },
              MenuListProps: {
                style: {
                  paddingTop: isCheckBox ? '9px' : '8px',
                  paddingBottom: isCheckBox ? 0 : '8px',
                  flexGrow: 1,
                  overflowY: 'auto'
                }
              }
            }}
            className="formContent-select-selectField"
            renderValue={(selected) => handleRenderValue(selected)}
          >
            {isSearchEnabled && (
              <div className="formContent-select-searchField">
                <TextField
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                  size="small"
                  fullWidth
                  autoFocus
                />
              </div>
            )}

            {isCheckBox ? (
              <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {isSelectAllEnabled && (
                  <MenuItem
                    key={EAcSelectOptions.ALL}
                    sx={{
                      padding: '8px 12px',
                      fontSize: '14px'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckboxClick(
                        EAcSelectOptions.ALL,
                        EAcSelectOptions.ALL
                      );
                    }}
                  >
                    <Checkbox
                      checked={handleIsItemChecked(EAcSelectOptions.ALL)}
                      size="small"
                      sx={{
                        padding: 0,
                        marginRight: '6px'
                      }}
                    />
                    Select all
                  </MenuItem>
                )}
                {renderSelectItems()}
              </Box>
            ) : (
              renderSelectItems()
            )}
            {isCheckBox && isApplyBtnEnabled && (
              <div
                className="apply-button--container"
                style={{ padding: '6px 8px' }}
              >
                <Button
                  size="small"
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    setSelectedItems(checkedItems);
                    setIsOpen(false);
                  }}
                  sx={{
                    width: '100%'
                  }}
                  disableElevation={true}
                >
                  Apply
                </Button>
              </div>
            )}
          </Select>
        </>
      </Stack>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      {uploadConfig && (
        <Stack direction="row" alignItems="center">
          {isUploading && (
            <CircularProgress
              style={{ marginTop: '6px', marginLeft: '6px' }}
              size={14}
            />
          )}
          {!isUploading && uploadConfig.uploadMessage ? (
            <Tooltip
              arrow
              title={`${UploadTooltipsDescription} ${uploadConfig.uploadMessage}`}
              placement="right"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12]
                    }
                  }
                ]
              }}
            >
              <Button
                size="small"
                variant="text"
                disabled={isUploading || !permissionsUtil.canUserEdit()}
                disableRipple
                onClick={() => {
                  addPictureInputRef?.current?.click();
                }}
                className="select-uploadBtn formContent-select-uploadButton"
                data-testid={props?.uploadBtnTestId}
              >
                Upload
              </Button>
            </Tooltip>
          ) : (
            <Button
              size="small"
              variant="text"
              disabled={isUploading || !permissionsUtil.canUserEdit()}
              disableRipple
              onClick={() => {
                addPictureInputRef?.current?.click();
              }}
              className="select-uploadBtn formContent-select-uploadButton"
              data-testid={props?.uploadBtnTestId}
            >
              Upload
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default AcSelect;
