import React from 'react';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { StoreBodyBlock } from 'constants/enums';

import { StyleBlockItem } from './Style';

export interface IBodyBlockItem {
  value: StoreBodyBlock;
  name: string;
  enabled: boolean;
}

interface IBlockItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: IBodyBlockItem;
  onVisibilityChange: () => void;
}

export const BlockItem: React.FC<IBlockItemProps> = ({
  item,
  onVisibilityChange,
  ...rest
}) => {
  return (
    <StyleBlockItem
      direction="row"
      textColor={item.enabled ? '#000000' : '#a8a9b2'}
      {...rest}
    >
      <span className="material-icons guide-list--item__icon drag_handle">
        drag_handle
      </span>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography color="inherit">{item.name}</Typography>
      </Stack>
      <span
        onClick={onVisibilityChange}
        className="visibility-icon"
        title="Component visibility"
      >
        <img
          alt="visibility"
          src={`${process.env.PUBLIC_URL}/assets/images/${item.enabled ? 'visibility.svg' : 'visibility_off.svg'}`}
        />
      </span>
    </StyleBlockItem>
  );
};
