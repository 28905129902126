import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import useOffers from 'api/useOffers';
import {
  ExtendedDateRange,
  FormikProps,
  ScheduleTimeFrame,
  TimeframeDateRange
} from 'common/contracts';
import { ENotificationType, OfferType } from 'constants/enums';
import dayjs, { Dayjs } from 'dayjs';
import { useNotifications } from 'hooks/useNotifications';

import AcCard from 'components/AcCard/AcCard';
import AcDateTimePicker from 'components/AcDateTimePicker/AcDateTimePicker';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';
import TimeframesTable from 'components/TimeframesTable/TimeframesTable';

interface ScheduleTabProps {
  formikProps: FormikProps;
  rollingOfferId?: string;
  edit: boolean;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({
  formikProps,
  rollingOfferId,
  edit
}) => {
  const { values, setFieldValue } = formikProps;
  const { enqueueSnackbar } = useNotifications();

  const { updateOffer } = useOffers(rollingOfferId, OfferType.ROLLING_OFFER);

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [appearancesTableData, setAppearancesTableData] = useState<
    ScheduleTimeFrame[]
  >([]);
  const [editRangeDatesValues, setEditRangeDatesValues] =
    useState<ExtendedDateRange | null>(null);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const timeFramesData: ScheduleTimeFrame[] =
      values.schedule?.timeFrames?.map(
        (date: ScheduleTimeFrame, index: number) => ({
          id: date.id || `id-${index}`,
          startTime: date.startTime,
          endTime: date.endTime,
          notes: date.notes
        })
      );

    setAppearancesTableData(timeFramesData);
  }, [values?.schedule?.timeFrames]);

  const resetScheduleValues = () => {
    setDateRange([null, null]);
    setEditRangeDatesValues(null);
    setNotes('');
  };

  const updateOfferStatus = async (active: boolean) => {
    if (edit) {
      try {
        await updateOffer.mutateAsync({
          offerId: String(rollingOfferId),
          form: { active: active }
        });
        setFieldValue('active', active);
      } catch (error) {
        enqueueSnackbar('Error editing rolling offer', ENotificationType.ERROR);
      }
    } else setFieldValue('active', active);
  };

  const handleApply = (range: TimeframeDateRange, notes?: string) => {
    const [fromUtcDate, toUtcDate] = range;

    if (fromUtcDate && toUtcDate) {
      const newTimeFrame: ScheduleTimeFrame = {
        startTime: new Date(fromUtcDate.toISOString()), // UTC start date
        endTime: new Date(toUtcDate.toISOString()), // UTC end date
        notes
      };

      if (editRangeDatesValues) {
        const [editStartTime, editEndTime] = editRangeDatesValues;

        const updatedTimeFrames = values?.schedule?.timeFrames.map(
          (timeFrame: ScheduleTimeFrame) => {
            const currentStartTimestamp = new Date(
              timeFrame.startTime
            ).getTime();
            const currentEndTimestamp = new Date(timeFrame.endTime).getTime();

            return currentStartTimestamp ===
              new Date(editStartTime).getTime() &&
              currentEndTimestamp === new Date(editEndTime).getTime()
              ? newTimeFrame
              : timeFrame;
          }
        );
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: updatedTimeFrames
        });
      } else {
        setEditRangeDatesValues(null);
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: [...values?.schedule?.timeFrames, newTimeFrame]
        });
      }
    } else {
      console.error('Invalid date range provided: Start or End date is null.');
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        justifyContent="space-between"
        flexDirection="row"
        alignItems="start"
      >
        <AcCard
          stackContainer={false}
          title={editRangeDatesValues ? 'Edit appearance' : 'Scheduled offer'}
        >
          <AcDateTimePicker
            onApply={(dateRange: TimeframeDateRange, notes?: string) =>
              handleApply(dateRange, notes)
            }
            editRangeDatesValues={editRangeDatesValues}
            setEditRangeDatesValues={setEditRangeDatesValues}
            disableFuture={false}
            minDate={dayjs().utc().startOf('day')}
            applyText={editRangeDatesValues ? 'Apply' : 'Add Appearance'}
            notes={notes}
            setNotes={setNotes}
            formikProps={formikProps}
            dateRange={dateRange}
            setDateRange={setDateRange}
            resetScheduleValues={resetScheduleValues}
          />
        </AcCard>
        <CustomizedSwitch
          status={values.active}
          texts={['Is Active', 'Is Active']}
          functions={[
            () => updateOfferStatus(false),
            () => updateOfferStatus(true)
          ]}
          color={'#000000'}
        />
      </Stack>
      <TimeframesTable
        formikProps={formikProps}
        appearancesTableData={appearancesTableData}
        handleEditTimeframe={(startTime: string, endTime: string, notes: string) => {
          setEditRangeDatesValues([
            new Date(startTime),
            new Date(endTime),
            notes
          ]);
        }}
        onDeleteRow={resetScheduleValues}
      />
    </>
  );
};

export default ScheduleTab;
