import { OfferDesignTypeItem, OfferDesignTypes } from "views/OffersUI/OffersUIForm/types";

export const roundedHoursOptions = [
  {
    content: '00:00',
    key: '00:00',
    value: '00:00'
  },
  {
    content: '01:00',
    key: '01:00',
    value: '01:00'
  },
  {
    content: '02:00',
    key: '02:00',
    value: '02:00'
  },
  {
    content: '03:00',
    key: '03:00',
    value: '03:00'
  },
  {
    content: '04:00',
    key: '04:00',
    value: '04:00'
  },
  {
    content: '05:00',
    key: '05:00',
    value: '05:00'
  },
  {
    content: '06:00',
    key: '06:00',
    value: '06:00'
  },
  {
    content: '07:00',
    key: '07:00',
    value: '07:00'
  },
  {
    content: '08:00',
    key: '08:00',
    value: '08:00'
  },
  {
    content: '09:00',
    key: '09:00',
    value: '09:00'
  },
  {
    content: '10:00',
    key: '10:00',
    value: '10:00'
  },
  {
    content: '11:00',
    key: '11:00',
    value: '11:00'
  },
  {
    content: '12:00',
    key: '12:00',
    value: '12:00'
  },
  {
    content: '13:00',
    key: '13:00',
    value: '13:00'
  },
  {
    content: '14:00',
    key: '14:00',
    value: '14:00'
  },
  {
    content: '15:00',
    key: '15:00',
    value: '15:00'
  },
  {
    content: '16:00',
    key: '16:00',
    value: '16:00'
  },
  {
    content: '17:00',
    key: '17:00',
    value: '17:00'
  },
  {
    content: '18:00',
    key: '18:00',
    value: '18:00'
  },
  {
    content: '19:00',
    key: '19:00',
    value: '19:00'
  },
  {
    content: '20:00',
    key: '20:00',
    value: '20:00'
  },
  {
    content: '21:00',
    key: '21:00',
    value: '21:00'
  },
  {
    content: '22:00',
    key: '22:00',
    value: '22:00'
  },
  {
    content: '23:00',
    key: '23:00',
    value: '23:00'
  }
];

export const refreshEveryOptions = [
  {
    value: 'Day',
    content: 'Day',
    key: 'day'
  }
];

export const fontWeightOptions = [
  {
    content: 'regular',
    key: 'normal',
    value: 'normal'
  },
  {
    content: 'bold',
    key: 'bold',
    value: 'bold'
  },
  {
    content: 'bolder',
    key: 'bolder',
    value: 'bolder'
  }
];

const separatorOptions = [
  {
    content: 'Comma',
    key: ',',
    value: ','
  },
  {
    content: 'Dot',
    key: '.',
    value: '.'
  },
  {
    content: 'Space',
    key: ' ',
    value: ' '
  }
];

const trueFalseOptions = [
  {
    content: 'Yes',
    key: 'true',
    value: 'true'
  },
  {
    content: 'No',
    key: 'false',
    value: 'false',
    selected: true
  }
];

export const adminSettingsUtils = {
  separatorOptions,
  trueFalseOptions
};

export const initialOfferDesignTypesItems: OfferDesignTypeItem[] = [
  {
    content: 'Special Offer',
    key: OfferDesignTypes.SpecialOffer,
    value: OfferDesignTypes.SpecialOffer
  },
  {
    content: 'Bundle',
    key: OfferDesignTypes.Bundle,
    value: OfferDesignTypes.Bundle
  },
  {
    content: 'Pop Up',
    key: OfferDesignTypes.PopUp,
    value: OfferDesignTypes.PopUp
  },
  {
    content: 'Rolling Offer',
    key: OfferDesignTypes.RollingOffer,
    value: OfferDesignTypes.RollingOffer
  }
];
