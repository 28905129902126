import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useTheme from 'api/useTheme';
import { AuthSliceState } from 'store/store.types';

import { defaultLoginTheme } from './defaults';

export const useGetLoginInitialValues = () => {
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [initialValues, setInitialValue] = useState<any>({});

  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );

  const loginTheme = useMemo(
    () => ({ data: storeTheme?.data?.login }),
    [storeTheme?.data]
  );

  useEffect(() => {
    setInitialValue({
      text: loginTheme?.data?.text || defaultLoginTheme.text,
      textColor: loginTheme?.data?.textColor || defaultLoginTheme.textColor,
      textSize: loginTheme?.data?.textSize || defaultLoginTheme.textSize,
      textWeight: loginTheme?.data?.textWeight || defaultLoginTheme.textWeight,
      font: loginTheme?.data?.font || defaultLoginTheme.font,
      loginLogoImage:
        loginTheme?.data?.loginLogoImage || defaultLoginTheme.loginLogoImage,
      loginLogoSize:
        loginTheme?.data?.loginLogoSize || defaultLoginTheme.loginLogoSize,
      loginBackgroundImageMobile:
        loginTheme?.data?.loginBackgroundImageMobile ||
        defaultLoginTheme.loginBackgroundImageMobile,
      loginBackgroundImageDesktop:
        loginTheme?.data?.loginBackgroundImageDesktop ||
        defaultLoginTheme.loginBackgroundImageDesktop,
      otpButton: {
        backgroundColor: {
          colorOne:
            loginTheme?.data?.otpButton?.backgroundColor?.colorOne ||
            defaultLoginTheme.otpButton.backgroundColor.colorOne,
          colorTwo:
            loginTheme?.data?.otpButton?.backgroundColor?.colorTwo ||
            defaultLoginTheme.otpButton.backgroundColor.colorTwo,
          gradientDirection:
            loginTheme?.data?.otpButton?.backgroundColor?.gradientDirection ||
            defaultLoginTheme.otpButton.backgroundColor.gradientDirection
        },
        icon:
          loginTheme?.data?.otpButton?.icon || defaultLoginTheme.otpButton.icon,
        text:
          loginTheme?.data?.otpButton?.text || defaultLoginTheme.otpButton.text,
        textColor: {
          colorOne:
            loginTheme?.data?.otpButton?.textColor?.colorOne ||
            defaultLoginTheme.otpButton.textColor.colorOne,
          colorTwo:
            loginTheme?.data?.otpButton?.textColor?.colorTwo ||
            defaultLoginTheme.otpButton.textColor.colorTwo,
          gradientDirection:
            loginTheme?.data?.otpButton?.textColor?.gradientDirection ||
            defaultLoginTheme.otpButton.textColor.gradientDirection
        }
      },
      consentSection: {
        htmlText:
          loginTheme?.data?.consentSection?.htmlText ||
          defaultLoginTheme.consentSection.htmlText,
        consentRequired:
          loginTheme?.data?.consentSection?.consentRequired ||
          defaultLoginTheme.consentSection.consentRequired
      },
      accountApprovalModal: {
        backgroundImage:
          storeTheme.data?.general?.accountApprovalModal?.backgroundImage ||
          defaultLoginTheme.accountApprovalModal.backgroundImage,
        backgroundColor: {
          colorOne:
            storeTheme.data?.general?.accountApprovalModal?.backgroundColor
              ?.colorOne ||
            defaultLoginTheme.accountApprovalModal.backgroundColor.colorOne,
          colorTwo:
            storeTheme.data?.general?.accountApprovalModal?.backgroundColor
              ?.colorTwo ||
            defaultLoginTheme.accountApprovalModal.backgroundColor.colorTwo,
          gradientDirection:
            storeTheme.data?.general?.accountApprovalModal?.backgroundColor
              ?.gradientDirection || ''
        },
        borderColor:
          storeTheme.data?.general?.accountApprovalModal?.borderColor ||
          defaultLoginTheme.accountApprovalModal.borderColor,
        borderWidth:
          storeTheme.data?.general?.accountApprovalModal?.borderWidth ||
          defaultLoginTheme.accountApprovalModal.borderWidth,
        buttonBackgroundColor: {
          colorOne:
            storeTheme.data?.general?.accountApprovalModal
              ?.buttonBackgroundColor?.colorOne ||
            defaultLoginTheme.accountApprovalModal.buttonBackgroundColor
              .colorOne,
          colorTwo:
            storeTheme.data?.general?.accountApprovalModal
              ?.buttonBackgroundColor?.colorTwo ||
            defaultLoginTheme.accountApprovalModal.buttonBackgroundColor
              .colorTwo,
          gradientDirection:
            storeTheme.data?.general?.accountApprovalModal
              ?.buttonBackgroundColor?.gradientDirection || ''
        },
        buttonTextColor:
          storeTheme.data?.general?.accountApprovalModal?.buttonTextColor ||
          defaultLoginTheme.accountApprovalModal.buttonTextColor,
        textColor:
          storeTheme.data?.general?.accountApprovalModal?.textColor ||
          defaultLoginTheme.accountApprovalModal.textColor
      }
    });
  }, [loginTheme?.data, storeTheme?.data, location]);

  return { initialValues };
};
