import { useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { EOrderStatus } from 'constants/enums';
import dayjs from 'dayjs';
import {
  handleDateRangeApplied,
  handleSameDateRangeApplied
} from 'utils/getDateRange';

import AcSearchInput from 'components/AcInput/AcSearchInput';
import AcSearchSelect from 'components/AcSelect/AcSearchSelect';

import {
  URL_ARRAY_PARAM_SEPARATOR,
  usePaginationFromURL
} from '../../../hooks/usePaginationFromURL';
import OrdersDatePicker from '../OrdersTable/components/OrdersDatePicker';
import { statusDictionary } from '../OrdersTable/OrdersTable.types';
import { ordersUtils } from '../utils';

import { FilterProps } from './FilterBar.types';

const FilterBar: React.FC<FilterProps> = ({
  projectsDetails,
  setFormattedRangeDates,
  getSearchResult,
  isFree
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
    null,
    null
  ]);
  const endDate = dayjs();
  const [rangeDates, setRangeDates] = useState([
    endDate.subtract(30, 'days'),
    endDate
  ]);
  const [clearFiltersButton, setClearFiltersButton] = useState<boolean>(false);

  const { statuses, publisherPurchaseId, playerId, orderId, receiptId } =
    usePaginationFromURL('orders');

  useEffect(() => {
    setClearFiltersButton(
      playerId ||
        publisherPurchaseId ||
        receiptId ||
        orderId ||
        statuses.length > 0
        ? true
        : false
    );
  }, [playerId, publisherPurchaseId, receiptId, orderId, statuses]);

  const formattedDate = `${rangeDates[0].format(
    'DD/MM/YY'
  )} - ${rangeDates[1].format('DD/MM/YY')}`;

  const updateQueryParam = (paramName: string, paramValue: string) =>
    ordersUtils.updateQueryParam({
      paramName,
      paramValue,
      location,
      navigate
    });

  const handleDateApply = (range: any, hours: any) => {
    if (!hours) {
      handleDateRangeApplied({
        range,
        setRangeDates,
        setFormattedRangeDates,
        setPreviousFormattedRangeDates
      });
      return;
    }
    handleSameDateRangeApplied({
      range,
      hours,
      setRangeDates,
      setFormattedRangeDates,
      setPreviousFormattedRangeDates
    });
  };

  const getStatus = useMemo<{ text: string; statuses: string }[]>(() => {
    const statusesArray = Object.values(EOrderStatus)
      .filter((value) => {
        if (isFree)
          return (
            value === EOrderStatus.created ||
            value === EOrderStatus.charge_pending ||
            value === EOrderStatus.charge_succeed ||
            value === EOrderStatus.charge_failed
          );
        return (
          value !== EOrderStatus.unpaid &&
          value !== EOrderStatus.payment_refund_cancelled
        );
      })
      .map((value) => ({
        text:
          isFree && value === EOrderStatus.charge_failed
            ? 'Failed'
            : statusDictionary[value as EOrderStatus]?.text,
        status: value
      }))
      .sort((a, b) => {
        const textA = a.text.toLowerCase();
        const textB = b.text.toLowerCase();

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

    return Object.values(
      statusesArray.reduce((statusesTextObj: any, { text, status }) => {
        if (!statusesTextObj[text]) {
          statusesTextObj[text] = { text, statuses: [] };
        }
        statusesTextObj[text].statuses.push(status);
        return statusesTextObj;
      }, {})
    ).map((item: any) => ({
      text: item.text,
      statuses: item.statuses.join(ordersUtils.STATUSES_SEPARATOR)
    }));
  }, [isFree]);

  const resetQueryParams = (arrParamsToReset: string[]) => {
    const newParams = new URLSearchParams(location.search);

    arrParamsToReset.forEach((param) => newParams.delete(param));

    const navigationOpt: {
      replace: boolean;
      state?: Record<string, boolean>;
    } = { replace: true };
    if (location?.state) {
      navigationOpt.state = location.state;
    }
    navigate(`${location.pathname}?${newParams.toString()}`, navigationOpt);
  };

  const handleClearFilters = () => {
    resetQueryParams([
      'publisherPurchaseId',
      'receiptId',
      'orderId',
      'playerId',
      'statuses'
    ]);
    getSearchResult('');
    setRangeDates([endDate.subtract(30, 'days'), endDate]);
    setFormattedRangeDates([null, null]);
  };

  return (
    <Stack pt={1} sx={{ paddingTop: '16px' }}>
      {!projectsDetails && (
        <Box pb={2}>
          <h3>Search by:</h3>
        </Box>
      )}
      <Stack
        direction="row"
        gap={1}
        sx={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        {projectsDetails && (
          <OrdersDatePicker
            isOpen={isDatePickerOpen}
            setOverlayOpen={setIsDatePickerOpen}
            handleDateApply={handleDateApply}
            formattedDate={formattedDate}
            startDate={rangeDates[0]}
            endDate={rangeDates[1]}
          />
        )}
        <AcSearchSelect
          header="Status"
          name="status"
          items={getStatus.map(({ text, statuses }, index: number) => ({
            content: text,
            key: String(index),
            value: index,
            filterBy: statuses
          }))}
          sx={{ flexGrow: 1 }}
          isCheckBox
          setSelectedItems={(ids: string[]) =>
            updateQueryParam(
              'statuses',
              encodeURIComponent(ids.join(URL_ARRAY_PARAM_SEPARATOR))
            )
          }
          value={statuses}
          className="selector-checkbox"
          isFree={isFree}
        />
        <AcSearchInput
          size="small"
          header="Transaction ID"
          onChange={(e: any) => {
            const value = e.target.value;
            getSearchResult(value);
            updateQueryParam('publisherPurchaseId', value);
          }}
          sx={{ flexGrow: 1 }}
          value={publisherPurchaseId}
          setValue={(value) => updateQueryParam('publisherPurchaseId', value)}
        />
        <AcSearchInput
          size="small"
          header="Player ID"
          onChange={(e: any) => {
            const value = e.target.value;
            getSearchResult(value);
            updateQueryParam('playerId', value);
          }}
          sx={{ flexGrow: 1 }}
          value={playerId}
          setValue={(value) => updateQueryParam('playerId', value)}
        />
        <AcSearchInput
          size="small"
          header="Order ID"
          onChange={(e: any) => {
            const value = e.target.value;
            getSearchResult(value);
            updateQueryParam('orderId', value);
          }}
          sx={{ flexGrow: 1 }}
          value={orderId}
          setValue={(value) => updateQueryParam('orderId', value)}
        />
        {!isFree && (
          <AcSearchInput
            size="small"
            header="Receipt number"
            onChange={(e: any) => {
              const value = e.target.value;
              getSearchResult(value);
              updateQueryParam('receiptId', value);
            }}
            value={receiptId}
            setValue={(value) => updateQueryParam('receiptId', value)}
          />
        )}

        {clearFiltersButton && (
          <Button
            variant="text"
            id="clearFiltersButton"
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default FilterBar;
