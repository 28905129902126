import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useTheme from 'api/useTheme';
import { AuthSliceState } from 'store/store.types';

import { defaultGeneralTheme } from '../GeneralSettings/defaults';

export const useGetCheckoutInitialValues = () => {
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [initialValues, setInitialValue] = useState<any>({});

  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );

  const checkoutTheme = useMemo(
    () => ({ data: storeTheme?.data?.checkout }),
    [storeTheme?.data]
  );

  useEffect(() => {
    setInitialValue({
      backgroundImage: checkoutTheme.data?.backgroundImage || '',
      buttonColor: checkoutTheme.data?.buttonColor,
      buttonTextColor: checkoutTheme.data?.buttonTextColor || '',
      mobileBgImage: checkoutTheme.data?.mobileBgImage || '',
      primaryColor: checkoutTheme.data?.primaryColor || '',
      textColor: checkoutTheme.data?.textColor || '',
      logo:
        checkoutTheme.data?.logo ||
        storeTheme.data?.general?.logo ||
        defaultGeneralTheme.logo ||
        '',
      publisherId: checkoutTheme.data?.publisherId || '',
      _id: checkoutTheme.data?._id || ''
    });
  }, [checkoutTheme.data, storeTheme.data, location]);

  return { initialValues };
};
