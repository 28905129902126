import React, { useEffect, useRef } from 'react';

import ReactQuill from 'react-quill';

import { FormHelperText, Stack, TextFieldProps, Tooltip } from '@mui/material';

import 'react-quill/dist/quill.snow.css';
import './style.scss';

const formats = [
  'size',
  'header',
  'bold',
  'italic',
  'underline',
  'color',
  'link'
];

export type AcAdvancedTextInputProps = TextFieldProps & {
  header?: string;
  tooltip?: string;
  value?: string;
  textarea?: boolean;
  helperText?: string;
  enableCopy?: boolean;
  required?: boolean;
  customClass?: string;
  setValue: (value: string) => any;
};

export const AcAdvancedTextInput: React.FC<AcAdvancedTextInputProps> = ({
  header = '',
  tooltip = '',
  textarea = false,
  value = '',
  helperText = '',
  required = false,
  customClass = '',
  setValue,
  ...props
}) => {
  const quillRef = useRef<ReactQuill>(null);

  const modules = {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        ['link']
      ]
    }
  };

  return (
    <Stack
      className={`input-container formContent-input ${customClass}`}
      direction="column"
    >
      <Stack
        direction="row"
        alignItems="center"
        marginBottom={'4px'}
        gap={'5px'}
        className="input-headerBlock formContent-input-fieldTitle"
      >
        {header && (
          <Tooltip arrow title={tooltip} placement="top">
            <h3>
              {required && (
                <span
                  style={{
                    color: 'red',
                    marginRight: '6px'
                  }}
                >
                  *
                </span>
              )}
              {header}
            </h3>
          </Tooltip>
        )}
      </Stack>
      <ReactQuill
        className={`formContent-input-inputField ${customClass}`}
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={setValue}
      />
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </Stack>
  );
};
