import { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Background } from '@appcharge/shared-ui';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import useImages from 'api/useImages';
import useIntegration from 'api/useIntegration';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import {
  EAssetType,
  EFeatureFlag,
  EFontWeight,
  ELogoSize,
  EStyleProperty
} from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips, useUploads } from 'hooks/useUpload';
import { LoginPreview } from 'Previews/LoginPage';
import { AuthSliceState } from 'store/store.types';
import { getStyledSelectItem } from 'utils/getStyledSelectItem';

import { AcAdvancedTextInput } from 'components/AcAdvancedTextInput/AcAdvancedInput';
import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { ThemeLoader } from '../ThemeLoader';
import { getFonts } from '../utils';

import { AccountApprovalModalSection } from './Components/AccountApprovalModalForm';
import { LoginThemeFormValues } from './types';

import 'style/forms.scss';

export const LoginTheme: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<LoginThemeFormValues>();
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const { getIntegration } = useIntegration(currentPublisherId);
  const [currentUploadingField, setCurrentUploadingField] = useState('');
  const { uploadImage } = useUploads();
  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const { getStoreTheme: storeTheme } = useTheme(
    currentPublisherId || undefined
  );

  const showNewPrivacySection: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_NEW_PRIVACY_RICH_TEXT
      ],
    [fetchFeatureFlags?.data]
  );

  const hasValues = useMemo(() => Object.keys(values).length > 0, [values]);

  useEffect(
    () => setIsLoading(storeTheme.isLoading || !storeTheme.data || !hasValues),
    [storeTheme?.isLoading, storeTheme?.data, hasValues]
  );

  if (isLoading) {
    return <ThemeLoader />;
  }

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        <Stack>
          <AcCard
            stackContainer={false}
            title="Now Say 'Hi'"
            description="Greet your players with a welcome message. This will be your players’ first encounter with your store, so be nice!"
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Text"
                  name="text"
                  value={values.text}
                  tooltip="This will be the text that welcomes your users"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.text && Boolean(errors.text)}
                />
              </Grid>
              <Grid item xs={4}>
                <AcGradientInput
                  header="Color"
                  name="textColor"
                  defaultValue={{
                    colorOne: values.textColor
                  }}
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  outputAsSingleColor
                  error={touched.textColor && Boolean(errors.textColor)}
                />
              </Grid>
              <Grid item xs={4}>
                <AcInput
                  header="Size (px)"
                  name="textSize"
                  type="number"
                  inputProps={{
                    max: 32,
                    min: 16
                  }}
                  value={values.textSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.textSize && Boolean(errors.textSize)}
                />
              </Grid>
              <Grid item xs={4}>
                <AcSelect
                  header="Weight"
                  name="textWeight"
                  defaultValue={EFontWeight.Regular}
                  value={values.textWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.textWeight && Boolean(errors.textWeight)}
                  items={[
                    {
                      content: 'Regular',
                      key: EFontWeight.Regular,
                      value: '300',
                      renderFunction: () =>
                        getStyledSelectItem(
                          EFontWeight.Regular,
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Medium',
                      key: EFontWeight.Medium,
                      value: '500',
                      renderFunction: () =>
                        getStyledSelectItem(
                          EFontWeight.Medium,
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Bold',
                      key: EFontWeight.Bold,
                      value: '700',
                      renderFunction: () =>
                        getStyledSelectItem(
                          EFontWeight.Bold,
                          EStyleProperty.fontWeight
                        )
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </AcCard>
          <Divider />
          <AcCard stackContainer={false} title="Font">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcSelect
                  header="Font"
                  name="font"
                  value={values.font}
                  items={getFonts()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.font && Boolean(errors.font)}
                />
              </Grid>
            </Grid>
          </AcCard>
          <Divider />
          <AcCard
            stackContainer={false}
            title="Login Assets"
            description="The brand assets you’ll add here will appear only in this screen. You can change them any time."
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={6}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  onChange={(e) =>
                    uploadImage(
                      e,
                      addPictureInputRef.current as HTMLInputElement,
                      pictures,
                      setFieldValue,
                      setCurrentUploadingField,
                      currentUploadingField
                    )
                  }
                  ref={addPictureInputRef}
                  accept="image/*"
                />
                <AcSelect
                  header="Logo"
                  name="loginLogoImage"
                  value={
                    values.loginLogoImage || storeTheme.data?.general?.logo
                  }
                  uploadConfig={{
                    onUploadSuccess: async (uploadData: any) => {
                      await pictures.refetch();
                      setFieldValue('loginLogoImage', uploadData!.data.url);
                    },
                    uploadType: EAssetType.LOGO,
                    uploadMessage: UploadsTooltips[EAssetType.LOGO]
                  }}
                  items={[
                    {
                      content: 'No image',
                      key: 'no-image',
                      value: '',
                      renderFunction: () => (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <span
                            style={{
                              width: 30,
                              height: 30,
                              display: 'inline-block',
                              background: '#eee',
                              textAlign: 'center',
                              lineHeight: '30px'
                            }}
                          ></span>
                          <Typography>No Image</Typography>
                        </Stack>
                      )
                    },
                    {
                      content: 'Default Appcharge logo',
                      key: 'https://media.appcharge.com/defaults/logo.svg',
                      value: 'https://media.appcharge.com/defaults/logo.svg',
                      renderFunction: () => {
                        return (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <img
                              src="https://media.appcharge.com/defaults/logo.svg"
                              alt="Default Appcharge logo"
                              style={{
                                maxWidth: 26
                              }}
                            />
                            <Typography>Default Appcharge logo</Typography>
                          </Stack>
                        );
                      }
                    },
                    ...(pictures.data
                      ? pictures.data
                          .filter((p: any) => p.type === EAssetType.LOGO)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 26,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      : [])
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.loginLogoImage && Boolean(errors.loginLogoImage)
                  }
                  tooltip="This will be the logo of your login page."
                />
              </Grid>
              <Grid item xs={6}>
                <AcSelect
                  header="Logo Size"
                  name="loginLogoSize"
                  value={values.loginLogoSize}
                  items={[
                    {
                      content: 'Small',
                      key: ELogoSize.SMALL,
                      value: ELogoSize.SMALL
                    },
                    {
                      content: 'Large',
                      key: ELogoSize.BIG,
                      value: ELogoSize.BIG
                    }
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.loginLogoSize && Boolean(errors.loginLogoSize)}
                  tooltip="Image with 1:1 ratio recommended, with at least 200px size for small view and 400px size for large view"
                />
              </Grid>
              <Grid item xs={12}>
                <AcSelect
                  header="Login Background Image (Mobile)"
                  name="loginBackgroundImageMobile"
                  value={
                    values.loginBackgroundImageMobile ||
                    storeTheme.data?.general?.backgroundImageMobile
                  }
                  uploadConfig={{
                    onUploadSuccess: async (uploadData: any) => {
                      await pictures.refetch();
                      setFieldValue(
                        'loginBackgroundImageMobile',
                        uploadData!.data.url
                      );
                    },
                    uploadType: EAssetType.BG_MOBILE,
                    uploadMessage: UploadsTooltips[EAssetType.BG_MOBILE]
                  }}
                  items={[
                    {
                      content: 'No image',
                      key: 'no-image',
                      value: '',
                      renderFunction: () => (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <span
                            style={{
                              width: 30,
                              height: 30,
                              display: 'inline-block',
                              background: '#eee',
                              textAlign: 'center',
                              lineHeight: '30px'
                            }}
                          ></span>
                          <Typography>No Image</Typography>
                        </Stack>
                      )
                    },
                    {
                      content: 'Default Appcharge background',
                      key: 'https://media.appcharge.com/defaults/background.png',
                      value:
                        'https://media.appcharge.com/defaults/background.png',
                      renderFunction: () => {
                        return (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <img
                              src="https://media.appcharge.com/defaults/background.png"
                              alt="Default Appcharge background"
                              style={{
                                width: 30,
                                maxHeight: 30
                              }}
                            />
                            <Typography>
                              Default Appcharge background
                            </Typography>
                          </Stack>
                        );
                      }
                    },
                    ...(pictures.data
                      ? pictures.data
                          .filter((p: any) => p.type === EAssetType.BG_MOBILE)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      : [])
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.loginBackgroundImageMobile &&
                    Boolean(errors.loginBackgroundImageMobile)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcSelect
                  header="Login Background Image (Desktop)"
                  name="loginBackgroundImageDesktop"
                  value={
                    values.loginBackgroundImageDesktop ||
                    storeTheme.data?.general?.backgroundImageDesktop
                  }
                  uploadConfig={{
                    onUploadSuccess: async (uploadData: any) => {
                      await pictures.refetch();
                      setFieldValue(
                        'loginBackgroundImageDesktop',
                        uploadData!.data.url
                      );
                    },
                    uploadType: EAssetType.BG_DESKTOP,
                    uploadMessage: UploadsTooltips[EAssetType.BG_DESKTOP]
                  }}
                  items={[
                    {
                      content: 'No image',
                      key: 'no-image',
                      value: '',
                      renderFunction: () => (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <span
                            style={{
                              width: 30,
                              height: 30,
                              display: 'inline-block',
                              background: '#eee',
                              textAlign: 'center',
                              lineHeight: '30px'
                            }}
                          ></span>
                          <Typography>No Image</Typography>
                        </Stack>
                      )
                    },
                    {
                      content: 'Default Appcharge background',
                      key: 'https://media.appcharge.com/defaults/background.png',
                      value:
                        'https://media.appcharge.com/defaults/background.png',
                      renderFunction: () => {
                        return (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <img
                              src="https://media.appcharge.com/defaults/background.png"
                              alt="Default Appcharge background"
                              style={{
                                width: 30,
                                maxHeight: 30
                              }}
                            />
                            <Typography>
                              Default Appcharge background
                            </Typography>
                          </Stack>
                        );
                      }
                    },
                    ...(pictures.data
                      ? pictures.data
                          .filter((p: any) => p.type === EAssetType.BG_DESKTOP)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      : [])
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.loginBackgroundImageDesktop &&
                    Boolean(errors.loginBackgroundImageDesktop)
                  }
                />
              </Grid>
            </Grid>
          </AcCard>
          {getIntegration.data?.playersAuthentication.otpOn && (
            <>
              <Divider />
              <AcCard
                stackContainer={false}
                title="OTP"
                description="Control your OTP login button design"
              >
                <Grid container rowSpacing={2} columnSpacing={1.5}>
                  <Grid item xs={12}>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(e) =>
                        uploadImage(
                          e,
                          addPictureInputRef.current as HTMLInputElement,
                          pictures,
                          setFieldValue,
                          setCurrentUploadingField,
                          currentUploadingField
                        )
                      }
                      ref={addPictureInputRef}
                      accept="image/*"
                    />
                    <AcSelect
                      header="OTP Icon"
                      name="otpButton.icon"
                      value={values.otpButton.icon}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue('otpButton.icon', uploadData!.data.url);
                        },
                        uploadType: EAssetType.LOGO,
                        uploadMessage: UploadsTooltips[EAssetType.LOGO]
                      }}
                      items={[
                        {
                          content: 'No image',
                          key: 'no-image',
                          value: '',
                          renderFunction: () => (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <span
                                style={{
                                  width: 30,
                                  height: 30,
                                  display: 'inline-block',
                                  background: '#eee',
                                  textAlign: 'center',
                                  lineHeight: '30px'
                                }}
                              ></span>
                              <Typography>No Image</Typography>
                            </Stack>
                          )
                        },
                        {
                          content: 'Default Appcharge logo',
                          key: 'https://media.appcharge.com/defaults/logo.svg',
                          value:
                            'https://media.appcharge.com/defaults/logo.svg',
                          renderFunction: () => {
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <img
                                  src="https://media.appcharge.com/defaults/logo.svg"
                                  alt="Default Appcharge logo"
                                  style={{
                                    maxWidth: 26
                                  }}
                                />
                                <Typography>Default Appcharge logo</Typography>
                              </Stack>
                            );
                          }
                        },
                        ...(pictures.data
                          ? pictures.data
                              .filter((p: any) => p.type === EAssetType.LOGO)
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 26,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          : [])
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.otpButton?.icon &&
                        Boolean(errors.otpButton?.icon)
                      }
                      tooltip="This will be the icon of your otp login button."
                    />
                  </Grid>
                </Grid>
                <Grid container rowSpacing={2} columnSpacing={1.5} pt={2}>
                  <Grid item xs={12}>
                    <AcInput
                      headerSize="14"
                      header="OTP Button Text"
                      name="otpButton.text"
                      value={values.otpButton.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.otpButton?.text &&
                        Boolean(errors.otpButton?.text)
                      }
                      helperText={
                        touched.otpButton?.text
                          ? errors.otpButton?.text?.toString()
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container rowSpacing={2} columnSpacing={1.5} pt={2}>
                  <Grid item xs={6}>
                    <AcGradientInput
                      header="Button Color"
                      name="otpButton.backgroundColor"
                      defaultValue={values.otpButton?.backgroundColor}
                      setValue={setFieldValue}
                      onBlur={handleBlur}
                      error={
                        touched.otpButton?.backgroundColor?.colorOne &&
                        Boolean(errors.otpButton?.backgroundColor?.colorOne)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AcGradientInput
                      header="Text Color"
                      name="otpButton.textColor"
                      defaultValue={values.otpButton?.textColor}
                      setValue={setFieldValue}
                      onBlur={handleBlur}
                      error={
                        touched.otpButton?.textColor &&
                        Boolean(errors.otpButton?.textColor)
                      }
                      helperText={
                        touched.otpButton?.textColor
                          ? errors.otpButton?.textColor?.toString()
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </AcCard>
            </>
          )}
          {showNewPrivacySection && (
            <>
              <Divider />
              <AcCard stackContainer={false} title="Privacy Settings">
                <Grid container rowSpacing={2} columnSpacing={1.5}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <AcAdvancedTextInput
                        name="consentSection.htmlText"
                        value={values.consentSection?.htmlText}
                        setValue={(value) =>
                          setFieldValue('consentSection.htmlText', value)
                        }
                        error={
                          touched.consentSection?.htmlText &&
                          Boolean(errors.consentSection?.htmlText)
                        }
                      />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="setAsFree"
                            checked={values.consentSection?.consentRequired}
                            onBlur={handleBlur}
                            onChange={async (e: any) => {
                              const isChecked = e.target.checked;
                              await setFieldValue(
                                'consentSection.consentRequired',
                                isChecked
                              );
                            }}
                          />
                        }
                        label="Ask for player's active consent before enabling the
                        login methods"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AcCard>
            </>
          )}
          <Divider />
          <AccountApprovalModalSection />
        </Stack>
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div className="store-preview-wrapper iphone-mock">
          <Background
            backgroundImageMobile={
              values.loginBackgroundImageMobile ||
              storeTheme.data?.general?.backgroundImageMobile
            }
            backgroundImageDesktop={
              values.loginBackgroundImageDesktop ||
              storeTheme.data?.general?.backgroundImageMobile
            }
            width="100%"
            height="100%"
            position="absolute"
          />
          <LoginPreview
            text={values.text}
            textColor={values.textColor}
            textSize={Number(values.textSize)}
            textWeight={values.textWeight}
            logo={values.loginLogoImage || storeTheme.data?.general?.logo}
            loginLogoSize={values.loginLogoSize || 'small'}
            width="100%"
            height="100% - 200px"
            fontFamily={values.font}
            playerAuthData={{
              usernamePasswordOn: true,
              usernamePasswordModel: 'bcrypt',
              userTokenOn: false,
              userTokenModel: '',
              googleAppSecret: 'kfdKd92h7KJH&*^%@!$',
              googleAppId: '87391245034',
              googleModel: 'OAuth2',
              googleOn: true,
              appleAppSecret: 'JHF@&$#hfdk17d',
              appleAppId: '2734590234',
              appleModel: 'Sign in with Apple',
              appleOn: false,
              fbAppSecret: 'jfdl65s4sfgsdf4&^#',
              fbAppId: '958273405',
              fbModel: 'Facebook Login',
              fbOn: true,
              otpOn: getIntegration.data!.playersAuthentication.otpOn,
              otpLinks: [],
              appleResponseType: 'code' as any,
              userTokenText: '',
              userTokenUrl: ''
            }}
            otpButton={{
              icon: values.otpButton.icon,
              text: values.otpButton.text,
              backgroundColor: values.otpButton.backgroundColor,
              textColor: values.otpButton.textColor
            }}
            htmlText={values.consentSection?.htmlText}
            consentRequired={!!values.consentSection?.consentRequired}
          />
        </div>
      </Grid>
    </Grid>
  );
};
