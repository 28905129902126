import { Stack } from '@mui/system';
import styled from 'styled-components';

export const StyledBlockListWrapper = styled.div`
  border-top: 1px solid #cacbd4;
`;
export const StyleBlockItem = styled(Stack)<{ textColor: string }>`
  align-items: center;
  height: 100%;
  gap: 2px;
  padding: 10px;
  color: ${({ textColor }) => textColor};
  border-bottom: 1px solid #cacbd4;

  .drag_handle {
    width: 64px;
    cursor: grab;
  }

  .visibility-icon {
    cursor: pointer;
  }
` as any;
