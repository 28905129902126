import { useSelector } from 'react-redux';

import { Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import { EAssetType } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import AcSolidInput from 'components/AcSolidInput/AcSolidInput';

import { LoginThemeFormValues } from '../types';

import 'style/forms.scss';

export const AccountApprovalModalSection: React.FC = () => {
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<LoginThemeFormValues>();
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;

  return (
    <AcCard stackContainer={false} title="Account Approval Modal">
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        {!pictures.isLoading && (
          <Grid item xs={6}>
            <AcSelect
              header="Background Image"
              name="accountApprovalModal.backgroundImage"
              value={values.accountApprovalModal.backgroundImage}
              uploadConfig={{
                onUploadSuccess: async (uploadData: any) => {
                  await pictures.refetch();
                  setFieldValue(
                    'accountApprovalModal.backgroundImage',
                    uploadData!.data.url
                  );
                },
                uploadType: EAssetType.BG_POPUP,
                uploadMessage: UploadsTooltips[EAssetType.BG_POPUP]
              }}
              items={[
                {
                  content: 'Default Appcharge background',
                  key: 'https://media.appcharge.com/defaults/background.png',
                  value: 'https://media.appcharge.com/defaults/background.png',
                  renderFunction: () => {
                    return (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <img
                          src="https://media.appcharge.com/defaults/background.png"
                          alt="Default Appcharge background"
                          style={{
                            width: 30,
                            maxHeight: 30
                          }}
                        />
                        <Typography>Default Appcharge background</Typography>
                      </Stack>
                    );
                  }
                },
                ...pictures?.data
                  .filter((p: any) => p.type === EAssetType.BG_POPUP)
                  .map((picture: any) => {
                    return {
                      content: picture.name,
                      key: picture.url,
                      value: picture.url,
                      renderFunction: () => {
                        return (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <img
                              src={picture.url}
                              alt={picture.name}
                              style={{
                                width: 26,
                                maxHeight: 30
                              }}
                            />
                            <Typography>{picture.name}</Typography>
                          </Stack>
                        );
                      }
                    };
                  })
              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.accountApprovalModal?.backgroundImage &&
                Boolean(errors.accountApprovalModal?.backgroundImage)
              }
              tooltip="This will be the background of the approval modal."
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <AcGradientInput
            header="Background Color"
            name="accountApprovalModal.backgroundColor"
            defaultValue={values.accountApprovalModal?.backgroundColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.backgroundColor &&
              Boolean(errors.accountApprovalModal?.backgroundColor)
            }
            helperText={
              touched.accountApprovalModal?.backgroundColor
                ? errors.accountApprovalModal?.backgroundColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcSolidInput
            header="Border Color"
            name="accountApprovalModal.borderColor"
            defaultValue={values.accountApprovalModal?.borderColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.borderColor &&
              Boolean(errors.accountApprovalModal?.borderColor)
            }
            helperText={
              touched.accountApprovalModal?.borderColor
                ? errors.accountApprovalModal?.borderColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcInput
            header="Border Width"
            name="accountApprovalModal.borderWidth"
            type="number"
            inputProps={{
              max: 6,
              min: 0
            }}
            value={values.accountApprovalModal?.borderWidth}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.borderWidth &&
              Boolean(errors.accountApprovalModal?.borderWidth)
            }
            helperText={
              touched.accountApprovalModal?.borderWidth
                ? errors.accountApprovalModal?.borderWidth?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcGradientInput
            header="Button Fill Color"
            name="accountApprovalModal.buttonBackgroundColor"
            defaultValue={values.accountApprovalModal?.buttonBackgroundColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.buttonBackgroundColor &&
              Boolean(errors.accountApprovalModal?.buttonBackgroundColor)
            }
            helperText={
              touched.accountApprovalModal?.buttonBackgroundColor
                ? errors.accountApprovalModal?.buttonBackgroundColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcSolidInput
            header="Button Text Color"
            name="accountApprovalModal.buttonTextColor"
            defaultValue={values.accountApprovalModal?.buttonTextColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.buttonTextColor &&
              Boolean(errors.accountApprovalModal?.buttonTextColor)
            }
            helperText={
              touched.accountApprovalModal?.buttonTextColor
                ? errors.accountApprovalModal?.buttonTextColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcSolidInput
            header="Text Color"
            name="accountApprovalModal.textColor"
            defaultValue={values.accountApprovalModal?.textColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.accountApprovalModal?.textColor &&
              Boolean(errors.accountApprovalModal?.textColor)
            }
            helperText={
              touched.accountApprovalModal?.textColor
                ? errors.accountApprovalModal?.textColor?.toString()
                : ''
            }
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};
