import React, { useEffect, useState } from 'react';
import { DEFAULT_BODY_BLOCKS_ORDER, StoreBodyBlock } from 'constants/enums';
import AcCard from 'components/AcCard/AcCard';
import { mapBodyBlocksToListItems } from '../../utils';
import { BlockItem, IBodyBlockItem } from './BlockItem';
import { StyledBlockListWrapper } from './Style';

interface IBodyBlocksProps {
  currentFormValue: StoreBodyBlock[];
  handleChange: (arr: StoreBodyBlock[]) => void;
  featureFlags?: Record<string, boolean>;
  isDirty: boolean;
}

export const BodyBlocks: React.FC<IBodyBlocksProps> = ({
  currentFormValue,
  handleChange,
  featureFlags,
  isDirty
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blockListItems, setBlockListItems] = useState<IBodyBlockItem[]>([]);

  useEffect(() => {
    if (isDirty || !currentFormValue || !featureFlags) return;
    let arrInitial = [...DEFAULT_BODY_BLOCKS_ORDER];
    const disabledItems = arrInitial.filter(
      (item) => !currentFormValue.includes(item)
    );
    const initialListItems = [...currentFormValue, ...disabledItems].map(
      mapBodyBlocksToListItems(currentFormValue)
    );

    setBlockListItems(initialListItems);
    setIsLoading(false);
  }, [currentFormValue, isDirty, featureFlags]);

  const [draggedItem, setDraggedItem] = useState<number | null>(null);

  const handleToggleEnable = (index: number): void => {
    const updatedItems = [...blockListItems];
    updatedItems[index].enabled = !updatedItems[index].enabled;

    setBlockListItems(updatedItems);
    handleFinishCurrentChange(updatedItems);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.effectAllowed = 'move';
    setDraggedItem(index);
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();

    if (draggedItem === index) return;

    const updatedItems = [...blockListItems];
    const [removed] = updatedItems.splice(draggedItem ?? 0, 1);
    updatedItems.splice(index, 0, removed);

    setDraggedItem(index);
    setBlockListItems(updatedItems);
  };

  const handleDragEnd = (item: IBodyBlockItem) => {
    setDraggedItem(null);

    if (item.enabled) {
      handleFinishCurrentChange(blockListItems);
    }
  };

  const handleFinishCurrentChange = (updatedItems: IBodyBlockItem[]) => {
    const newValue: StoreBodyBlock[] = updatedItems
      .filter(({ enabled }) => enabled)
      .map(({ value }) => value);

    handleChange(newValue);
  };

  return (
    <AcCard
      stackContainer={false}
      title="Store Components"
      description="Organize and control the structure and visibility of store components"
      customStyles={{ marginBottom: '24px' }}
    >
      {!isLoading && (
        <StyledBlockListWrapper>
          {blockListItems.map((item, index) => (
            <BlockItem
              key={item.value}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={() => handleDragEnd(item)}
              onVisibilityChange={() => handleToggleEnable(index)}
              item={item}
            />
          ))}
        </StyledBlockListWrapper>
      )}
    </AcCard>
  );
};
