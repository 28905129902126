import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import {
  ExtendedAppearanceStatus,
  ExtendedDateRange,
  FormikProps,
  ScheduleTimeFrame,
  TimeFrameTableData
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  ELocalStorageKeys,
  EPendingAppearanceStatus
} from 'constants/enums';
import dayjs from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { appearanceUtils, statusUtils } from 'utils/scheduleUtils';

import DataTable from 'components/DataTable/DataTable';

interface TimeFramesTableProps {
  formikProps: FormikProps;
  appearancesTableData: ScheduleTimeFrame[];
  handleEditTimeframe: (
    startTime: string,
    endTime: string,
    notes: string
  ) => void;
  onDeleteRow: () => void;
}

const TimeframesTable: React.FC<TimeFramesTableProps> = ({
  formikProps,
  appearancesTableData,
  handleEditTimeframe,
  onDeleteRow
}) => {
  const handleDeleteTimeframe = (startTime: string, endTime: string) => {
    const newTimeFrames = formikProps?.values?.schedule?.timeFrames?.filter(
      (timeFrame: ScheduleTimeFrame) =>
        new Date(timeFrame.startTime).getTime() !==
          new Date(startTime).getTime() &&
        new Date(timeFrame.endTime).getTime() !== new Date(endTime).getTime()
    );

    onDeleteRow();

    formikProps.setFieldValue('schedule', {
      permanent: !newTimeFrames.length
        ? false
        : formikProps.values?.schedule.permanent,
      timeFrames: newTimeFrames
    });
  };

  const columns = [
    {
      field: 'startTime',
      headerName: 'Start',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <span>
            {dayjs(params.row.startTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'endTime',
      headerName: 'End',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <span>
            {dayjs(params.row.endTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'notes',
      headerName: 'Notes',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (row: GridRenderCellParams<TimeFrameTableData>) => {
        const { startTime, endTime, id } = row.row;
        const pendingSaveStatus = id.startsWith('id-');
        const status: ExtendedAppearanceStatus = pendingSaveStatus
          ? EPendingAppearanceStatus.PENDING_SAVE
          : statusUtils.determineStatus(new Date(startTime), new Date(endTime));

        return (
          <StatusLabel
            text={status}
            status={statusUtils.getStatusLabel(status)}
            prefixIcon={
              status === EAppearanceStatus.RUNNING ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ScheduleIcon />
              )
            }
          />
        );
      },
      sortComparator: (a: any, b: any, params1: any, params2: any) =>
        appearanceUtils.sortByStatusOrder(
          params1,
          params2,
          appearancesTableData
        )
    },
    {
      field: 'actions',
      type: 'actions',
      disableReorder: true,
      width: 50,
      getActions: (params: GridRenderCellParams<TimeFrameTableData>) => {
        const { startTime, endTime, notes } = params.row;
        return [
          <GridActionsCellItem
            icon={<EditTwoToneIcon />}
            label="Edit"
            showInMenu
            onClick={() => handleEditTimeframe(startTime, endTime, notes)}
          />,
          <GridActionsCellItem
            className="danger"
            icon={<DeleteOutlineTwoToneIcon className="danger" />}
            label="Delete"
            showInMenu
            onClick={() => handleDeleteTimeframe(startTime, endTime)}
          />
        ];
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={appearancesTableData}
      loading={false}
      rowIdIdentifier="id"
      defaultHiddenFields={['id']}
      localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
      hideFooter
      error={false}
      initialSorting={{
        sortModel: [{ field: 'createdAt', sort: 'desc' }]
      }}
    />
  );
};

export default TimeframesTable;
