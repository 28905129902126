import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, Stack, Typography } from '@mui/material';
import useIntegration from 'api/useIntegration';
import { categorySubCategories } from 'common/contracts';
import { Events } from 'constants/enums';
import {
  PublisherIdType,
  SearchKeyType,
  useTableSearch
} from 'hooks/useTableSearch';
import { AuthSliceState } from 'store/store.types';

import AcCard from 'components/AcCard/AcCard';
import { TableSearchFilters } from 'components/TableSearchFilters/TableSearchFilters';

import { PublisherEventType } from '../Settings.types';

import EventCategorySection from './EventCategorySection';

const EventsSettings = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { getIntegration, getPublisherEvents }: any =
    useIntegration(currentPublisherId);
  const [events, setEvents] = useState(getPublisherEvents?.data || []);

  const {
    searchValue,
    filterValue,
    setSearchValue,
    setFilterValue,
    filteredData,
    isTableDataEmpty
  } = useTableSearch({
    data: events,
    publisherId: PublisherIdType.CATEGORY,
    searchKey: SearchKeyType.DISPLAY_NAME
  });

  useEffect(() => {
    if (getPublisherEvents?.data) {
      const eventOrderMap = Object.values(categorySubCategories)
        .flatMap((subCategories) => Object.keys(subCategories))
        .reduce(
          (acc, eventKey, index) => {
            acc[eventKey] = index;
            return acc;
          },
          {} as Record<string, number>
        );

      const orderedEvents = [...getPublisherEvents.data].sort(
        (eventA, eventB) =>
          (eventOrderMap[eventA.value] ?? Infinity) -
          (eventOrderMap[eventB.value] ?? Infinity)
      );

      setEvents(orderedEvents);
    }
  }, [getPublisherEvents?.data]);

  const categorizedEvents = filteredData().reduce<
    Record<string, PublisherEventType[]>
  >((acc, event: any) => {
    const categoryEntry = Object.entries(Events).find(([_, eventKeys]) =>
      eventKeys.includes(event.value)
    );

    const category = categoryEntry ? categoryEntry[0] : 'New Events';
    const subCategory =
      categorySubCategories[category]?.[event.value] || 'Unknown';

    acc[category] = acc[category] || [];
    acc[category].push({
      value: event.value,
      displayName: event.displayName,
      subCategory
    });

    return acc;
  }, {});

  return (
    <Grid container className="formContent">
      <Grid item xs={15} className="formContent-minWIdth">
        <Stack>
          <AcCard title="Events">
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                color: '#CACBD4',
                marginBottom: '10px'
              }}
            >
              Webhook Url:{' '}
              {getIntegration?.data?.eventsWebhookUrl || 'https://'}
            </Typography>
            <TableSearchFilters
              searchValue={searchValue}
              filterValue={filterValue}
              setSearchValue={setSearchValue}
              setFilterValue={setFilterValue}
              tableVariant="event"
              header="Category"
              marginTop="0"
            />
            {Object.entries(categorizedEvents).map(
              ([category, categoryEvents]) => (
                <EventCategorySection
                  key={category}
                  category={category}
                  events={categoryEvents}
                  enabledEvents={getIntegration?.data?.enabledWebhookEvents}
                  isTableDataEmpty={isTableDataEmpty}
                />
              )
            )}
          </AcCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EventsSettings;
