import { useMutation, useQuery } from '@tanstack/react-query';
import { User } from 'common/contracts';

import { IPermissions } from '../common/permissions.types';
import { LoginData } from '../components/LoginModal/LoginModal.types';
import {
  EFeatureFlag,
  ELocalStorageKeys,
  EQueryKeys
} from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { useLogin } from '../hooks/useLogin';
import { localStorageUtil } from '../utils/localStorageUtil';
import { permissionsUtil } from '../utils/permissionsUtil';
import {
  ResetPasswordData,
  UpdateInfoData,
  UpdatePasswordData
} from '../views/Profile/ProfileView.types';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

interface UseUsersOptions {
  shouldFetchUsersData?: boolean;
  publisherId?: string | null;
  enableFeatureFlags?: boolean;
}

export default function useUsers({
  shouldFetchUsersData = false,
  publisherId,
  enableFeatureFlags = true
}: UseUsersOptions) {
  const axios = useAxios();
  const loginUtils = useLogin();

  const canAccessUsers = publisherId
    ? !!permissionsUtil.canAccessUsers(
        localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
        publisherId
      )
    : shouldFetchUsersData;

  const getUsers = useQuery<any, Error>({
    ...fetchConfig.general,
    queryKey: [EApiRoutes.USERS],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.USERS),
        publisherId ? { headers: { 'x-publishers': publisherId } } : undefined
      );
    },
    enabled: canAccessUsers
  });

  const updateUser = useMutation(async (data: Partial<UpdateInfoData>) => {
    return await axios.put(getApiRouteOrMock(EApiRoutes.USERS), data);
  }, {});

  const updateRole = useMutation(async (data: Partial<UpdateInfoData>) => {
    if (!data.userId || !data.role) {
      throw new Error(
        'UserID and role are required for updating the user role.'
      );
    }
    return await axios.put(
      getApiRouteOrMock(EApiRoutes.UPDATE_USER_ROLE) + `/${data.userId}`,
      { role: data.role }
    );
  }, {});

  const updateRoleV2 = useMutation(
    async (data: { userId: string; roleId: string }) => {
      if (!data.userId || !data.roleId) {
        throw new Error(
          'UserID and role are required for updating the user role.'
        );
      }
      return await axios.put(
        getApiRouteOrMock(EApiRoutes.UPDATE_USER_ROLE) + `/${data.userId}/role`,
        { roleId: data.roleId }
      );
    },
    {}
  );

  const deleteUser = useMutation(async (id: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.DELETE_USER)}/${id}`,
      {},
      publisherId ? { 'x-publishers': publisherId } : undefined
    );
  }, {});

  const addUser = useMutation(async (userForm: any) => {
    return await axios.post(
      getApiRouteOrMock(EApiRoutes.ADD_USER),
      userForm,
      publisherId ? { 'x-publishers': publisherId } : undefined
    );
  }, {});

  const resendUserInvitation = useMutation(async (userData: any) => {
    return await axios.post(
      getApiRouteOrMock(EApiRoutes.RESEND_USER_INVITATION),
      userData,
      publisherId ? { 'x-publishers': publisherId } : undefined
    );
  }, {});

  const resetPassword = useMutation(async (data: ResetPasswordData) => {
    return await axios.put(getApiRouteOrMock(EApiRoutes.RESET_USER), data);
  }, {});

  const updatePassword = useMutation(async (data: UpdatePasswordData) => {
    return await axios.put(
      getApiRouteOrMock(EApiRoutes.UPDATE_USER_PASSWORD),
      data
    );
  }, {});

  const forgotPassword = useMutation(async (email: string) => {
    return await axios.put(getApiRouteOrMock(EApiRoutes.FORGOT_USER_PASSWORD), {
      email
    });
  }, {});

  const login = useMutation(async (loginData: LoginData) => {
    const response = await axios.post(
      getApiRouteOrMock(EApiRoutes.LOGIN_USER),
      loginData
    );
    return response.data;
  }, {});

  const loginWithGoogle = useMutation(async (loginData: LoginData) => {
    const response = await axios.post(
      getApiRouteOrMock(EApiRoutes.LOGIN_USER_GOOGLE),
      loginData
    );
    return response.data;
  }, {});

  const fetchFeatureFlags = useQuery({
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
    refetchIntervalInBackground: false,
    retry: false,
    queryKey: [EQueryKeys.FEATURE_FLAGS],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.FEATURE_FLAGS),
        {},
        publisherId ? { 'x-publishers': publisherId } : undefined
      );
    },
    enabled: enableFeatureFlags && !!publisherId
  });

  const getIsFeatureEnabledInAnyProject = (
    featureFlag: EFeatureFlag
  ): Promise<boolean> => {
    const projectsDetails = localStorageUtil.getAny<User>(
      ELocalStorageKeys.USER_DETAILS
    )?.projects;

    if (!projectsDetails) return Promise.resolve(false);

    const fetchIsFeatureEnabledForProject = (id: string) => {
      return new Promise((resolve, reject) => {
        axios
          .get(
            getApiRouteOrMock(EApiRoutes.FEATURE_FLAGS),
            {},
            id ? { 'x-publishers': id } : undefined
          )
          .then(({ featureFlags }) => {
            if (featureFlags?.[featureFlag]) resolve(id);
            else reject(new Error('Condition not met'));
          })
          .catch((error) => {
            reject(new Error('Request failed: ' + error.message));
          });
      });
    };

    const promises = projectsDetails.map(({ publisherId: id }) =>
      fetchIsFeatureEnabledForProject(id)
    );

    return Promise.any(promises)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const logout = useMutation(async () => {
    await axios.post(getApiRouteOrMock(EApiRoutes.LOGOUT_USER), {});
    loginUtils.logout();
  }, {});

  return {
    login,
    loginWithGoogle,
    logout,
    getUsers,
    addUser,
    updateUser,
    updateRole,
    updateRoleV2,
    deleteUser,
    resetPassword,
    updatePassword,
    forgotPassword,
    fetchFeatureFlags,
    getIsFeatureEnabledInAnyProject,
    resendUserInvitation
  };
}
