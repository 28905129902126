import { AssetsCategories } from './enums';

export const getAssetItemValue = (item: string) =>
  Object.keys(AssetsCategories).find(
    (key) => AssetsCategories[key as keyof typeof AssetsCategories] === item
  ) || 'general';

export const assetsCategoriesItems = [
  {
    content: 'General',
    key: AssetsCategories.general,
    value: getAssetItemValue(AssetsCategories.general)
  },
  {
    content: 'Mobile BG image',
    key: AssetsCategories.bgMobile,
    value: getAssetItemValue(AssetsCategories.bgMobile)
  },
  {
    content: 'Desktop BG image',
    key: AssetsCategories.bgDesk,
    value: getAssetItemValue(AssetsCategories.bgMobile)
  },
  {
    content: 'Product',
    key: AssetsCategories.product,
    value: getAssetItemValue(AssetsCategories.product)
  },
  {
    content: 'Logo',
    key: AssetsCategories.logo,
    value: getAssetItemValue(AssetsCategories.logo)
  },
  {
    content: 'Prefix',
    key: AssetsCategories.productPrefix,
    value: getAssetItemValue(AssetsCategories.productPrefix)
  },
  {
    content: 'Player Level',
    key: AssetsCategories.playerLevel,
    value: getAssetItemValue(AssetsCategories.playerLevel)
  },
  {
    content: 'Badge',
    key: AssetsCategories.badge,
    value: getAssetItemValue(AssetsCategories.badge)
  },
  {
    content: 'BG Bundle',
    key: AssetsCategories.bgBundle,
    value: getAssetItemValue(AssetsCategories.bgBundle)
  },
  {
    content: 'BG Popup',
    key: AssetsCategories.bgPopup,
    value: getAssetItemValue(AssetsCategories.bgPopup)
  },
  {
    content: 'Banner',
    key: AssetsCategories.banner,
    value: getAssetItemValue(AssetsCategories.banner)
  },
  {
    content: 'Favicon',
    key: AssetsCategories.favicon,
    value: getAssetItemValue(AssetsCategories.favicon)
  },
  {
    content: 'Section',
    key: AssetsCategories.section,
    value: getAssetItemValue(AssetsCategories.section)
  },
  {
    content: 'Button Image',
    key: AssetsCategories.addToHomeButtonImage,
    value: getAssetItemValue(AssetsCategories.addToHomeButtonImage)
  },
  {
    content: 'Icon Image',
    key: AssetsCategories.addToHomeIconImage,
    value: getAssetItemValue(AssetsCategories.addToHomeIconImage)
  }
];
