import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useTheme from 'api/useTheme';
import { AuthSliceState } from 'store/store.types';

import { defaultGeneralTheme } from './defaults';
import { GeneralThemeFormValues } from './generalTheme.types';

export const useGetGeneralInitialValues = () => {
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [initialValues, setInitialValue] =
    useState<GeneralThemeFormValues>(defaultGeneralTheme);
  const { getStoreTheme } = useTheme(currentPublisherId || undefined);

  const generalTheme = useMemo(
    () => ({ data: getStoreTheme?.data?.general }),
    [getStoreTheme?.data?.general]
  );

  useEffect(() => {
    setInitialValue({
      storeTabName:
        generalTheme.data?.storeTabName || defaultGeneralTheme.storeTabName,
      logo: generalTheme.data?.logo || defaultGeneralTheme.logo,
      favicon: generalTheme.data?.favicon || defaultGeneralTheme.favicon,
      backgroundImageMobile:
        generalTheme.data?.backgroundImageMobile ||
        defaultGeneralTheme.backgroundImageMobile,
      backgroundImageDesktop:
        generalTheme.data?.backgroundImageDesktop ||
        defaultGeneralTheme.backgroundImageDesktop,
      storeSaleImage:
        generalTheme.data?.storeSaleImage || defaultGeneralTheme.storeSaleImage,
      font: generalTheme.data?.font || defaultGeneralTheme.font,
      footer: {
        imageMobile:
          generalTheme.data?.footer?.imageMobile ||
          defaultGeneralTheme.footer.imageMobile,
        imageDesktop:
          generalTheme.data?.footer?.imageDesktop ||
          defaultGeneralTheme.footer.imageDesktop,
        appstoreLink:
          generalTheme.data?.footer?.appstoreLink ||
          defaultGeneralTheme.footer.appstoreLink,
        googlePlayLink:
          generalTheme.data?.footer?.googlePlayLink ||
          defaultGeneralTheme.footer.googlePlayLink,
        privacyPolicyUrl:
          generalTheme.data?.footer?.privacyPolicyUrl ||
          defaultGeneralTheme.footer.privacyPolicyUrl,
        termsAndConditionsUrl:
          generalTheme.data?.footer?.termsAndConditionsUrl ||
          defaultGeneralTheme.footer.termsAndConditionsUrl,
        supportLink:
          generalTheme.data?.footer?.supportLink ||
          defaultGeneralTheme.footer.supportLink,
        openLinksInNewTab:
          generalTheme.data?.footer?.openLinksInNewTab ||
          defaultGeneralTheme?.footer.openLinksInNewTab
      }
    });
  }, [generalTheme.data, getStoreTheme?.data, location]);

  return { initialValues };
};
