import React from 'react';

import { Box } from '@mui/system';

import AcInput from '../AcInput/AcInput';
import AcSearchInput from '../AcInput/AcSearchInput';

export type ITableSearchFiltersProps = {
  searchValue: string;
  filterValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  tableVariant: string;
  header?: string;
  marginTop?: string;
};

export const TableSearchFilters = ({
  searchValue,
  filterValue,
  setSearchValue,
  setFilterValue,
  tableVariant,
  header,
  marginTop = '16px'
}: ITableSearchFiltersProps) => {
  return (
    <>
      <AcInput
        placeholder={`Search by ${tableVariant} name`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        sx={{
          maxWidth: '340px',
          marginTop
        }}
      />
      <Box mt={2} width="max-content">
        <AcSearchInput
          size="small"
          header={
            header ||
            `${tableVariant[0].toUpperCase() + tableVariant.slice(1)} SKU`
          }
          onChange={(e: any) => setFilterValue(e.target.value)}
          value={filterValue}
          setValue={setFilterValue}
          renderStatusTextOverride={(value: string) => value}
        />
      </Box>
    </>
  );
};
