import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import { Stack } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import {
  EStatusLabel,
  StatusLabelProps
} from 'design-system/StatusLabel/types';
import AcAvatar from 'components/AcAvatar/AcAvatar';
import AcSelect from 'components/AcSelect/AcSelect';
import { permissionsUtil } from 'utils/permissionsUtil';

const getInitials = (name: string) => {
  name.trim();
  return name?.split(' ')[0][0]?.toUpperCase() || '';
};

export const userStatusMap: Record<string, StatusLabelProps> = {
  [EStatusLabel.PENDING]: {
    text: 'Pending',
    status: EStatusLabel.PENDING,
    prefixIcon: <ScheduleIcon />
  },
  [EStatusLabel.ACTIVE]: {
    text: 'Active',
    status: EStatusLabel.SUCCESS,
    prefixIcon: <CheckCircleOutlineIcon />
  }
};
export const getUsersColumns = (
  handleChangeUserRole: (e: any, id: string, name: string) => void,
  handleDeleteAction: (id: string) => void,
  isAdmin?: boolean,
  newRolesOptions?: any,
  resendUserInvitation?: (email: string) => void
) => {
  const columns: any = [
    {
      field: 'name',
      minWidth: 200,
      headerName: 'Name',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <AcAvatar content={getInitials(row?.value)} />
            <span>{row?.value}</span>
          </Stack>
        );
      }
    },
    {
      field: 'email',
      minWidth: 200,
      headerName: 'Email',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <span>{row.value}</span>
          </Stack>
        );
      }
    },
    {
      field: 'role',
      headerName: 'Role',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack justifyContent="center" alignItems="center">
            <AcSelect
              size="small"
              value={row.value.toLowerCase()}
              disabled={
                (row.value.toLowerCase() === 'admin' &&
                  !permissionsUtil.isSuperAdmin()) ||
                permissionsUtil.isViewerOrEditor()
              }
              style={{
                height: '30px',
                fontSize: '14px',
                width: '120px',
                marginTop: '-1em'
              }}
              items={newRolesOptions ? newRolesOptions : userRolesOptions}
              onChange={(e: any) => {
                handleChangeUserRole(e, row.row._id, row.row.name);
              }}
            />
          </Stack>
        );
      }
    },
    {
      field: 'status',
      minWidth: 100,
      headerName: 'Status',
      disableReorder: true,
      flex: 1,
      hideable: false,
      renderCell: ({ row }: any) => {
        const currentStatus = row.active
            ? userStatusMap['active']
            : userStatusMap['pending'];
        return (
            <StatusLabel
                text={currentStatus.text}
                status={currentStatus.status}
                prefixIcon={currentStatus.prefixIcon}
            ></StatusLabel>
        );
      }
    }
  ];

  if (isAdmin) {
    columns.push({
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => {
        const actionOptions = [];
        if (!params.row.active && resendUserInvitation) {
          actionOptions.push(
            <GridActionsCellItem
             icon={<SendIcon />}
             label="Resend invitation"
             onClick={() => resendUserInvitation(params.row.email)}
             showInMenu
            />
          )
        }

        if (handleDeleteAction) {
          actionOptions.push(
            <GridActionsCellItem
              className="danger"
              icon={<DeleteOutlineTwoToneIcon className="danger" />}
              label="Delete"
              disabled={permissionsUtil.isViewerOrEditor()}
              onClick={() => handleDeleteAction(params.row._id)}
              showInMenu
            />
          )
        }
        return actionOptions
      }
    });
  }
  return columns;
};

export const userRolesOptions = [
  {
    content: 'Viewer',
    key: 'viewer',
    value: 'viewer'
  },
  {
    content: 'Editor',
    key: 'editor',
    value: 'editor'
  },
  {
    content: 'Admin',
    key: 'admin',
    value: 'admin'
  }
];
