import { SyntheticEvent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useIntegration from 'api/useIntegration';
import usePermissions from 'api/usePermissions';
import usePublisher from 'api/usePublisher';
import useUsers from 'api/useUsers';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { EPermissionGroup } from 'common/permissions.types';
import { EFeatureFlag, ELocalStorageKeys } from 'constants/enums';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import PageTopBar from '../Topbar/PageTopBar';
import { ESettingsState } from './Settings.types';

import './style.scss';

const SettingsView = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const { getPublisher } = usePublisher(currentPublisherId || undefined);
  const { getIntegration } = useIntegration(currentPublisherId);
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const location = useLocation();
  const navigate = useNavigate();

  const projectItem = projectsDetails?.find(
    (item) => item.publisherId === currentPublisherId
  );
  const currentTab = location.pathname.split('/')[4];
  const isCheckout =
    projectItem?.projectType === EPublisherSolutionModel.CHECKOUT;
  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });
  const hasFeatureFlagEventCenter =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_EVENT_CENTER];

  const handleAuthTabShown = () => {
    return (
      !isCheckout ||
      permissionsUtil.shouldHideGroupInPermission(
        permissions,
        EPermissionGroup.AUTHENTICATION,
        getPublisher.data?._id
      )
    );
  };

  const [tab, setTab] = useState(currentTab);

  const getTabs = useMemo(() => {
    const tabs = [];
    const isCompanyTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.COMPANY,
      getPublisher.data?._id
    );
    const isUsersTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.USERS,
      getPublisher.data?._id
    );
    const isIntegrationsTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.INTEGRATION,
      getPublisher.data?._id
    );
    const isSuperAdminByProject = permissionsUtil.isSuperAdminByProject(
      getPublisher.data?._id || ''
    );
    const isStoreSettingsTabShown =
      isSuperAdminByProject ||
      permissionsUtil.isAdminByProject(getPublisher.data?._id || '');

    if (isCompanyTabShown) {
      tabs.push({ label: 'Company', value: ESettingsState.COMPANY });
    }

    if (isUsersTabShown) {
      tabs.push({ label: 'Users', value: ESettingsState.USERS });
    }

    if (isIntegrationsTabShown) {
      tabs.push({ label: 'Integration', value: ESettingsState.INTEGRATION });
    }

    if (
      isIntegrationsTabShown &&
      fetchFeatureFlags.data?.featureFlags?.dashboard_integration_profiles
    ) {
      tabs.push({
        label: 'Integration Profiles',
        value: ESettingsState.INTEGRATION_PROFILES
      });
    }

    if (handleAuthTabShown()) {
      tabs.push({ label: 'Authentication', value: ESettingsState.PLAYER_AUTH });
    }

    if (isSuperAdminByProject) {
      tabs.push(
        { label: 'Payments', value: ESettingsState.PAYMENTS },
        { label: 'Store Layout', value: ESettingsState.LAYOUT }
      );
    }

    if (isSuperAdminByProject || (
      permissionsUtil.isAdminByProject(getPublisher.data?._id || '') &&
      hasFeatureFlagEventCenter
    )) {
      tabs.push({ label: 'Analytics Events', value: ESettingsState.EVENTS });
    }

    if (isStoreSettingsTabShown) {
      const storeSettingsTabData = {
        label: 'Store Settings',
        value: ESettingsState.STORE_SETTINGS
      };
      const storeLayoutTabData = tabs.find(
        (tab) => tab.value === ESettingsState.LAYOUT
      );
      if (storeLayoutTabData) {
        tabs.splice(
          tabs.indexOf(storeLayoutTabData) + 1,
          0,
          storeSettingsTabData
        );
      } else {
        tabs.push({
          label: 'Store Settings',
          value: ESettingsState.STORE_SETTINGS
        });
      }
    }

    return tabs;
  }, [permissions, getPublisher.data?._id, handleAuthTabShown]);

  const handleTabChange = (event: SyntheticEvent, newValue: ESettingsState) => {
    getIntegration.refetch();
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/settings/${newValue}`;
    navigate(newPath);
  };

  return (
    <TabsPanel
      tabs={getTabs}
      headerComponent={<PageTopBar withTabsDesign={true} headline="Settings" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};

export default SettingsView;
